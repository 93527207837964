import cx from 'classnames';
import { useState } from 'react';

import {
  Button,
  InputField,
  ModalHeader,
  SquareButton,
  Typography,
} from '@sb/ui/components';
import { ArrowBackIcon } from '@sb/ui/icons';
import { display, justifyContent, margin } from '@sb/ui/styles';
import { useToast } from '@sbrc/hooks';
import { validatePIN } from '@sbrc/services';

import { useSafetySettingsStore } from './store';

import styles from './shared.module.css';

export default function EnterPIN() {
  const { setLockState } = useSafetySettingsStore('setLockState');
  const [pin, setPIN] = useState<string>('');
  const [hasError, setHasError] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { setToast } = useToast();

  const isSubmittable = pin.length >= 4;

  const handleSubmit: React.FormEventHandler = async (event) => {
    event.preventDefault();

    setIsSubmitting(true);

    const pinAccepted = await validatePIN(pin).catch(() => {
      setToast({ kind: 'error', message: 'PIN validation failed' });

      return false;
    });

    setHasError(!pinAccepted);
    setIsSubmitting(false);

    if (pinAccepted) {
      setLockState('unlocked');
    }
  };

  return (
    <>
      <ModalHeader title="Safety Settings" />

      <div
        className={cx(
          styles.modalContent,
          styles.narrowContent,
          justifyContent.center,
        )}
      >
        <form
          className={cx(margin.left.large, margin.right.large)}
          onSubmit={handleSubmit}
        >
          <div className={cx(display.flex.row)}>
            <SquareButton
              type="button"
              aria-label="Back"
              onClick={() => setLockState('locked')}
              className={margin.right.small}
            >
              <ArrowBackIcon size="small" />
            </SquareButton>
            <Typography component="h3">Confirm PIN to Unlock</Typography>
          </div>
          <InputField
            className={margin.top.medium}
            inputMode="numeric"
            hasError={hasError}
            helperText={hasError ? 'PIN not accepted' : undefined}
            onChange={(e) => setPIN(e.target.value)}
            value={pin}
            type="password"
            autoFocus
            disabled={isSubmitting}
          >
            Enter PIN
          </InputField>
          <Button
            className={margin.top.medium}
            type="submit"
            isFullWidth
            variant="primary"
            disabled={isSubmitting || !isSubmittable}
          >
            Unlock
          </Button>
        </form>
      </div>
    </>
  );
}

import { useEffect, useState } from 'react';

import type { Equipment } from '@sb/types';
import { Button, Loader, Typography } from '@sb/ui/components';
import { AddIcon } from '@sb/ui/icons';

import type { EquipmentConnectionStatus } from './EquipmentManagerItem';
import { EquipmentManagerItem } from './EquipmentManagerItem';

import styles from './shared.module.css';

export type GetConnectionStatus = (
  equipmentItem: Equipment.ConvertedResponse,
) => EquipmentConnectionStatus;

interface EquipmentListProps {
  loading: boolean;
  equipment: Equipment.ConvertedResponse[];
  getConnectionStatus: GetConnectionStatus;
  selectedEquipmentID: string | null;
  isChangeDisabled: boolean;
  onAdd: () => void;
  onEdit: (equipmentItem: Equipment.ConvertedResponse) => void;
  onDelete: (equipmentItem: Equipment.ConvertedResponse) => void;
}

export function EquipmentList({
  loading,
  equipment,
  getConnectionStatus,
  selectedEquipmentID,
  isChangeDisabled,
  onAdd,
  onEdit,
  onDelete,
}: EquipmentListProps) {
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (isChangeDisabled) {
      setIsEditing(false);
    }
  }, [isChangeDisabled]);

  const content = (() => {
    if (loading) {
      return <Loader />;
    }

    return (
      <div>
        <div className={styles.equipmentListHeader}>
          <Typography component="h5">Connected</Typography>
          <Button
            variant="link"
            size="medium"
            className={styles.editButton}
            onClick={() => setIsEditing(!isEditing)}
            disabled={isChangeDisabled}
          >
            {isEditing ? 'Done' : 'Edit'}
          </Button>
        </div>
        <div className={styles.equipmentList}>
          {equipment.map((equipmentItem) => {
            return (
              <div
                className={styles.equipmentListItem}
                onClick={() => onEdit(equipmentItem)}
                role="button"
                key={equipmentItem.id}
              >
                <EquipmentManagerItem
                  isSelected={equipmentItem.id === selectedEquipmentID}
                  status={getConnectionStatus(equipmentItem)}
                  equipment={equipmentItem}
                  name={equipmentItem.config.name}
                  editing={isEditing}
                  onClickDelete={() => onDelete(equipmentItem)}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  })();

  return (
    <>
      <div className={styles.equipmentModalContent}>{content}</div>
      <div className={styles.footer}>
        <Button
          startIcon={<AddIcon />}
          variant="link"
          onClick={() => onAdd()}
          disabled={isChangeDisabled}
        >
          Add equipment
        </Button>
      </div>
    </>
  );
}

import { Checkbox } from '@sb/ui/components';

import styles from './SuctionChannelCheckbox.module.css';

interface SuctionChannelCheckboxProps {
  name: string;
  isChecked: boolean;
  onChange: (isChecked: boolean) => void;
  suctionPercentage: number;
}

export function SuctionChannelCheckbox({
  name,
  isChecked,
  onChange,
  suctionPercentage,
}: SuctionChannelCheckboxProps) {
  const suctionPercentage100 = Math.round(suctionPercentage * 100);

  const containerStyle: any = {
    '--suction-channel-checkbox--color-stop-percent': `${suctionPercentage100}%`,
  };

  return (
    <div style={containerStyle}>
      <Checkbox
        checked={isChecked}
        onChange={(e) => onChange(e.target.checked)}
        className={styles.label}
      >
        <span className={styles.name}>{name}</span>
        <span className={styles.pc}>{suctionPercentage100}%</span>
      </Checkbox>
    </div>
  );
}

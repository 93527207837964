import { RangeSpinner, useDualValueRangeSpinner } from '@sb/ui/components';
import { forceToString } from '@sb/utilities';
import SectionContainer from '@sbrc/components/visualizer-view-shared/widget-panel/SectionContainer';

import { OR_3FG15_FORCE_NEWTONS_SLIDER_STEP } from '../..';

import type { DualValueModeProps } from './types';

export default function GripperForce({
  gripperControlState,
}: DualValueModeProps) {
  const { changeTargetForce, command, forceRange, routineRunnerForce } =
    gripperControlState;

  const targetForce = command.targetForce ?? NaN;

  const spinnerProps = useDualValueRangeSpinner({
    primaryValue: routineRunnerForce,
    secondaryValue: targetForce,
    min: forceRange.min,
    max: forceRange.max,
    step: OR_3FG15_FORCE_NEWTONS_SLIDER_STEP,
    onChange: changeTargetForce,
  });

  return (
    <SectionContainer hasMeter title="Force">
      <RangeSpinner {...spinnerProps} valueToString={forceToString} />
    </SectionContainer>
  );
}

import { useEffect, useState } from 'react';

import type { OnRobotDualQuickChangerCommand } from '@sb/integrations/OnRobotDualQuickChanger';
import { calculateORDualChangerCommandFromOR3FG15Command } from '@sb/integrations/OnRobotDualQuickChanger';
import { useGuidedMode, useRoutineRunnerHandle } from '@sbrc/hooks';
import type { UseRoutineRunnerHandleArguments } from '@sbrc/hooks';

import type { OnRobot3FG15Command, OnRobot3FG15State } from '../..';
import { get3FG15State } from '../../utils';

export default function useGripperSingleValueControl<T extends number | string>(
  routineRunnerArgs: UseRoutineRunnerHandleArguments,
  actualValue: T,
  getGripperCommand: (
    gripperState: OnRobot3FG15State | null,
    requestedValue: T,
  ) => OnRobot3FG15Command | null,
  activeDualGripper?: 'primary' | 'secondary',
): readonly [value: T, setValue: React.Dispatch<React.SetStateAction<T>>] {
  const routineRunnerHandle = useRoutineRunnerHandle(routineRunnerArgs);
  const { runAdHocCommand, stopGuidedMode } = useGuidedMode(routineRunnerArgs);

  const [requestedValue, setRequestedValue] = useState<T | undefined>();

  useEffect(() => {
    if (requestedValue === undefined) {
      return undefined;
    }

    // On a one-second debounce because botman will get confused
    // if we send lots of gripper commands too quickly
    const timeoutId = setTimeout(() => {
      const gripperState =
        routineRunnerHandle.getState()?.kinematicState.gripperState;

      let parentCommand: OnRobotDualQuickChangerCommand;

      const state =
        activeDualGripper && gripperState?.kind === 'OnRobotDualQuickChanger'
          ? gripperState[activeDualGripper]
          : gripperState;

      const OR3FGState = get3FG15State(state);

      const command = getGripperCommand(OR3FGState, requestedValue);

      if (activeDualGripper && command) {
        parentCommand = calculateORDualChangerCommandFromOR3FG15Command(
          command,
          activeDualGripper,
        );
      }

      if (command) {
        runAdHocCommand({
          onRunCommand: () => {
            return routineRunnerHandle.actuateDevice({
              command: parentCommand ?? command,
            });
          },
          onComplete: () => {
            setRequestedValue(undefined);
          },
        });
      } else {
        setRequestedValue(undefined);
      }
    }, 1000);

    return () => {
      stopGuidedMode();
      clearTimeout(timeoutId);
    };
  }, [
    requestedValue,
    routineRunnerHandle,
    getGripperCommand,
    runAdHocCommand,
    stopGuidedMode,
    activeDualGripper,
  ]);

  return [
    requestedValue ?? actualValue,
    (action) => {
      setRequestedValue((currentValue) => {
        const newValue =
          typeof action === 'function'
            ? action(currentValue ?? actualValue)
            : action;

        return newValue;
      });
    },
  ];
}

const VALID_STEPS = [
  0.001, 0.005, 0.01, 0.1, 0.25, 0.5, 1, 5, 10, 25, 50, 100, 250, 500, 1000,
];

interface MaxMinStepProps<T> {
  max?: T;
  min?: T;
  step?: T;
}

interface Options {
  convertValue: (value: number) => number;
  isSlider?: boolean;
}

export function convertMaxMinStepProps(
  { max, min, step }: MaxMinStepProps<number | string>,
  { convertValue, isSlider }: Options,
): MaxMinStepProps<number> {
  const convertedProps: MaxMinStepProps<number> = {};

  if (typeof max === 'number') {
    convertedProps.max = convertValue(max);
  }

  if (typeof min === 'number') {
    convertedProps.min = convertValue(min);
  }

  if (typeof step === 'number') {
    const convertedStepBase = convertValue(step);

    // adjust step value to be a nice round number
    const convertedStep =
      VALID_STEPS.find((v) => v >= convertedStepBase) ?? 1000;

    convertedProps.step = convertedStep;

    if (isSlider) {
      // ensure max is a multiple of step & no larger than the unconverted value
      if (typeof max === 'number') {
        convertedProps.max =
          Math.floor(convertValue(max) / convertedStep) * convertedStep;
      }

      // ensure max is a mutliple of step & no smaller than the unconverted value
      if (typeof min === 'number') {
        convertedProps.min =
          Math.ceil(convertValue(min) / convertedStep) * convertedStep;
      }
    }
  }

  return convertedProps;
}

import { Typography } from '@sb/ui/components';
import { margin } from '@sb/ui/styles';

export const NoEquipmentEditorForm = () => {
  return (
    <>
      <Typography component="h5" className={margin.bottom.small}>
        Settings
      </Typography>
      <Typography isDisabled>No settings available for this device.</Typography>
    </>
  );
};

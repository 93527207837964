import type { Robot } from '@sb/types';
import { DropdownButton } from '@sb/ui/components';
import { useRobotBadgeStatus } from '@sbrc/hooks';

import type { SoftwareBuildData } from '../software-update';

import RobotBadgeContent from './RobotBadgeContent';
import RobotBadgeMenu from './RobotBadgeMenu';

import styles from './RobotBadgeDropdown.module.css';

interface RobotBadgeDropdownProps {
  isDisabled?: boolean;
  robot: Robot.ConvertedResponse;
  buildData: SoftwareBuildData;
}

export default function RobotBadgeDropdown({
  isDisabled,
  robot,
  buildData,
}: RobotBadgeDropdownProps) {
  const robotBadgeStatus = useRobotBadgeStatus(robot.id);

  return (
    <DropdownButton
      className={styles.button}
      disabled={isDisabled}
      content={
        <RobotBadgeMenu
          robot={robot}
          robotBadgeStatus={robotBadgeStatus}
          buildData={buildData}
        />
      }
      variant="grayDark"
      data-testid="robot-badge"
    >
      <div className={styles.content}>
        <RobotBadgeContent
          robot={robot}
          robotBadgeStatus={robotBadgeStatus}
          isSoftwareUpdateAvailable={buildData.isSoftwareUpdateAvailable}
          isInstalling={buildData.isInstalling}
        />
      </div>
    </DropdownButton>
  );
}

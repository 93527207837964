import { useEffect } from 'react';

import { Modal } from '@sb/ui/components';
import { useRobotStateKind } from '@sbrc/hooks';

import EnterPIN from './EnterPIN';
import { SettingsFormModal } from './form/SettingsFormModal';
import LoadingState from './LoadingState';
import { useReadSettings, useSafetySettingsStore } from './store';

import styles from './shared.module.css';

interface ModalOuterProps {
  robotID: string;
  onClose: () => void;
}

export default function ModalOuter({ robotID, onClose }: ModalOuterProps) {
  const routineRunnerStateKind = useRobotStateKind({ robotID });

  const isUnlockDisabled =
    routineRunnerStateKind === 'Antigravity' ||
    routineRunnerStateKind === 'Recovering' ||
    routineRunnerStateKind === 'RoutineRunning' ||
    routineRunnerStateKind === 'RunningAdHocCommand';

  useReadSettings(robotID);

  const { hasUnsavedChanges, lockState, setLockState } = useSafetySettingsStore(
    'hasUnsavedChanges',
    'lockState',
    'setLockState',
  );

  // revert to locked if robot becomes non-idle
  useEffect(() => {
    if (
      isUnlockDisabled &&
      (lockState === 'enterPIN' || lockState === 'unlocked')
    ) {
      setLockState('locked');
    }
  }, [isUnlockDisabled, lockState, setLockState]);

  // may only close dialog by using Discard/Save buttons when has unsaved changes
  const handleClose = hasUnsavedChanges ? () => {} : onClose;

  return (
    <Modal isOpen onClose={handleClose} className={styles.modal}>
      {lockState === 'loading' && <LoadingState />}
      {lockState === 'enterPIN' && <EnterPIN />}
      <SettingsFormModal
        onClose={onClose}
        isUnlockDisabled={isUnlockDisabled}
      />
    </Modal>
  );
}

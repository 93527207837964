import { Typography } from '@sb/ui/components';
import { WarningIcon } from '@sb/ui/icons';

import styles from './Disclaimer.module.css';

export function Disclaimer() {
  return (
    <div className={styles.disclaimer}>
      <WarningIcon size="extraSmall" />
      <Typography variant="small">
        Remember to conduct a full safety assessment per{' '}
        <a
          href="https://www.iso.org/standard/41571.html"
          target="blank"
          rel="nofollow"
        >
          ISO 10218-2
        </a>{' '}
        to verify limits and safeguards required for application.
      </Typography>
    </div>
  );
}

import { Typography } from '@sb/ui/components';
import { margin } from '@sb/ui/styles';

import { Disclaimer } from '../Disclaimer';

import { CollisionThresholds } from './CollisionThresholds';

export function CollisionsTab() {
  return (
    <>
      <Disclaimer />
      <Typography component="h5" className={margin.bottom.small}>
        Collision Detection
      </Typography>
      <Typography variant="medium">
        The robot will trigger a protective stop when it detects a collision,
        either based on an unexpected change in torque or acceleration, as
        measured in each joint. Adjust the settings below to change the
        sensitivity of collision detection.
      </Typography>
      <CollisionThresholds />
    </>
  );
}

import { useCallback } from 'react';

import type { IOLevel } from '@sb/routine-runner';
import type { Robot } from '@sb/types';
import { IconButton } from '@sb/ui/components';
import { PlayCircleIcon } from '@sb/ui/icons';
import { useIsRobotConnected, useSetIO } from '@sbrc/hooks';

interface IOSendSignalButtonProps {
  className?: string;
  disabled: boolean;
  ioLevel: IOLevel;
  ioPort: number;
  isVizbot?: boolean;
  kind: Robot.IOPortKind;
  robotID: string | null;
}

const IOSendSignalButton = ({
  className,
  disabled,
  ioLevel,
  ioPort,
  isVizbot,
  kind,
  robotID,
}: IOSendSignalButtonProps) => {
  const { setInputIO, setOutputIO } = useSetIO(robotID, isVizbot);

  const isConnected = useIsRobotConnected({ robotID });

  const isSendSignalButtonDisabled = !isConnected || disabled;

  const onSetIO = useCallback(() => {
    if (kind === 'Input') {
      setInputIO(ioPort, ioLevel);
    }

    if (kind === 'Output') {
      setOutputIO(ioPort, ioLevel);
    }
  }, [ioLevel, ioPort, kind, setInputIO, setOutputIO]);

  return (
    <IconButton
      className={className}
      disabled={isSendSignalButtonDisabled}
      onClick={onSetIO}
    >
      <PlayCircleIcon variant="gray" size="small" />
    </IconButton>
  );
};

export default IOSendSignalButton;

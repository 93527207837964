import SectionContainer from '@sbrc/components/visualizer-view-shared/widget-panel/SectionContainer';

import { CommandExecute } from './CommandExecute';
import GripperScrewLength from './GripperScrewLength';
import GripperShankPosition from './GripperShankPosition';
import GripperTargetForce from './GripperTargetForce';
import GripperTargetTorque from './GripperTargetTorque';
import ApplyChangesButton from './RepositionShankButton';
import type { DualValueModeProps } from './types';

export default function GripperControlDualValueMode(props: DualValueModeProps) {
  const { gripperControlState, routineRunnerArgs } = props;

  return (
    <>
      <SectionContainer title="Command (Hold to apply)">
        <GripperTargetTorque {...props} />
        <GripperScrewLength {...props} />
        <GripperTargetForce {...props} />
        <CommandExecute
          gripperControlState={gripperControlState}
          routineRunnerArgs={routineRunnerArgs}
        />
      </SectionContainer>
      <SectionContainer title="Set Z Axis position">
        <GripperShankPosition {...props} />
        <ApplyChangesButton {...props} />
      </SectionContainer>
    </>
  );
}

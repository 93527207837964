import { RangeSpinner, useDualValueRangeSpinner } from '@sb/ui/components';
import { convertMetersToMillimeterString } from '@sb/utilities';
import SectionContainer from '@sbrc/components/visualizer-view-shared/widget-panel/SectionContainer';

import { OR_2FG7_DIAMETER_METERS_SLIDER_STEP } from '../..';

import type { DualValueModeProps } from './types';

export default function GripperWidth({
  gripperControlState,
}: DualValueModeProps) {
  const {
    changeTargetDiameter,
    diameterRange,
    isDisabled,
    routineRunnerDiameter,
    targetDiameterMeters,
  } = gripperControlState;

  const spinnerProps = useDualValueRangeSpinner({
    primaryValue: routineRunnerDiameter ?? 0.05, // default to 5cm
    secondaryValue: targetDiameterMeters,
    step: OR_2FG7_DIAMETER_METERS_SLIDER_STEP,
    ...diameterRange,
    onChange: changeTargetDiameter,
    isDisabled,
  });

  return (
    <SectionContainer
      hasMeter
      title="Grip Width"
      extraTitle="5 second actuation"
    >
      <RangeSpinner
        {...spinnerProps}
        valueToString={convertMetersToMillimeterString}
      />
    </SectionContainer>
  );
}

import cx from 'classnames';

import { Typography } from '@sb/ui/components';
import { margin, display, alignItems, justifyContent } from '@sb/ui/styles';

import styles from './GripperStatus.module.css';

type Props = {
  status: {
    isBusy: boolean;
    isGripDetected: boolean;
    isForceGripDetected: boolean;
  };
};

export function GripperStatus(props: Props) {
  const { status } = props;

  const statusDisplays = [
    ['Busy', status.isBusy],
    ['Gripped', status.isGripDetected],
    ['Force Gripped', status.isForceGripDetected],
  ] as const;

  return (
    <div
      className={cx(
        display.flex.row,
        justifyContent.spaceAround,
        margin.bottom.extraSmall,
      )}
    >
      {statusDisplays.map(([title, value]) => (
        <div key={title} className={cx(display.flex.row, alignItems.center)}>
          <div
            className={cx({
              [margin.right.extraSmall]: true,
              [styles.status]: true,
              [styles.active]: value,
              [styles.inactive]: !value,
            })}
          />
          <Typography variant="small" color={value ? 'black' : 'gray'}>
            {title}
          </Typography>
        </div>
      ))}
    </div>
  );
}

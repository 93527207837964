import React from 'react';

import {
  ABSOLUTE_MAX_TORQUE_SHOCK_COLLISION_THRESHOLDS,
  ABSOLUTE_MAX_ACCELERATION_COLLISION_THRESHOLDS,
  DEFAULT_TORQUE_SHOCK_COLLISION_THRESHOLDS,
  DEFAULT_ACCELERATION_COLLISION_THRESHOLDS,
  JOINT_NUMBERS,
} from '@sb/motion-planning';
import { Typography } from '@sb/ui/components';
import { JOINT_NAMES } from '@sbrc/utils';

import { CollisionThresholdField } from './CollisionThresholdField';
import { LimitsSectionHeader } from './LimitsSectionHeader';

import styles from './CollisionThresholds.module.css';

const TORQUE_COLLISION_STEP = 5;
const ACCEL_COLLISION_STEP = 5;

export function CollisionThresholds() {
  return (
    <div className={styles.grid}>
      <LimitsSectionHeader />

      {JOINT_NUMBERS.map((jointNumber) => (
        <React.Fragment key={jointNumber}>
          <Typography variant="medium">{JOINT_NAMES[jointNumber]}</Typography>

          <CollisionThresholdField
            torqueFieldName={`collisionThresholds[${jointNumber}]`}
            torqueSuffix="N⋅m"
            torqueStep={TORQUE_COLLISION_STEP}
            torquePlaceholderValue={
              DEFAULT_TORQUE_SHOCK_COLLISION_THRESHOLDS[jointNumber]
            }
            torqueRange={[
              0,
              ABSOLUTE_MAX_TORQUE_SHOCK_COLLISION_THRESHOLDS[jointNumber],
            ]}
            accelerationFieldName={`accelerationThreshold[${jointNumber}]`}
            accelerationSuffix="m/s²"
            accelerationStep={ACCEL_COLLISION_STEP}
            accelerationPlaceholderValue={
              DEFAULT_ACCELERATION_COLLISION_THRESHOLDS[jointNumber]
            }
            accelerationRange={[
              0,
              ABSOLUTE_MAX_ACCELERATION_COLLISION_THRESHOLDS[jointNumber],
            ]}
          />
        </React.Fragment>
      ))}
    </div>
  );
}

import { useEffect } from 'react';

import type { DeviceCommand } from '@sb/integrations/device';
import type { OnRobot2FG7State } from '@sb/integrations/OnRobot2FG7';
import {
  areOR2FG7GripperPositionsEqual,
  getOR2FG7ActiveWidth,
} from '@sb/integrations/OnRobot2FG7/util';
import { GripperControlDualValueMode } from '@sb/integrations/OnRobot2FG7/widget/dual-value-mode/GripperControlDualValueMode';
import { useGripperControlState } from '@sb/integrations/OnRobot2FG7/widget/gripper-control-state';
import type { Integrations, Robot } from '@sb/types';
import { Button } from '@sb/ui/components';
import { DownloadIcon, ResetAllIcon } from '@sb/ui/icons';
import {
  sharedStyles,
  WidgetView,
} from '@sbrc/components/visualizer-view-shared/widget-panel';

import type { CompatibleCommand, OnRobotDualQuickChangerCommand } from '..';

interface DualOnRobot2FG7WidgetInnerProps {
  isVizbot: boolean;
  robot: Robot.ConvertedResponse;
  onCommandChange?: React.Dispatch<React.SetStateAction<DeviceCommand | null>>;
  onClose: () => void;
  additionalControls?: React.ReactNode;
  routineRunnerGripperState: OnRobot2FG7State;
  otherRobotGripperState: OnRobot2FG7State | null;
  routineRunnerPayload: number;
  dualChangerCommand: OnRobotDualQuickChangerCommand;
  changeActiveGripperCommand: (action: CompatibleCommand) => void;
  getEquipmentByKind: Integrations.GetEquipmentByKind;
}

export function DualOnRobot2FG7WidgetInner({
  dualChangerCommand,
  changeActiveGripperCommand,
  routineRunnerGripperState,
  isVizbot,
  robot,
  onCommandChange,
  onClose,
  otherRobotGripperState,
  routineRunnerPayload,
  additionalControls,
  getEquipmentByKind,
}: DualOnRobot2FG7WidgetInnerProps) {
  const routineRunnerArgs = {
    robotID: robot.id,
    isVizbot,
  };

  const gripperControlState = useGripperControlState({
    ...routineRunnerArgs,
    routineRunnerGripperState,
    routineRunnerPayload,
  });

  const {
    canApplyGripperChanges,
    changeTargetDiameter,
    changeGripKind,
    conformGripperControlStateToActualState,
    targetDiameterMeters,
    command,
  } = gripperControlState;

  useEffect(() => {
    if (onCommandChange) {
      onCommandChange(dualChangerCommand);
    }
  }, [onCommandChange, dualChangerCommand]);

  useEffect(() => {
    changeActiveGripperCommand(command);
  }, [changeActiveGripperCommand, command]);

  const onRobot2FG7Implementation = getEquipmentByKind('OnRobot2FG7');

  const buttonText = isVizbot ? 'Live Robot' : 'Visualizer';

  const areGripperPositionsEqual = areOR2FG7GripperPositionsEqual(
    otherRobotGripperState,
    targetDiameterMeters,
    command,
  );

  return (
    <WidgetView
      testPrefix="onrobot-2fg7-control"
      onClose={onClose}
      headerTitle={onRobot2FG7Implementation.getDisplayName()}
      headerIcon={<onRobot2FG7Implementation.InlineIcon />}
      headerButton={
        <>
          <Button
            className={sharedStyles.headerButtonText}
            startIcon={<DownloadIcon className={sharedStyles.buttonIcon} />}
            label="Import gripper state"
            size="extraSmall"
            variant="gray"
            onClick={() => {
              if (!otherRobotGripperState) return;

              const diameter = getOR2FG7ActiveWidth(otherRobotGripperState);

              changeGripKind(otherRobotGripperState?.gripKind ?? 'inward');
              changeTargetDiameter(diameter);
            }}
            disabled={areGripperPositionsEqual}
          >
            {buttonText}
          </Button>

          <Button
            startIcon={<ResetAllIcon className={sharedStyles.buttonIcon} />}
            label="Reset to current gripper state"
            size="extraSmall"
            variant="gray"
            onClick={() => {
              conformGripperControlStateToActualState(
                routineRunnerGripperState,
              );
            }}
            disabled={!canApplyGripperChanges}
            data-testid="gripper-widget-reset-current-gripper-state-button"
          />
        </>
      }
    >
      <div className={sharedStyles.controls}>
        {additionalControls}

        <GripperControlDualValueMode
          gripperControlState={gripperControlState}
          routineRunnerArgs={routineRunnerArgs}
          dualChangerCommand={dualChangerCommand}
        />
      </div>
    </WidgetView>
  );
}

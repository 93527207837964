import { useCallback, useState, useMemo } from 'react';

import { useNotifications } from '@sbrc/hooks';

import NotificationDropdown from './NotificationDropdown';
import type { NotificationSystemColorVariant } from './shared';
import { NotificationSystemContext, NOTIFICATION_LIST_MAX } from './shared';

interface NotificationSystemProps {
  dropdownClassName?: string;
  variant?: NotificationSystemColorVariant;
}

/**
 * This component handles the entire logic for displaying a notification
 * system, including the visual aspects (the dropdown menu and the alert
 * bar displayed at the bottom of each page).
 */
export default function NotificationSystem({
  dropdownClassName,
  variant = 'dark',
}: NotificationSystemProps) {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const notifications = useNotifications();

  /** Notifications that have not been archived by the user. */
  const nonArchivedNotifications = useMemo(() => {
    return notifications.filter((notification) => {
      return notification.status !== 'archived';
    });
  }, [notifications]);

  const archivedNotifications = notifications.filter((notification) => {
    return notification.status === 'archived';
  });

  /** Max limit of notifications displayed. */
  const maxNonArchivedNotifications = nonArchivedNotifications.slice(
    0,
    NOTIFICATION_LIST_MAX,
  );

  const closeDropdown = () => setIsDropdownOpen(false);

  const openDropdown = useCallback((onOpenDropdown?: () => void) => {
    setIsDropdownOpen(true);
    onOpenDropdown?.();
  }, []);

  return (
    <NotificationSystemContext.Provider
      value={{
        isDropdownOpen,
        closeDropdown,
        openDropdown,
        nonArchivedNotifications,
        maxNonArchivedNotifications,
        archivedNotifications,
      }}
    >
      <NotificationDropdown variant={variant} className={dropdownClassName} />
    </NotificationSystemContext.Provider>
  );
}

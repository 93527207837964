import cx from 'classnames';

import { Badge, Button, Typography } from '@sb/ui/components';
import { WarningIcon } from '@sb/ui/icons';
import { alignItems, display, justifyContent, margin } from '@sb/ui/styles';

import type { SoftwareBuildData } from './useSoftwareBuildData';

import styles from './SoftwareReadyToInstall.module.css';

interface SoftwareReadyToInstallProps {
  buildData: SoftwareBuildData;
}

export function SoftwareReadyToInstall({
  buildData,
}: SoftwareReadyToInstallProps) {
  const {
    targetBuildID,
    targetBuildName,
    lastErrorMessage,
    isInstalling,
    onRequestInstall,
  } = buildData;

  return (
    <div className={styles.installContent}>
      <div
        className={cx(
          display.flex.row,
          justifyContent.start,
          alignItems.center,
          margin.left.small,
        )}
      >
        <Typography className={margin.right.small} variant="medium" isBold>
          {targetBuildName}
        </Typography>
        <Badge variant="green">NEW</Badge>
      </div>

      <Typography
        color="gray"
        variant="small"
        className={cx(margin.left.small, margin.bottom.small)}
      >
        {targetBuildID}
      </Typography>
      <Typography
        color="gray"
        className={cx(
          margin.bottom.medium,
          margin.top.small,
          margin.left.small,
        )}
      >
        Update software to gain access to the latest features and improvements.
      </Typography>

      <div
        className={cx(
          display.flex.row,
          justifyContent.spaceBetween,
          styles.actionContainer,
          alignItems.center,
        )}
      >
        {!lastErrorMessage && (
          <Typography variant="small" color="gray">
            {isInstalling ? 'Installing...' : 'Update is ready to install'}
          </Typography>
        )}

        {lastErrorMessage && (
          <>
            <WarningIcon
              size="small"
              color="var(--standard-bots--warning-main)"
            />
            <Typography>
              A problem occurred in the software update process.
            </Typography>
          </>
        )}
        <Button disabled={isInstalling} onClick={onRequestInstall}>
          Update Now
        </Button>
      </div>
    </div>
  );
}

import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';

import type { DBBackupData, USBDriveData } from '@sb/feathers-types';
import { Button, Loader, Select, SelectItem } from '@sb/ui/components';
import { ModalContext } from '@sb/ui/components/Modal/shared';
import Typography from '@sb/ui/components/Typography';
import margin from '@sb/ui/styles/margin';
import { createBackup } from '@sbrc/services/feathers-client';

import { DriveStatusIcon } from './DriveStatusIcon';

import styles from './CreateBackupTab.module.css';

export const CreateBackupTab = ({
  usbData,
  robotID,
}: {
  usbData: USBDriveData[];
  robotID: string;
}) => {
  const { onClose } = useContext(ModalContext);
  const [backupData, setBackupData] = useState<DBBackupData | null>(null);

  const [driveData, setDriveData] = useState(
    usbData.length > 0 ? usbData[0] : null,
  );

  const [isBackingUp, setIsBackingUp] = useState<boolean>(false);

  useEffect(() => {
    const driveIndex = usbData.findIndex(
      (drive) => drive.label === driveData?.label,
    );

    if (!driveData || driveIndex === -1) {
      setDriveData(usbData.length > 0 ? usbData[0] : null);
    } else {
      setDriveData(usbData[driveIndex]);
    }
  }, [usbData, driveData, setDriveData]);

  const createBackupClick = async () => {
    if (!driveData?.label) {
      return;
    }

    setBackupData(null);
    setIsBackingUp(true);

    try {
      const createdBackupData = await createBackup(driveData?.label, robotID);
      setIsBackingUp(false);
      setBackupData(createdBackupData);
    } catch (err) {
      setIsBackingUp(false);

      setBackupData({
        path: driveData.label,
        robotID,
        key: '',
        date: new Date(),
        status: 'failed',
        error: err.message,
      });
    }
  };

  let driveHeader;
  let driveHeaderLabel;
  let subText;
  let backupPossible = false;

  if (driveData) {
    backupPossible = true;

    driveHeaderLabel = isBackingUp
      ? `Backing up to "${driveData.label}."`
      : driveData.label;

    if (backupData) {
      subText = backupData.error ?? backupData.status ?? 'creating backup';

      if (backupData.status === 'failed' || backupData.error) {
        driveHeaderLabel = `Error occurred writing to "${driveData.label}"`;

        subText =
          backupData.error ??
          'You can retry, try another drive, or contact support.';
      }

      if (backupData.status === 'success') {
        driveHeaderLabel = `Backup saved to "${driveData.label}."`;
        subText = 'You can restore it later via this window.';
      }
    } else
      subText =
        driveData.backups.length === 0
          ? 'No existing backups detected.'
          : `Last backup: ${dayjs(driveData.backups[0].date).format(
              'ddd, MMM D, YYYY HH:mm',
            )}`;
  } else {
    driveHeaderLabel = 'No disk inserted.';
  }

  if (usbData.length > 1 && !isBackingUp && !backupData) {
    driveHeader = (
      <Select
        activeLabel={
          <Typography variant="medium" isBold>
            {driveData?.label ?? 'select drive...'}
          </Typography>
        }
      >
        {usbData.map((usb) => (
          <SelectItem key={usb.label} onClick={() => setDriveData(usb)}>
            {usb.label}
          </SelectItem>
        ))}
      </Select>
    );
  } else {
    driveHeader = (
      <Typography variant="medium" isBold>
        {driveHeaderLabel}
      </Typography>
    );
  }

  return (
    <>
      <Typography variant="medium">
        To create a backup of the routines on this robot, insert a thumbdrive or
        other USB storage device into the USB-A port on the bottom of the
        control box.
      </Typography>
      <div className={styles.drivePanel}>
        {driveData && (
          <DriveStatusIcon
            className={margin.right.small}
            status={backupData?.status}
          />
        )}
        <div className={styles.driveTextContainer}>
          {driveHeader}
          {subText && (
            <Typography variant="medium" color="gray">
              {isBackingUp ? 'Creating backup...' : subText}
            </Typography>
          )}
        </div>
        {isBackingUp && <Loader className={margin.right.small} />}
        {!isBackingUp && !backupData && (
          <Button
            disabled={isBackingUp || !backupPossible}
            onClick={createBackupClick}
          >
            Create Backup
          </Button>
        )}
        {backupData?.status === 'success' && (
          <Button variant="secondary" onClick={onClose}>
            Done
          </Button>
        )}
        {!isBackingUp && backupData?.status === 'failed' && (
          <Button variant="alertSecondary" onClick={createBackupClick}>
            Retry
          </Button>
        )}
      </div>
    </>
  );
};

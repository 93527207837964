import {
  ABSOLUTE_MAX_TOOLTIP_SPEED,
  COLLABORATIVE_MAX_TOOLTIP_SPEED,
  DEFAULT_MAX_TOOLTIP_SPEED,
} from '@sb/motion-planning';
import { Typography } from '@sb/ui/components';
import { VelocityNumberInput } from '@sbrc/components/units';

import { LimitField } from './LimitField';
import { LimitsSectionHeader } from './LimitsSectionHeader';

const TOOLTIP_MIN_SPEED = 0.01;
const TOOLTIP_STEP = 0.25;

export function TooltipLimits() {
  return (
    <>
      <LimitsSectionHeader title="Tooltip" />

      <Typography variant="medium">Max Velocity</Typography>
      <LimitField
        fieldName="maxTooltipSpeed"
        slowFieldName="slowTooltipSpeed"
        defaultValue={DEFAULT_MAX_TOOLTIP_SPEED}
        minValue={TOOLTIP_MIN_SPEED}
        maxValue={ABSOLUTE_MAX_TOOLTIP_SPEED}
        collaborativeValue={COLLABORATIVE_MAX_TOOLTIP_SPEED}
        step={TOOLTIP_STEP}
        isSpeedLimitField
        NumberInputComponent={VelocityNumberInput}
      />
    </>
  );
}

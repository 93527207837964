import type { Robot } from '@sb/types';
import { Button, Typography } from '@sb/ui/components';
import { ControlPanelIcon, InfoIcon } from '@sb/ui/icons';
import { display, margin } from '@sb/ui/styles';

import IOCard from './IOCard';

interface SafetyIOMessageProps {
  ioInputs: Robot.IOPort[];
  onOpenSafetySettings?: () => void;
}

export function SafetyIOMessage({
  ioInputs,
  onOpenSafetySettings,
}: SafetyIOMessageProps) {
  if (ioInputs.every(({ safeguardKind }) => safeguardKind === undefined)) {
    return null;
  }

  return (
    <IOCard containerClassName={display.flex.row}>
      <InfoIcon className={margin.right.small} />
      <Typography variant="medium" className={margin.right.auto}>
        Some inputs below have been assigned to a safety function of the robot.
        <br />
        This can be changed only from Safety Settings.
      </Typography>
      {onOpenSafetySettings && (
        <Button
          startIcon={<ControlPanelIcon />}
          variant="gray"
          onClick={onOpenSafetySettings}
        >
          Safety Settings
        </Button>
      )}
    </IOCard>
  );
}

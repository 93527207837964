import {
  screwLengthStringConvert,
  SCREW_LENGTH_RANGE,
} from '@sb/integrations/OnRobotScrewdriver';
import {
  RangeSpinner,
  useSingleValueRangeSpinner,
  Typography,
} from '@sb/ui/components';

import type { DualValueModeProps } from './types';

export default function GripperScrewLength({
  gripperControlState,
}: DualValueModeProps) {
  const { changeScrewLength, target } = gripperControlState;

  const spinnerProps = useSingleValueRangeSpinner({
    value: target.targetScrewLength,
    min: SCREW_LENGTH_RANGE.min,
    max: SCREW_LENGTH_RANGE.max,
    step: SCREW_LENGTH_RANGE.step,
    onChange: changeScrewLength,
  });

  return (
    <>
      <Typography>Screw Length</Typography>
      <RangeSpinner
        {...spinnerProps}
        valueToString={screwLengthStringConvert}
      />
    </>
  );
}

import { useMemo } from 'react';

import type { Integrations } from '@sb/types';
import { Loader, Select, SelectItem } from '@sb/ui/components';
import { usePageRobotAndRoutine } from '@sbrc/hooks';

import type { DualChangerChildren } from '..';

interface GripperSelectProps {
  onGripperChange: (action: DualChangerChildren) => void;
  selectedGripper: DualChangerChildren;
  getEquipmentByKind: Integrations.GetEquipmentByKind;
}

export function GripperSelect({
  onGripperChange,
  selectedGripper,
  getEquipmentByKind,
}: GripperSelectProps) {
  const { routine } = usePageRobotAndRoutine();

  const equipmentList = routine?.equipment;

  const dualQuickChanger = equipmentList?.find(
    ({ kind }) => kind === 'OnRobotDualQuickChanger',
  );

  const dualImplementations = useMemo(() => {
    if (dualQuickChanger?.kind === 'OnRobotDualQuickChanger') {
      const primary = getEquipmentByKind(
        dualQuickChanger.grippers.primary.kind,
      );

      const secondary = getEquipmentByKind(
        dualQuickChanger.grippers.secondary.kind,
      );

      return {
        primaryName: `Primary: ${primary.getDisplayName()}`,
        secondaryName: `Secondary: ${secondary.getDisplayName()}`,
      };
    }

    return undefined;
  }, [dualQuickChanger, getEquipmentByKind]);

  if (!dualImplementations) return <Loader />;

  return (
    <Select
      activeLabel={
        selectedGripper === 'primary'
          ? dualImplementations.primaryName
          : dualImplementations.secondaryName
      }
    >
      <SelectItem
        isSelected={selectedGripper === 'primary'}
        onClick={() => onGripperChange('primary')}
      >
        {dualImplementations.primaryName}
      </SelectItem>
      <SelectItem
        isSelected={selectedGripper === 'secondary'}
        onClick={() => onGripperChange('secondary')}
      >
        {dualImplementations.secondaryName}
      </SelectItem>
    </Select>
  );
}

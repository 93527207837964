import { clamp } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

import { PAYLOAD_MASS_KG_DEFAULT } from '@sb/routine-runner';
import {
  useIsAnotherSessionRunningAdHocCommand,
  useRobotRoutineRunningState,
} from '@sbrc/hooks';
import type { UseRoutineRunnerHandleArguments } from '@sbrc/hooks';

import type {
  OnRobot2FG7Command,
  OR2FG7GripKind,
  OnRobot2FG7State,
} from '../..';
import {
  OR_2FG7_DIAMETER_METERS_SLIDER_STEP,
  OR_2FG7_FORCE_NEWTONS_SLIDER_STEP,
  OR_2FG7_TARGET_FORCE_DEFAULT,
  COMMAND_DEFAULT,
  OUTWARD_MOUNT_INWARD_GRIP_DIAMETER_RANGE,
  FORCE_RANGE,
} from '../../constants';
import { getOR2FG7ActiveWidth, getOR2FG7DiameterRange } from '../../util';

type GripperRange = {
  min: number;
  max: number;
};

type GripperConformState = 'outdated' | 'diverged' | 'updated' | 'actuating';

type GripperControlStateOutput = {
  canApplyGripperChanges: boolean;
  changeGripKind: (
    gripKind: OnRobot2FG7Command['gripKind'],
  ) => OnRobot2FG7Command;
  changeTargetDiameter: (action: React.SetStateAction<number>) => void;
  changeTargetForce: (action: React.SetStateAction<number> | undefined) => void;
  command: OnRobot2FG7Command;
  conformGripperControlStateToActualState: (state: OnRobot2FG7State) => void;
  diameterRange: GripperRange;
  error?: string;
  forceRange: GripperRange;
  isConnected: boolean;
  isDiameterEqual: boolean;
  isDisabled: boolean;
  linearSensorError: boolean;
  routineRunnerDiameter: number;
  routineRunnerForce: number;
  routineRunnerGripKind: OnRobot2FG7Command['gripKind'];
  setConformedState: (formState: GripperConformState) => void;
  setTargetPayload: React.Dispatch<React.SetStateAction<number>>;
  targetDiameterMeters: number;
  targetPayload: number;
  uncalibratedError: boolean;
};

interface UseGripperControlStateArguments
  extends UseRoutineRunnerHandleArguments {
  routineRunnerGripperState: OnRobot2FG7State;
  routineRunnerPayload: number | null;
}

const useGripperControlState = (
  args: UseGripperControlStateArguments,
): GripperControlStateOutput => {
  // Whether the form has been modified since load.
  // If not, updates to the gripper state should modify the form state.
  const { routineRunnerPayload, routineRunnerGripperState } = args;

  const actualPayloadValue = routineRunnerPayload ?? PAYLOAD_MASS_KG_DEFAULT;

  const [conformedState, setConformedState] =
    useState<GripperConformState>('outdated');

  const [targetPayload, setTargetPayload] =
    useState<number>(actualPayloadValue);

  const [command, setCommand] = useState<OnRobot2FG7Command>(COMMAND_DEFAULT);

  const {
    gripKind,
    targetForce = COMMAND_DEFAULT.targetForce,
    targetDiameter,
  } = command;

  const isAnotherSessionMovingRobot = useIsAnotherSessionRunningAdHocCommand({
    robotID: args.robotID,
  });

  const routineRunningState = useRobotRoutineRunningState({
    robotID: args.robotID,
  });

  const isRoutineRunning = routineRunningState !== null;

  const routineRunnerForce =
    routineRunnerGripperState?.force ?? (COMMAND_DEFAULT.targetForce as number);

  const routineRunnerGripKind =
    routineRunnerGripperState?.gripKind ?? command.gripKind;

  const routineRunnerDiameter = routineRunnerGripperState
    ? getOR2FG7ActiveWidth(routineRunnerGripperState)
    : COMMAND_DEFAULT.targetDiameter;

  const diameterRange: GripperRange = getOR2FG7DiameterRange(gripKind);

  const changeGripKind = (newGripKind: OR2FG7GripKind): OnRobot2FG7Command => {
    if (!routineRunnerGripperState) {
      return command;
    }

    setConformedState('diverged');

    let offset = 0;

    // TODO: Handle finger orientation - currently hardcoded to outward mount
    // changing from outward to inward
    if (newGripKind === 'inward' && command.gripKind === 'outward') {
      offset -= routineRunnerGripperState.fingertipOffset;
    }
    // changing from inward to outward
    else if (newGripKind === 'outward' && command.gripKind === 'inward') {
      offset += routineRunnerGripperState.fingertipOffset;
    }

    const newCommand: OnRobot2FG7Command = {
      ...command,
      targetDiameter: command.targetDiameter + offset,
      gripKind: newGripKind,
    };

    setCommand(newCommand);

    return newCommand;
  };

  const changeTargetDiameter = (action: React.SetStateAction<number>) => {
    setConformedState('diverged');

    setCommand((previousCommand) => {
      let meters: number;

      if (typeof action === 'function') {
        if (previousCommand) {
          meters = action(previousCommand.targetDiameter);
        } else {
          meters = action(OUTWARD_MOUNT_INWARD_GRIP_DIAMETER_RANGE.min);
        }
      } else {
        meters = action;
      }

      return {
        ...previousCommand,
        targetDiameter: clamp(meters, diameterRange.min, diameterRange.max),
      };
    });
  };

  const changeTargetForce = (
    action: React.SetStateAction<number> | undefined,
  ) => {
    setConformedState('diverged');

    setCommand((previousState) => {
      let newtons: number | undefined;

      if (typeof action === 'function') {
        newtons = action(
          previousState?.targetForce ?? OR_2FG7_TARGET_FORCE_DEFAULT,
        );
      } else {
        newtons = action;
      }

      return {
        ...previousState,
        targetForce: newtons
          ? clamp(newtons, FORCE_RANGE.min, FORCE_RANGE.max)
          : undefined,
      };
    });
  };

  /* Make the temporary form state conform to the actual observed state
   * after actuation completes.
   *
   * For example, if the command was "grip to 10cm" but the actuation
   * resulted in a grasp of an object with width 15cm, we want the gripper
   * control state to be updated to 15cm once the actuation is complete.
   *
   * Accomplish this by marking the form as outdated, so the `useEffect`
   * will reset it.
   */
  const conformGripperControlStateToActualState = useCallback(
    (gripperRoutineRunnerState: OnRobot2FG7State) => {
      if (!gripperRoutineRunnerState) return;

      setCommand((previousState) => {
        const currentGripKind =
          gripperRoutineRunnerState.gripKind ?? previousState.gripKind;

        const orientation = currentGripKind === 'inward' ? 'inner' : 'outer';

        return {
          ...previousState,
          gripKind: gripperRoutineRunnerState.gripKind,
          targetDiameter: gripperRoutineRunnerState.width[orientation],
          targetForce:
            gripperRoutineRunnerState.force ?? OR_2FG7_TARGET_FORCE_DEFAULT,
        } as OnRobot2FG7Command;
      });

      setTargetPayload(actualPayloadValue);

      setConformedState('updated');
    },
    [actualPayloadValue],
  );

  useEffect(() => {
    if (
      conformedState !== 'diverged' &&
      conformedState !== 'actuating' &&
      routineRunnerGripperState
    ) {
      conformGripperControlStateToActualState(routineRunnerGripperState);
    }
  }, [
    conformGripperControlStateToActualState,
    conformedState,
    routineRunnerGripperState,
  ]);

  const isForceEqual =
    typeof targetForce === 'number' &&
    typeof routineRunnerForce === 'number' &&
    Math.abs(routineRunnerForce - targetForce) <
      OR_2FG7_FORCE_NEWTONS_SLIDER_STEP;

  const isDiameterEqual =
    typeof routineRunnerDiameter === 'number' &&
    typeof targetDiameter === 'number' &&
    Math.abs(routineRunnerDiameter - targetDiameter) <
      OR_2FG7_DIAMETER_METERS_SLIDER_STEP;

  const canApplyGripperChanges =
    !isRoutineRunning &&
    !isAnotherSessionMovingRobot &&
    (conformedState === 'actuating' ||
      !isDiameterEqual ||
      !isForceEqual ||
      routineRunnerGripKind !== command.gripKind ||
      routineRunnerPayload !== targetPayload);

  const { linearSensorError, uncalibratedError } = routineRunnerGripperState;

  return {
    canApplyGripperChanges,
    changeGripKind,
    changeTargetDiameter,
    changeTargetForce,
    command,
    conformGripperControlStateToActualState,
    diameterRange,
    error: routineRunnerGripperState.error,
    forceRange: FORCE_RANGE,
    isConnected: routineRunnerGripperState.isConnected,
    isDiameterEqual,
    isDisabled:
      !routineRunnerGripperState.isConnected ||
      Boolean(routineRunnerGripperState.error) ||
      linearSensorError ||
      uncalibratedError,
    linearSensorError,
    routineRunnerDiameter,
    routineRunnerForce,
    routineRunnerGripKind,
    setConformedState,
    setTargetPayload,
    targetDiameterMeters: targetDiameter,
    targetPayload,
    uncalibratedError,
  };
};

export default useGripperControlState;

import { Loader } from '@sb/ui/components';

import styles from './shared.module.css';

export function LoadingState() {
  return (
    <div className={styles.loadingState}>
      <Loader />
    </div>
  );
}

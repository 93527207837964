import { useCallback, useMemo, useState } from 'react';

import type { Robot } from '@sb/types';
import { Button, Modal, ModalHeader, TabItem, Tabs } from '@sb/ui/components';
import { useIsEqual, useToast } from '@sbrc/hooks';
import { updateRobot } from '@sbrc/services';

import UnsavedChangesAlert from '../UnsavedChangesAlert';

import IOEditor from './IOEditor';
import { SafetyIOMessage } from './SafetyIOMessage';

import styles from './IOManagerModal.module.css';

interface IOManagerModalProps {
  defaultTab?: Robot.IOPortKind;
  isOpen: boolean;
  onClose: () => void;
  onOpenSafetySettings?: () => void;
  robot: Robot.ConvertedResponse;
}

const IOManagerModal = ({
  defaultTab,
  isOpen,
  onClose,
  onOpenSafetySettings,
  robot,
}: IOManagerModalProps) => {
  const { setToast, toastState } = useToast();

  const [ioInputs, setIoInputs] = useState<Robot.IOPort[]>(robot.ioInputs);

  const [ioOutputs, setIOOutputs] = useState<Robot.IOPort[]>(robot.ioOutputs);

  const [isUnsavedChangesAlertOpen, setIsUnsavedChangesAlertOpen] =
    useState<boolean>(false);

  /**
   * Keep track if all ports have valid configurations. This is updated using
   * the `onValidationChange` callback sent by the `IOEditor` component.
   */
  const [ioValidationByPort, setIoValidationByPort] = useState<
    Record<number, boolean>
  >({});

  /** Find if at least one IO port has an invalid configuration. */
  const isIOPortInvalid: boolean = useMemo(() => {
    return Object.values(ioValidationByPort).some((isValid) => !isValid);
  }, [ioValidationByPort]);

  const isIOInputEqual = useIsEqual(ioInputs, robot.ioInputs);
  const isIOOutputEqual = useIsEqual(ioOutputs, robot.ioOutputs);
  const isIOConfigurationEqual: boolean = isIOInputEqual && isIOOutputEqual;

  const isSaveChangesDisabled: boolean =
    isIOConfigurationEqual ||
    toastState?.kind === 'progress' ||
    isIOPortInvalid;

  const onIOInputChange = useCallback(
    (ioInput: Robot.IOPort, index: number) => {
      setIoInputs((previousState) => {
        const newIOInputs = [...previousState];
        newIOInputs[index] = ioInput;

        return newIOInputs;
      });
    },
    [],
  );

  const onIOOutputChange = useCallback(
    (ioOutput: Robot.IOPort, index: number) => {
      setIOOutputs((previousState) => {
        const newIOOutputs = [...previousState];
        newIOOutputs[index] = ioOutput;

        return newIOOutputs;
      });
    },
    [],
  );

  const onSaveChangesClick = async () => {
    setToast({
      kind: 'progress',
      message: `${robot.name}: Saving I/O configuration...`,
    });

    try {
      await updateRobot(robot.id, { ioInputs, ioOutputs });

      setToast({
        kind: 'success',
        message: `${robot.name}: I/O configuration successfully saved!`,
      });

      onClose();
    } catch (error) {
      setToast({ kind: 'error', message: `${robot.name}: ${error.message}` });
    }
  };

  const onCancelClick = () => {
    if (!isIOConfigurationEqual) {
      setIsUnsavedChangesAlertOpen(true);
    } else {
      onClose();
    }
  };

  const resetForm = () => {
    setIoInputs(robot.ioInputs);
    setIOOutputs(robot.ioOutputs);
    setIsUnsavedChangesAlertOpen(false);
  };

  const onDiscardChanges = () => {
    resetForm();
    onClose();
  };

  const onIoValidationChange = useCallback(
    (port: number, isValid: boolean): void => {
      setIoValidationByPort((previousState) => {
        return { ...previousState, [port]: isValid };
      });
    },
    [],
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onCancelClick}
      className={styles.ioManagerModal}
    >
      <ModalHeader title="I/O Manager" />

      <Tabs tabListClassName={styles.tabList} defaultTab={defaultTab}>
        <TabItem
          label="Inputs"
          id="Input"
          panel={
            <div className={styles.inputOutputList}>
              <SafetyIOMessage
                ioInputs={ioInputs}
                onOpenSafetySettings={onOpenSafetySettings}
              />

              {ioInputs.map((ioInput, index) => (
                <IOEditor
                  key={`io-port-input-${ioInput.port}`}
                  kind="Input"
                  io={ioInput}
                  ioInputs={ioInputs}
                  ioOutputs={ioOutputs}
                  onIOChange={(newInput) => onIOInputChange(newInput, index)}
                  onValidationChange={onIoValidationChange}
                  robotID={robot.id}
                />
              ))}
            </div>
          }
        />

        <TabItem
          label="Outputs"
          id="Output"
          panel={
            <div className={styles.inputOutputList}>
              {ioOutputs.map((ioOutput, index) => (
                <IOEditor
                  key={`io-port-output-${ioOutput.port}`}
                  kind="Output"
                  io={ioOutput}
                  ioInputs={ioInputs}
                  ioOutputs={ioOutputs}
                  onIOChange={(newOutput) => {
                    onIOOutputChange(newOutput, index);
                  }}
                  onValidationChange={onIoValidationChange}
                  robotID={robot.id}
                />
              ))}
            </div>
          }
        />
      </Tabs>

      <div className={styles.modalAction}>
        <Button variant="gray" onClick={onCancelClick}>
          Cancel
        </Button>
        <Button disabled={isSaveChangesDisabled} onClick={onSaveChangesClick}>
          Save Changes
        </Button>
      </div>

      <UnsavedChangesAlert
        isOpen={isUnsavedChangesAlertOpen}
        onDiscard={onDiscardChanges}
        onKeepEditing={() => setIsUnsavedChangesAlertOpen(false)}
      />
    </Modal>
  );
};

export default IOManagerModal;

import { useEffect, useState } from 'react';

import { getEquipmentByKind } from '@sb/integrations/frontend/getEquipmentByKind';
import type { Robot } from '@sb/types';
import { Modal, Typography } from '@sb/ui/components';
import { AccessoriesIcon } from '@sb/ui/icons';
import {
  useEquipment,
  useLiveRoutineRunnerHandle,
  useRobotGripperState,
  useRobotStateKind,
  useToast,
} from '@sbrc/hooks';
import {
  createEquipment,
  synchronizeGlobalEquipmentWithRoutineRunner,
  deleteEquipment,
  updateEquipment,
} from '@sbrc/services';

import { EditEquipment } from './EditEquipment';
import { EmptyState } from './EmptyState';
import type { GetConnectionStatus } from './EquipmentList';
import { EquipmentList } from './EquipmentList';
import { LoadingState } from './LoadingState';
import { NewEquipmentPicker } from './NewEquipmentPicker';

import styles from './shared.module.css';

interface EquipmentManagerModalProps {
  isOpen: boolean;
  onClose: () => void;
  robot: Robot.ConvertedResponse;
}

type ModalState = 'EquipmentList' | 'NewEquipmentPicker';

export function EquipmentManagerModal({
  isOpen,
  onClose,
  robot,
}: EquipmentManagerModalProps) {
  const robotID = robot.id;

  const routineRunnerHandle = useLiveRoutineRunnerHandle({ robotID });

  const isRoutineRunning = useRobotStateKind({ robotID }) === 'RoutineRunning';

  const [modalState, setModalState] = useState<ModalState>('EquipmentList');

  const equipment = useEquipment({ includeDisabled: true });

  const visibleEquipment =
    equipment?.filter((e) => !getEquipmentByKind(e.config.kind).isHidden?.()) ??
    [];

  const [selectedEquipmentID, setSelectedEquipmentID] = useState<string | null>(
    null,
  );

  const defaultEquipmentID =
    selectedEquipmentID === null && visibleEquipment[0]?.id;

  useEffect(() => {
    if (defaultEquipmentID) {
      setSelectedEquipmentID(defaultEquipmentID);
    }
  }, [defaultEquipmentID, selectedEquipmentID]);

  const selectedEquipment = visibleEquipment.find(
    (e) => e.id === selectedEquipmentID,
  );

  const otherEquipment = visibleEquipment.filter(
    (e) => e.id !== selectedEquipmentID,
  );

  const gripperState = useRobotGripperState({ robotID });

  const getConnectionStatus: GetConnectionStatus = (equipmentItem) => {
    if (
      equipmentItem.isEnabled &&
      gripperState?.kind === equipmentItem.config.kind &&
      'isConnected' in gripperState
    ) {
      return gripperState.isConnected ? 'connected' : 'disconnected';
    }

    return 'unknown';
  };

  const { setToast } = useToast();

  const renderModalContent = () => {
    if (!equipment) {
      return <LoadingState />;
    }

    if (modalState === 'EquipmentList') {
      if (visibleEquipment.length === 0) {
        return <EmptyState onAdd={() => setModalState('NewEquipmentPicker')} />;
      }

      return (
        <div className={styles.sideBySideContainer}>
          <div className={styles.listContainer}>
            <div className={styles.equipmentHeader}>
              <AccessoriesIcon
                size="small"
                className={styles.equipmentHeaderIcon}
              />
              <Typography component="h4">Equipment</Typography>
            </div>
            <EquipmentList
              loading={equipment === undefined}
              equipment={visibleEquipment}
              getConnectionStatus={getConnectionStatus}
              selectedEquipmentID={selectedEquipmentID}
              isChangeDisabled={isRoutineRunning}
              onEdit={(equipmentItem) =>
                setSelectedEquipmentID(equipmentItem.id)
              }
              onAdd={() => setModalState('NewEquipmentPicker')}
              onDelete={async (equipmentItem) => {
                await deleteEquipment(equipmentItem.id);

                synchronizeGlobalEquipmentWithRoutineRunner(
                  routineRunnerHandle,
                );

                setModalState('EquipmentList');
              }}
            />
          </div>
          <div className={styles.detailContainer}>
            {selectedEquipment && (
              <EditEquipment
                isFormDisabled={isRoutineRunning}
                equipmentItem={selectedEquipment}
                otherEquipment={otherEquipment}
                robot={robot}
                onSave={async (updatedEquipmentItemSettings) => {
                  if (selectedEquipment.id) {
                    await updateEquipment(
                      selectedEquipment.id,
                      updatedEquipmentItemSettings,
                    );
                  } else {
                    await createEquipment(updatedEquipmentItemSettings);
                  }

                  synchronizeGlobalEquipmentWithRoutineRunner(
                    routineRunnerHandle,
                  );

                  setToast({
                    kind: 'success',
                    message: 'Equipment item saved',
                  });

                  setModalState('EquipmentList');
                }}
                onClose={onClose}
                getEquipmentByKind={getEquipmentByKind}
                showEnabledSwitch
              />
            )}
          </div>
        </div>
      );
    }

    return (
      <NewEquipmentPicker
        onAddEquipment={async (newEquipment) => {
          const id = await createEquipment(newEquipment);

          setSelectedEquipmentID(id);

          synchronizeGlobalEquipmentWithRoutineRunner(routineRunnerHandle);

          setModalState('EquipmentList');
        }}
        equipment={visibleEquipment}
        onClickBack={() => setModalState('EquipmentList')}
      />
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} className={styles.modal}>
      {renderModalContent()}
    </Modal>
  );
}

import cx from 'classnames';

import type { Integrations } from '@sb/types';
import { Typography } from '@sb/ui/components';
import { margin, padding } from '@sb/ui/styles';

import { IOPortRow } from './IOPortRow';

import styles from './IOPortTable.module.css';

type IOPortTableProps = {
  ports: Integrations.IOPort[];
  onChange: (ports: Integrations.IOPort[]) => void;
};

export const IOPortTable = ({ ports, onChange }: IOPortTableProps) => {
  const onPortChange = (port: Integrations.IOPort, i: number) =>
    onChange([...ports.slice(0, i), port, ...ports.slice(i + 1)]);

  const onPortDelete = (i: number) =>
    onChange([...ports.slice(0, i), ...ports.slice(i + 1)]);

  return (
    <table className={styles.portTable}>
      <thead>
        <tr>
          <th className={styles.portTableHeader}>
            <Typography>Port</Typography>
          </th>
          <th
            className={cx(
              padding.left.small,
              margin.bottom.extraSmall,
              styles.portTableHeader,
            )}
          >
            <Typography>Name</Typography>
          </th>
          <th className={styles.portTableHeader} style={{ paddingLeft: 17 }}>
            <Typography>Values</Typography>
          </th>
          {/* the linter seems very confused by the self-closing th tag */}
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <th className={styles.portTableHeader} />
        </tr>
      </thead>
      <tbody>
        {ports.map((port, i) => {
          return (
            <IOPortRow
              key={port.port}
              port={port}
              onChange={(updatedPort) => onPortChange(updatedPort, i)}
              onDelete={() => onPortDelete(i)}
            />
          );
        })}
      </tbody>
    </table>
  );
};

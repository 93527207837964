import ms from 'ms';
import { createContext, useContext } from 'react';

import type { Notification } from '@sb/types';

export const NOTIFICATION_LIST_MAX = 10;

export type NotificationSystemColorVariant = 'dark' | 'light';

export const WAIT_FOR_CONFIRMATION_MESSAGE_DEFAULT =
  'Please confirm to resume the routine.';

export const getWaitForConfirmationTitle = (robotName: string): string => {
  return `${robotName} is Paused`;
};

export const getTimeDifferenceFromNow = (createdAt: Date) => {
  const timeDiff = Date.now() - createdAt.valueOf();

  ms(timeDiff, { long: true });

  return ms(timeDiff, { long: true });
};

interface NotificationSystemContextState {
  isDropdownOpen: boolean;
  closeDropdown: () => void;
  openDropdown: (cb?: () => void) => void;
  nonArchivedNotifications: Array<Notification.ConvertedResponse>;
  maxNonArchivedNotifications: Array<Notification.ConvertedResponse>;
  archivedNotifications: Array<Notification.ConvertedResponse>;
}

export const NotificationSystemContext =
  createContext<NotificationSystemContextState>({
    isDropdownOpen: false,
    closeDropdown: () => {},
    openDropdown: () => {},
    nonArchivedNotifications: [],
    maxNonArchivedNotifications: [],
    archivedNotifications: [],
  });

export const useNotificationSystemContext = () => {
  return useContext(NotificationSystemContext);
};

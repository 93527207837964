import type { Integrations, RoutineVariable } from '@sb/types';
import { ThreeFingerGripperIcon } from '@sb/ui/icons';

import { getGripperImplementationDefaults } from '../frontend/getGripperImplementationDefaults';

import {
  OR_3FG15_DEFAULT_FINGER_LENGTH,
  OR_3FG15_FINGERTIP_DIAMETER_DEFAULT,
  OR_3FG15_FINGER_POSITION_DEFAULT,
  OR3FG15_DEFAULT_TOOLTIP_POSE,
} from './constants';
import { EquipmentManagerEditForm } from './editor/EquipmentManagerEditForm';
import type { OnRobot3FG15GrippersConfiguration } from './types/Configuration';
import { OnRobot3FG15Widget } from './widget/OnRobot3FG15Widget';

export const INTEGRATION_VARIABLES: RoutineVariable.GripperStateVariableInformation[] =
  [
    {
      kind: 'gripperState',
      selector: 'gripperState.diameterMeters',
      variableKind: 'number',
      displayName: 'Diameter',
      variableName: 'kinematicState.gripperState.diameterMeters',
      userIdentifier: 'Gripper_diameterMeters',
      unitLabel: 'M',
    },
    {
      kind: 'gripperState',
      selector: 'gripperState.forceAppliedNewtons',
      variableKind: 'number',
      displayName: 'Gripped with force',
      variableName: 'kinematicState.gripperState.forceAppliedNewtons',
      userIdentifier: 'Gripper_forceAppliedNewtons',
      unitLabel: 'N',
    },
    {
      kind: 'gripperState',
      selector: 'gripperState.isGripDetected',
      variableKind: 'boolean',
      displayName: 'Gripped',
      values: [{ displayName: 'Gripped', value: true }],
      variableName: 'kinematicState.gripperState.isGripDetected',
      userIdentifier: 'Gripper_isGripDetected',
    },
    {
      kind: 'gripperState',
      selector: 'gripperState.isForceGripDetected',
      variableKind: 'boolean',
      displayName: 'Gripped with force specified in previous step',
      values: [{ displayName: 'Gripped', value: true }],
      variableName: 'kinematicState.gripperState.isForceGripDetected',
      userIdentifier: 'Gripper_isForceGripDetected',
    },
  ];

export const onRobot3FG15Implementation: Integrations.IntegrationFrontend<OnRobot3FG15GrippersConfiguration> =
  {
    ...getGripperImplementationDefaults(),
    InlineIcon: ThreeFingerGripperIcon,
    getSupportedSteps: () => ['ActuateGripper'],
    getDisplayName: () => {
      return 'OnRobot 3FG15';
    },
    getManufacturerName: () => {
      return 'OnRobot';
    },
    getShortName: () => {
      return '3FG15';
    },
    getIntegrationVariables: () => INTEGRATION_VARIABLES,
    getDefaultItem: (): OnRobot3FG15GrippersConfiguration => {
      return {
        kind: 'OnRobot3FG15',
        fingerConfiguration: {
          mountingPosition: OR_3FG15_FINGER_POSITION_DEFAULT,
          lengthInMeters: OR_3FG15_DEFAULT_FINGER_LENGTH, // 49mm
          offsetInMeters: OR_3FG15_FINGERTIP_DIAMETER_DEFAULT, // 13.5mm
        },
        tooltipConfig: {
          tooltipPose: OR3FG15_DEFAULT_TOOLTIP_POSE,
        },
      };
    },
    Widget: OnRobot3FG15Widget,
    EquipmentManagerEditForm,
  };

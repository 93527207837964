import {
  PAYLOAD_MASS_KG_ABSOLUTE_MAX,
  PAYLOAD_MASS_KG_ABSOLUTE_MIN,
  PAYLOAD_MASS_KG_DEFAULT,
} from '@sb/routine-runner';
import { RangeSpinner, useDualValueRangeSpinner } from '@sb/ui/components';
import { massToString } from '@sb/utilities';
import SectionContainer from '@sbrc/components/visualizer-view-shared/widget-panel/SectionContainer';
import { useRobotPayloadState } from '@sbrc/hooks';
import { PAYLOAD_KG_SLIDER_STEP } from '@sbrc/utils';

import type { DualValueModeProps } from './types';

export default function GripperPayload({
  gripperControlState,
  routineRunnerArgs,
}: DualValueModeProps) {
  const routineRunnerPayload = useRobotPayloadState(routineRunnerArgs);
  const currentPayload = routineRunnerPayload ?? PAYLOAD_MASS_KG_DEFAULT;

  const { setTargetPayload, targetPayload } = gripperControlState;

  const spinnerProps = useDualValueRangeSpinner({
    primaryValue: currentPayload,
    secondaryValue: targetPayload,
    max: PAYLOAD_MASS_KG_ABSOLUTE_MAX,
    min: PAYLOAD_MASS_KG_ABSOLUTE_MIN,
    step: PAYLOAD_KG_SLIDER_STEP,
    onChange: setTargetPayload,
  });

  return (
    <SectionContainer hasMeter title="Payload">
      <RangeSpinner {...spinnerProps} valueToString={massToString} />
    </SectionContainer>
  );
}

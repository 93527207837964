import type { Robot } from '@sb/types';
import { Badge, Typography } from '@sb/ui/components';
import { NoWiFiIcon, RobotIcon } from '@sb/ui/icons';
import type { RobotBadgeStatus } from '@sbrc/hooks';

import { SpeedBadge } from './SpeedBadge';

import styles from './RobotBadgeContent.module.css';

interface RobotBadgeContentProps {
  robot: Robot.ConvertedResponse;
  robotBadgeStatus: RobotBadgeStatus;
  isSoftwareUpdateAvailable?: boolean;
  isInstalling?: boolean;
}

export default function RobotBadgeContent({
  robot,
  robotBadgeStatus,
  isSoftwareUpdateAvailable,
  isInstalling,
}: RobotBadgeContentProps) {
  return (
    <>
      {isSoftwareUpdateAvailable ? (
        <div className={styles.softwareUpdateIndicator} />
      ) : (
        <div className={styles.iconContainer}>
          <RobotIcon fontSize="20px" />
          <Badge
            variant={robotBadgeStatus.ledColor}
            className={styles.statusLight}
          />
        </div>
      )}

      <Typography className={styles.text} hasNoWrap isBold>
        {robot.name}
      </Typography>

      {isInstalling && <Badge variant="blue">Installing</Badge>}

      {!isInstalling && robotBadgeStatus.kind !== 'idle' && (
        <Badge
          data-testid="robot-status-text"
          variant={robotBadgeStatus.color}
          startIcon={
            robotBadgeStatus.kind === 'disconnected' ? (
              <NoWiFiIcon />
            ) : undefined
          }
        >
          {robotBadgeStatus.label}
        </Badge>
      )}

      <SpeedBadge
        hidden={
          isInstalling ||
          robotBadgeStatus.kind === 'disconnected' ||
          robotBadgeStatus.kind === 'loading' ||
          robotBadgeStatus.kind === 'estop'
        }
        speedRestrictionPercentage={robot.speedRestrictionPercentage}
        isSlowSpeed={robotBadgeStatus.safeguardState === 'slowSpeed'}
      />
    </>
  );
}

import { TabItem, Tabs } from '@sb/ui/components';
import { padding } from '@sb/ui/styles';

import { CreateBackupTab } from './CreateBackupTab';
import { RestoreBackupTab } from './RestoreBackupTab';
import { useUSBDrive } from './useUSBDrive';

export const DataBackupModalBody = ({ robotID }: { robotID: string }) => {
  const usbData = useUSBDrive();

  return (
    <Tabs tabPanelContainerClassName={padding.medium}>
      <TabItem
        id="create"
        label="Create Backup"
        panel={<CreateBackupTab robotID={robotID} usbData={usbData} />}
      />
      <TabItem
        id="restore"
        label="Restore Backup"
        panel={<RestoreBackupTab usbData={usbData} />}
      />
    </Tabs>
  );
};

import cx from 'classnames';
import { useMemo } from 'react';

import { Dropdown } from '@sb/ui/components';
import { AlertIcon } from '@sb/ui/icons';
import { useAuthentication, useToast } from '@sbrc/hooks';
import { updateNotificationStatus } from '@sbrc/services';

import NotificationDropdownContent from './NotificationDropdownContent';
import type { NotificationSystemColorVariant } from './shared';
import { useNotificationSystemContext } from './shared';

import styles from './NotificationDropdown.module.css';

interface NotificationDropdownProps {
  className?: string;
  variant?: NotificationSystemColorVariant;
}

export default function NotificationDropdown({
  className,
  variant = 'dark',
}: NotificationDropdownProps) {
  const {
    closeDropdown,
    isDropdownOpen,
    openDropdown,
    maxNonArchivedNotifications,
  } = useNotificationSystemContext();

  const { userID } = useAuthentication();
  const { setToast } = useToast();

  const unseenNotifications = useMemo(() => {
    return maxNonArchivedNotifications.filter((notification) => {
      return notification.status !== 'viewed';
    });
  }, [maxNonArchivedNotifications]);

  const onViewAlertItems = async () => {
    if (!userID) return;

    const notificationIDs = unseenNotifications.map(
      (notification) => notification.id,
    );

    try {
      await updateNotificationStatus(notificationIDs, userID, 'viewed');
    } catch (error) {
      setToast({ kind: 'error', message: error.message });
    }
  };

  return (
    <Dropdown
      isOpen={isDropdownOpen}
      onClose={closeDropdown}
      content={<NotificationDropdownContent />}
    >
      <button
        aria-label="Notifications Menu"
        className={cx(styles.notificationsCircle, styles[variant], className)}
        onClick={
          isDropdownOpen ? closeDropdown : () => openDropdown(onViewAlertItems)
        }
        data-testid="notifications-button"
      >
        <div
          hidden={
            !unseenNotifications.length || !maxNonArchivedNotifications.length
          }
          className={styles.dot}
          role="status"
          aria-label="Notifications"
        />

        <AlertIcon className={styles.alertIcon} />
      </button>
    </Dropdown>
  );
}

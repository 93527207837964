import { Typography } from '@sb/ui/components';
import { margin } from '@sb/ui/styles';
import { useRobotState } from '@sbrc/hooks';

import { useSafetySettingsStore } from '../../store';

import { SafeguardRuleSettings } from './SafeguardRuleSettings';

import styles from './IOTab.module.css';

export function IOTab() {
  const { robotID, safeguardRules } = useSafetySettingsStore(
    'robotID',
    'safeguardRules',
  );

  const robotSupportsSafeguardRules = useRobotState(
    { robotID },
    (state) => state?.kinematicState.supportsSafeguardRules ?? false,
  );

  if (!robotSupportsSafeguardRules) {
    return (
      <>
        The version of the robot that you have is not compatible with this
        feature. If you need to use this feature, please contact support to
        update your hardware.
      </>
    );
  }

  return (
    <>
      <Typography variant="medium" className={margin.bottom.large}>
        External safety devices like area scanners, E-stop buttons, and key
        switches can be connected to the 24V digital inputs on the control box
        and assigned to safety functions here. Any inputs unassigned here can be
        freely used for other devices in the cell. A safety assessment can
        determine which are needed for your application.
      </Typography>

      <div className={styles.grid}>
        <Typography className={styles.headerCell} variant="medium" isBold>
          Digital Inputs
        </Typography>
        <Typography className={styles.headerCell} variant="small">
          Current Value
        </Typography>
        <Typography className={styles.headerCell} variant="small">
          Safety Function Assignment
        </Typography>
        <div className={styles.headerCell}>&nbsp;</div>

        {safeguardRules?.map((rule, index) => (
          <SafeguardRuleSettings
            key={rule.pair}
            pair={rule.pair}
            fieldName={`safeguardRules[${index}]`}
          />
        ))}
      </div>
    </>
  );
}

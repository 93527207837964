import { RangeSpinner, useDualValueRangeSpinner } from '@sb/ui/components';
import { convertMetersToMillimeterString } from '@sb/utilities';
import SectionContainer from '@sbrc/components/visualizer-view-shared/widget-panel/SectionContainer';

import { OR_3FG15_DIAMETER_METERS_SLIDER_STEP } from '../..';

import type { DualValueModeProps } from './types';

export default function GripperDiameter({
  gripperControlState,
}: DualValueModeProps) {
  const {
    changeTargetDiameter,
    diameterRange,
    routineRunnerDiameter,
    targetDiameterMeters,
  } = gripperControlState;

  const spinnerProps = useDualValueRangeSpinner({
    primaryValue: routineRunnerDiameter,
    secondaryValue: targetDiameterMeters,
    step: OR_3FG15_DIAMETER_METERS_SLIDER_STEP,
    ...diameterRange,
    onChange: changeTargetDiameter,
  });

  return (
    <SectionContainer
      hasMeter
      title="Diameter"
      extraTitle="15 second actuation"
    >
      <RangeSpinner
        {...spinnerProps}
        valueToString={convertMetersToMillimeterString}
      />
    </SectionContainer>
  );
}

import type { NumberInputProps } from '@sb/ui/components';
import { NumberInput } from '@sb/ui/components';
import { useDistanceUnitInfo } from '@sbrc/hooks';

import { convertMaxMinStepProps } from './convertMaxMinStepProps';

/**
 * Component for entering linear velocity,
 * will convert value from m/s to the user's preference for display/input
 */
export function VelocityNumberInput({
  onChange,
  value,
  placeholderValue,
  ...rest
}: NumberInputProps) {
  const distanceUnitInfo = useDistanceUnitInfo();

  const maxMinStepProps = convertMaxMinStepProps(rest, {
    convertValue: distanceUnitInfo.fromMeters,
  });

  return (
    <NumberInput
      {...rest}
      {...maxMinStepProps}
      decimalPlaces={distanceUnitInfo.decimalPlaces}
      onChange={(newValue) => onChange(distanceUnitInfo.toMeters(newValue))}
      suffix={`${distanceUnitInfo.abbreviation}/s`}
      value={distanceUnitInfo.fromMeters(value)}
      placeholderValue={distanceUnitInfo.fromMeters(placeholderValue)}
    />
  );
}

import type { Integrations } from '@sb/types';
import { DualToolChangerIcon } from '@sb/ui/icons';

import { getGripperImplementationDefaults } from '../frontend/getGripperImplementationDefaults';
import { noGripperImplementation } from '../NoGripper/frontend';

// eslint-disable-next-line import/no-cycle
import { EquipmentManagerEditForm } from './editor/EquipmentManagerEditForm';
import { getDualGripperVariables } from './getDualGripperVariables';
import type { DualGripperConfiguration } from './types/Configuration';
import { OnRobotDualQuickChangerWidget } from './widget/OnRobotDualQuickChangerWidget';

export const onRobotDualQuickChangerImplementation: Integrations.IntegrationFrontend<DualGripperConfiguration> =
  {
    ...getGripperImplementationDefaults(),
    InlineIcon: DualToolChangerIcon,
    getSupportedSteps: () => ['ActuateGripper'],
    getDisplayName: () => {
      return 'OnRobot Dual Quick Changer';
    },
    getManufacturerName: () => {
      return 'OnRobot';
    },
    getShortName: () => {
      return 'Dual Quick Changer';
    },
    getIntegrationVariables: (
      config: Integrations.IntegrationVariablesProps,
    ) => {
      if (config.item.kind !== 'OnRobotDualQuickChanger') return [];

      return getDualGripperVariables(
        config.item.grippers.primary.kind,
        config.item.grippers.secondary.kind,
      );
    },
    getDefaultItem: (): DualGripperConfiguration => {
      return {
        kind: 'OnRobotDualQuickChanger',
        grippers: {
          primary: noGripperImplementation.getDefaultItem(),
          secondary: noGripperImplementation.getDefaultItem(),
        },
      };
    },
    Widget: OnRobotDualQuickChangerWidget,
    EquipmentManagerEditForm,
  };

import cx from 'classnames';
import NextLink from 'next/link';

import { Typography } from '@sb/ui/components';
import { RobotIcon, RoutineIcon } from '@sb/ui/icons';
import { LINK_TO_ROUTINE_LIST_PAGE, getLinkToRobotMovePage } from '@sbrc/utils';

import styles from './AppHeaderNav.module.css';

export type Page = 'moveRobot' | 'routines' | 'robotList';

interface AppHeaderNavProps {
  robotID: string;
  activePage?: Page;
}

export default function AppHeaderNav({
  robotID,
  activePage,
}: AppHeaderNavProps) {
  const listItems = [
    {
      key: 'move-robot',
      page: 'moveRobot',
      title: 'Move Robot',
      link: getLinkToRobotMovePage(robotID),
      icon: <RobotIcon className={styles.icon} />,
    },
    {
      key: 'routines',
      page: 'routines',
      title: 'Routines',
      link: LINK_TO_ROUTINE_LIST_PAGE,
      icon: <RoutineIcon className={styles.icon} />,
    },
  ];

  return (
    <nav>
      <ul className={styles.list}>
        {listItems.map((item) => (
          <li key={item.key} className={styles.listItem}>
            <NextLink
              href={item.link}
              className={cx(
                styles.link,
                activePage === item.page && styles.active,
              )}
              data-testid={`app-header-nav-${item.key}-link`}
            >
              {item.icon}
              <Typography className={styles.title}>{item.title}</Typography>
            </NextLink>
          </li>
        ))}
      </ul>
    </nav>
  );
}

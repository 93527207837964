import {
  COMMAND_TORQUE_NM_DEFAULT,
  torqueStringConvert,
  TORQUE_RANGE,
} from '@sb/integrations/OnRobotScrewdriver';
import {
  RangeSpinner,
  Typography,
  useSingleValueRangeSpinner,
  Layout,
} from '@sb/ui/components';
import { useIsRobotMoving, useRobotGripperState } from '@sbrc/hooks';

import type { DualValueModeProps } from './types';

export default function GripperTargetTorque({
  gripperControlState,
  routineRunnerArgs,
}: DualValueModeProps) {
  const { changeTargetTorque, target } = gripperControlState;

  const { isRobotMoving } = useIsRobotMoving(routineRunnerArgs);

  const routineRunnerGripperState = useRobotGripperState(
    routineRunnerArgs,
    'OnRobotScrewdriver',
  );

  const achievedTorqueString =
    routineRunnerGripperState?.achievedTorque.toFixed(3);

  const spinnerProps = useSingleValueRangeSpinner({
    value: target.targetTorque ?? COMMAND_TORQUE_NM_DEFAULT,
    min: TORQUE_RANGE.min,
    max: TORQUE_RANGE.max,
    step: TORQUE_RANGE.step,
    onChange: changeTargetTorque,
  });

  return (
    <>
      <Layout.Flex direction="horizontal">
        <Layout.FlexChild grow={0} shrink={0}>
          <Typography>Target Torque</Typography>
        </Layout.FlexChild>
        <Layout.FlexChild grow={1} shrink={1} />
        <Layout.FlexChild grow={0} shrink={0}>
          <Typography>
            {isRobotMoving
              ? undefined
              : ` Achieved Torque: ${achievedTorqueString}`}
          </Typography>
        </Layout.FlexChild>
      </Layout.Flex>
      <RangeSpinner {...spinnerProps} valueToString={torqueStringConvert} />
    </>
  );
}

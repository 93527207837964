import cx from 'classnames';
import { useState } from 'react';

import type { Notification } from '@sb/types';
import { MenuItem, Modal, Typography, NoItems } from '@sb/ui/components';
import { TickIcon } from '@sb/ui/icons';
import { margin } from '@sb/ui/styles';
import { useAuthentication, useToast } from '@sbrc/hooks';
import { updateNotificationStatus } from '@sbrc/services/feathers-client/notifications/updateNotificationStatus';

import {
  useNotificationSystemContext,
  getTimeDifferenceFromNow,
} from '../shared';
import { getNotificationMessage } from '../utils';

import DetailedAlertsPanel from './DetailedAlertsPanel';

import styles from './DetailedAlertsModal.module.css';

interface DetailAlertsModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedNotification?: Notification.ConvertedResponse;
  isArchivedList?: boolean;
}

export default function DetailAlertsModal({
  isOpen,
  onClose,
  selectedNotification,
  isArchivedList,
}: DetailAlertsModalProps) {
  const {
    maxNonArchivedNotifications,
    nonArchivedNotifications,
    archivedNotifications,
  } = useNotificationSystemContext();

  const { userID } = useAuthentication();

  const notificationsList = isArchivedList
    ? archivedNotifications
    : maxNonArchivedNotifications;

  const { setToast } = useToast();

  const [selectedItem, setSelectedItem] = useState<
    Notification.ConvertedResponse | undefined
  >(selectedNotification ?? notificationsList[0]);

  const onArchiveAlertItems = async () => {
    if (!userID) return;

    const notificationIDs = nonArchivedNotifications.map(
      (notification) => notification.id,
    );

    try {
      await updateNotificationStatus(notificationIDs, userID, 'archived');

      onClose();

      setToast({ kind: 'success', message: 'Notifications archived!' });
    } catch (error) {
      setToast({ kind: 'error', message: error.message });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      isScrollable
      onClose={onClose}
      className={styles.detailedAlertsModal}
    >
      {notificationsList.length > 0 ? (
        <div className={styles.modal}>
          <div className={cx(styles.modalMenu, margin.top.extraSmall)}>
            <div>
              <Typography
                component="h6"
                color="gray"
                className={cx(margin.top.medium, margin.left.small)}
              >
                {String(notificationsList.length)}{' '}
                {isArchivedList && 'Archived '}
                {notificationsList.length > 1 ? 'Alerts' : 'Alert'}
              </Typography>

              <div className={styles.notificationItems}>
                {notificationsList.map((notification) => {
                  const isSelected = selectedItem?.id === notification.id;

                  const secondaryMessage = (() => {
                    switch (notification.kind) {
                      case 'robotFailure':
                        return notification.failureReason;
                      case 'waitForConfirmation':
                        return 'Waiting for confirmation';
                      default:
                        // ensure type exhaustive
                        const exhaustive: never = notification; // eslint-disable-line no-case-declarations
                        throw new Error(
                          `Unexpected kind for notification ${exhaustive}`,
                        );
                    }
                  })();

                  return (
                    <MenuItem
                      key={notification.id}
                      role="button"
                      endIcon={<TickIcon className={styles.tick} />}
                      onClick={() => setSelectedItem(notification)}
                      className={cx(
                        isSelected && styles.selected,
                        styles.menuAlertItem,
                      )}
                      contentClassName={cx(isSelected && styles.selectedColor)}
                    >
                      <div>
                        <Typography
                          isBold
                          variant="medium"
                          className={styles.menuRobotText}
                        >
                          {`Robot ${getNotificationMessage(notification)}`}
                        </Typography>
                        <div className={styles.notificationMetadataContainer}>
                          <Typography
                            className={styles.notificationMetadataTimestamp}
                            variant="small"
                          >
                            {getTimeDifferenceFromNow(notification.createdAt)}{' '}
                            ago
                          </Typography>
                          <Typography
                            className={margin.left.extraSmall}
                            variant="small"
                            color="gray"
                          >
                            {secondaryMessage}
                          </Typography>
                        </div>
                      </div>
                    </MenuItem>
                  );
                })}
              </div>
            </div>

            {!isArchivedList && (
              <Typography
                className={styles.archiveButton}
                role="button"
                color="primary"
                onClick={onArchiveAlertItems}
              >
                Archive alert items
              </Typography>
            )}
          </div>

          {selectedItem && <DetailedAlertsPanel selectedItem={selectedItem} />}
        </div>
      ) : (
        <NoItems className={styles.noItems}>
          <Typography variant="medium">
            All alerts items have been cleared. <br />
            To view archived alerts, click the <strong>Notifications </strong>
            button in the header menu.
          </Typography>
        </NoItems>
      )}
    </Modal>
  );
}

import type { PopoverPlacement } from '@sb/ui/components';
import { IconButton, Popover, Typography } from '@sb/ui/components';
import { QuestionIcon } from '@sb/ui/icons';

import styles from './HelpPopover.module.css';

interface HelpPopoverProps {
  className?: string;
  message: React.ReactNode;
  placement?: PopoverPlacement;
}

const HelpPopover = ({ className, message, placement }: HelpPopoverProps) => {
  return (
    <Popover
      className={className}
      placement={placement}
      content={<Typography>{message}</Typography>}
      offset={2}
    >
      <IconButton>
        <QuestionIcon className={styles.questionIcon} />
      </IconButton>
    </Popover>
  );
};

export default HelpPopover;

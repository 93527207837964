import { calculateSpeedProfileLimitValue } from '@sb/routine-runner';
import type { NumberInputProps } from '@sb/ui/components';
import { Badge, NumberInput } from '@sb/ui/components';
import { SpeedIcon } from '@sb/ui/icons';
import { useFeatureFlag } from '@sbrc/hooks';

import { useSettingValue } from '../../store';

import styles from './LimitField.module.css';

interface SettingsFieldProps {
  fieldName: string;
  slowFieldName: string;
  suffix?: string;
  defaultValue: number;
  minValue: number;
  maxValue: number;
  collaborativeValue: number;
  step: number;
  isSpeedLimitField?: boolean;
  NumberInputComponent?: React.ComponentType<NumberInputProps>;
}

export function LimitField({
  fieldName,
  slowFieldName,
  suffix,
  defaultValue,
  minValue,
  maxValue,
  collaborativeValue,
  step,
  isSpeedLimitField = false,
  NumberInputComponent = NumberInput,
}: SettingsFieldProps) {
  const isSafetyIOEnabled = useFeatureFlag('safetyIO');

  const [isFactoryPreset] = useSettingValue(
    'factoryPreset',
    (v) => v !== 'none',
  );

  const [fullValue, setFullValue, onFullValidationChange, isFullDisabled] =
    useSettingValue(fieldName, (v) => (typeof v === 'number' ? v : null));

  const [slowValue, setSlowValue, onSlowValidationChange, isSlowDisabled] =
    useSettingValue(slowFieldName, (v) => (typeof v === 'number' ? v : null));

  const defaultSlowValue = calculateSpeedProfileLimitValue({
    isSlowMode: true,
    isSpeedLimit: isSpeedLimitField,
    defaultValue,
    collaborativeValue,
    fullValue,
    slowValue: null,
  });

  const slowPercent = (() => {
    const effectiveValue = fullValue ?? defaultValue ?? NaN;
    const effectiveSlowValue = slowValue ?? defaultSlowValue ?? NaN;

    if (effectiveValue < 0 || effectiveSlowValue < 0) {
      return NaN;
    }

    return Math.floor((100 * effectiveSlowValue) / effectiveValue);
  })();

  return (
    <>
      <NumberInputComponent
        className={styles.field}
        disabled={isFactoryPreset || isFullDisabled}
        suffix={suffix}
        value={fullValue ?? NaN}
        onChange={setFullValue}
        onClear={() => {
          setFullValue(null);
          setSlowValue(null);
        }}
        step={step}
        max={maxValue}
        min={minValue}
        onValidationChange={onFullValidationChange}
        placeholderValue={defaultValue}
      />

      {isSafetyIOEnabled ? (
        <NumberInputComponent
          className={styles.field}
          disabled={isFactoryPreset || isSlowDisabled || fullValue === null}
          suffix={suffix}
          value={slowValue ?? NaN}
          onChange={setSlowValue}
          onClear={() => setSlowValue(null)}
          step={step}
          max={fullValue ?? maxValue}
          min={minValue}
          onValidationChange={onSlowValidationChange}
          placeholderValue={defaultSlowValue}
        />
      ) : (
        <div className={styles.field} />
      )}

      <div className={styles.slowBadgeContainer}>
        {isSafetyIOEnabled && slowPercent >= 0 && slowPercent <= 100 && (
          <Badge
            variant={slowPercent === 100 ? 'black' : 'grayLight'}
            startIcon={<SpeedIcon />}
          >
            {`${slowPercent}%`}
          </Badge>
        )}
      </div>
    </>
  );
}

import {
  PAYLOAD_MASS_KG_ABSOLUTE_MAX,
  PAYLOAD_MASS_KG_ABSOLUTE_MIN,
  PAYLOAD_MASS_KG_DEFAULT,
} from '@sb/routine-runner';
import { RangeSpinner, useSingleValueRangeSpinner } from '@sb/ui/components';
import { massToString } from '@sb/utilities';
import SectionContainer from '@sbrc/components/visualizer-view-shared/widget-panel/SectionContainer';
import {
  useIsAnotherSessionRunningAdHocCommand,
  useRobotPayloadState,
  useRobotRoutineRunningState,
  useRoutineRunnerHandle,
} from '@sbrc/hooks';
import { PAYLOAD_KG_SLIDER_STEP } from '@sbrc/utils';

import type { SingleValueModeProps } from './types';

export default function GripperPayload({
  routineRunnerArgs,
}: SingleValueModeProps) {
  const routineRunnerPayload =
    useRobotPayloadState(routineRunnerArgs) ?? PAYLOAD_MASS_KG_DEFAULT;

  const routineRunnerHandle = useRoutineRunnerHandle(routineRunnerArgs);

  const isAnotherSessionMovingRobot =
    useIsAnotherSessionRunningAdHocCommand(routineRunnerArgs);

  const routineRunningState = useRobotRoutineRunningState(routineRunnerArgs);

  const isRoutineRunning = routineRunningState !== null;

  let targetPayload = routineRunnerPayload;

  const spinnerProps = useSingleValueRangeSpinner({
    value: routineRunnerPayload,
    min: PAYLOAD_MASS_KG_ABSOLUTE_MIN,
    max: PAYLOAD_MASS_KG_ABSOLUTE_MAX,
    step: PAYLOAD_KG_SLIDER_STEP,
    onChange: (action) => {
      const newValue =
        typeof action === 'function' ? action(targetPayload) : action;

      if (newValue !== targetPayload) {
        targetPayload = newValue;

        routineRunnerHandle.setRobotPayload({
          mass: newValue,
        });
      }
    },
  });

  return (
    <SectionContainer title="Payload">
      <RangeSpinner
        {...spinnerProps}
        valueToString={massToString}
        isDisabled={isAnotherSessionMovingRobot || isRoutineRunning}
      />
    </SectionContainer>
  );
}

import dynamic from 'next/dynamic';

import type { Notification } from '@sb/types';

const DetailedAlertsNotificationItem = dynamic(() => {
  return import('./detailed-alerts/DetailedAlertsNotificationItem');
});

interface NotificationItemProps {
  notification: Notification.ConvertedResponse;
  listNumber: string;
}

const NotificationItem = ({
  notification,
  listNumber,
}: NotificationItemProps) => {
  return (
    <DetailedAlertsNotificationItem
      notification={notification}
      listNumber={listNumber}
    />
  );
};

export default NotificationItem;

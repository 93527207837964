import cx from 'classnames';
import NextLink from 'next/link';

import type { Notification } from '@sb/types';
import { Link, ModalHeader, Typography } from '@sb/ui/components';
import { display, justifyContent, margin } from '@sb/ui/styles';
import { getLinkToRoutinePage } from '@sbrc/utils';

import {
  getNotificationMessage,
  getNotificationReason,
  getNotificationAction,
} from '../utils';

import styles from './DetailedAlertsPanel.module.css';

interface DetailedAlertsPanelProps {
  selectedItem: Notification.ConvertedResponse;
}

export default function DetailedAlertsPanel({
  selectedItem,
}: DetailedAlertsPanelProps) {
  return (
    <div className={styles.notificationPanel}>
      <ModalHeader
        className={margin.top.extraSmall}
        titleComponent="h3"
        title={`Robot ${getNotificationMessage(selectedItem)}`}
      />

      <div className={styles.notificationContent}>
        <div className={styles.contentSection}>
          <div className={cx(display.flex.row, justifyContent.spaceBetween)}>
            <Typography variant="large" isBold>
              What Happened
            </Typography>

            <Typography color="gray">
              {selectedItem.createdAt.toString()}
            </Typography>
          </div>

          <Typography variant="medium" className={styles.contentText}>
            {getNotificationReason(selectedItem)}
          </Typography>
        </div>

        <div className={styles.contentSection}>
          <Typography variant="large" isBold>
            What You Can Do
          </Typography>
          <Typography variant="medium" className={styles.contentText}>
            {getNotificationAction(selectedItem)}
          </Typography>
        </div>

        <div className={styles.contentSection}>
          <Typography isBold variant="large">
            Trace ID
          </Typography>
          <Typography variant="medium" className={styles.contentText}>
            {selectedItem.failureTraceID}
          </Typography>
        </div>

        {selectedItem.robotID && selectedItem.routineID && (
          <NextLink
            href={`${getLinkToRoutinePage(
              String(selectedItem.routineID),
            )}?robotID=${selectedItem.robotID}`}
            passHref
            legacyBehavior
          >
            <Link className={styles.routineEditorLink}>
              Go to Routine Editor
            </Link>
          </NextLink>
        )}
      </div>
    </div>
  );
}

import { useEffect } from 'react';

import type { Integrations } from '@sb/types';
import { Button, Loader } from '@sb/ui/components';
import { DownloadIcon, ResetAllIcon } from '@sb/ui/icons';
import {
  sharedStyles,
  WidgetView,
} from '@sbrc/components/visualizer-view-shared/widget-panel';

import type { OnRobot2FG7State } from '../State';
import { areOR2FG7GripperPositionsEqual, getOR2FG7ActiveWidth } from '../util';

import { GripperControlDualValueMode } from './dual-value-mode/GripperControlDualValueMode';
import { useGripperControlState } from './gripper-control-state';

interface OnRobot2FG7WidgetInnerProps {
  routineRunnerPayload: number;
  routineRunnerGripperState: OnRobot2FG7State;
  otherRobotGripperState: OnRobot2FG7State | null;
  args: Integrations.WidgetProps;
}

export function OnRobot2FG7WidgetInner({
  routineRunnerPayload,
  routineRunnerGripperState,
  otherRobotGripperState,
  args,
}: OnRobot2FG7WidgetInnerProps) {
  const {
    robot,
    isVizbot,
    onCommandChange = () => {},
    onClose,
    implementation: onRobot2FG7Implementation,
  } = args;

  const routineRunnerArgs = {
    robotID: robot.id,
    isVizbot,
  };

  const gripperControlState = useGripperControlState({
    ...routineRunnerArgs,
    routineRunnerGripperState,
    routineRunnerPayload,
  });

  const {
    canApplyGripperChanges,
    changeTargetDiameter,
    changeGripKind,
    conformGripperControlStateToActualState,
    targetDiameterMeters,
    command,
  } = gripperControlState;

  useEffect(() => {
    if (onCommandChange) {
      onCommandChange(command);
    }
  }, [command, onCommandChange]);

  const areGripperPositionsEqual = areOR2FG7GripperPositionsEqual(
    otherRobotGripperState,
    targetDiameterMeters,
    command,
  );

  const buttonText = isVizbot ? 'Live Robot' : 'Visualizer';

  return (
    <WidgetView
      testPrefix="onrobot-2fg7-control"
      onClose={onClose}
      headerTitle={onRobot2FG7Implementation.getDisplayName()}
      headerIcon={<onRobot2FG7Implementation.InlineIcon />}
      headerButton={
        <>
          {otherRobotGripperState?.kind === 'OnRobot2FG7' && (
            <Button
              className={sharedStyles.headerButtonText}
              startIcon={<DownloadIcon className={sharedStyles.buttonIcon} />}
              label="Import gripper state"
              size="extraSmall"
              variant="gray"
              onClick={() => {
                const diameter = getOR2FG7ActiveWidth(otherRobotGripperState);
                changeGripKind(otherRobotGripperState.gripKind ?? 'inward');
                changeTargetDiameter(diameter);
              }}
              disabled={areGripperPositionsEqual}
            >
              {buttonText}
            </Button>
          )}

          {routineRunnerGripperState?.kind === 'OnRobot2FG7' && (
            <Button
              startIcon={<ResetAllIcon className={sharedStyles.buttonIcon} />}
              label="Reset to current gripper state"
              size="extraSmall"
              variant="gray"
              onClick={() => {
                conformGripperControlStateToActualState(
                  routineRunnerGripperState,
                );
              }}
              disabled={!canApplyGripperChanges}
              data-testid="gripper-widget-reset-current-gripper-state-button"
            />
          )}
        </>
      }
    >
      {routineRunnerGripperState ? (
        <div className={sharedStyles.controls}>
          <GripperControlDualValueMode
            gripperControlState={gripperControlState}
            routineRunnerArgs={routineRunnerArgs}
          />
        </div>
      ) : (
        <div className={sharedStyles.loaderContainer}>
          <Loader />
        </div>
      )}
    </WidgetView>
  );
}

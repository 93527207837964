import type { Integrations } from '@sb/types';

import type { OnRobot2FG7Command } from './Command';
import {
  OR_2FG7_DIAMETER_METERS_SLIDER_STEP,
  OUTWARD_MOUNT_INWARD_GRIP_DIAMETER_RANGE,
  OUTWARD_MOUNT_OUTWARD_GRIP_DIAMETER_RANGE,
} from './constants';
import type { OnRobot2FG7State, GripKind } from './State';

export function getOR2FG7ActiveWidth(state: OnRobot2FG7State): number {
  return state.gripKind === 'outward' ? state.width.outer : state.width.inner;
}

export function getOR2FG7DiameterRange(
  gripKind: GripKind,
): Integrations.GripperRange {
  // TODO account for finger orientation
  if (gripKind === 'outward') {
    return OUTWARD_MOUNT_OUTWARD_GRIP_DIAMETER_RANGE;
  }

  return OUTWARD_MOUNT_INWARD_GRIP_DIAMETER_RANGE;
}

export function areOR2FG7GripperPositionsEqual(
  otherRobotGripperState: OnRobot2FG7State | null,
  targetDiameterMeters: number,
  command: OnRobot2FG7Command,
) {
  if (!otherRobotGripperState) return false;

  const isGripKindTargetSynced =
    otherRobotGripperState?.gripKind === command.gripKind;

  const otherDiameter = getOR2FG7ActiveWidth(otherRobotGripperState);

  const isDiameterTargetSynced =
    Math.abs(otherDiameter - targetDiameterMeters) <
    OR_2FG7_DIAMETER_METERS_SLIDER_STEP;

  return isDiameterTargetSynced && isGripKindTargetSynced;
}

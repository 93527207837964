import { Button } from '@sb/ui/components';
import SectionContainer from '@sbrc/components/visualizer-view-shared/widget-panel/SectionContainer';
import {
  useGuidedMode,
  useRoutineRunnerHandle,
  useToast,
  useIsAnotherSessionRunningAdHocCommand,
  useRobotRoutineRunningState,
} from '@sbrc/hooks';

import type { OnRobotVGP20Command } from '../types';

interface ApplyChangesProps {
  command: OnRobotVGP20Command | null;
  routineRunnerArgs: {
    robotID: string;
    isVizbot: boolean;
  };
  resetCommand: () => void;
}

export function ApplyChangesButton({
  command,
  routineRunnerArgs,
  resetCommand,
}: ApplyChangesProps) {
  const { setToast } = useToast();

  const routineRunnerHandle = useRoutineRunnerHandle(routineRunnerArgs);

  const { runAdHocCommand, stopGuidedMode } = useGuidedMode(routineRunnerArgs);

  const isAnotherSessionMovingRobot = useIsAnotherSessionRunningAdHocCommand({
    robotID: routineRunnerArgs.robotID,
  });

  const routineRunningState = useRobotRoutineRunningState(routineRunnerArgs);

  const isRoutineRunning = routineRunningState !== null;

  const onHoldApplyChangesButton = () => {
    if (!command) {
      return;
    }

    runAdHocCommand({
      onRunCommand: () => {
        return routineRunnerHandle.actuateDevice({ command });
      },

      onSuccess: () => {
        resetCommand();
      },

      onError: (error) => {
        setToast({
          kind: 'error',
          message: `Error controlling vacuum: ${error}`,
        });
      },
    });
  };

  return (
    <SectionContainer>
      <Button
        isFullWidth
        onHold={onHoldApplyChangesButton}
        onRelease={stopGuidedMode}
        disabled={isAnotherSessionMovingRobot || isRoutineRunning || !command}
        data-testid="gripper-widget-apply-changes-button"
      >
        Hold to Apply Changes
      </Button>
    </SectionContainer>
  );
}

import { useState } from 'react';

import { Dropdown, IconButton } from '@sb/ui/components';
import { ReorderIcon } from '@sb/ui/icons';

import AccountMenu from './AccountMenu';

import styles from './HeaderHamburgerMenu.module.css';

const HeaderHamburgerMenu = () => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const closeMenu = () => setOpen(false);

  return (
    <div className={styles.headerMenuContainer}>
      <Dropdown content={<AccountMenu />} isOpen={isOpen} onClose={closeMenu}>
        <IconButton
          onClick={() => setOpen(!isOpen)}
          className={styles.menuCircle}
          data-testid="user-header-button"
        >
          <ReorderIcon size="extraSmall" variant="black" />
        </IconButton>
      </Dropdown>
    </div>
  );
};

export default HeaderHamburgerMenu;

import type { Integrations } from '@sb/types';

export const getDynamicBaseDefaults = (): Pick<
  Integrations.IntegrationFrontend,
  'getIntegrationType' | 'canAddAsNewIntegration'
> => ({
  getIntegrationType: () => 'DynamicBase',
  canAddAsNewIntegration: (existing) =>
    !existing.some(
      (otherItem) => otherItem.getIntegrationType() === 'DynamicBase',
    ),
});

import {
  FORCE_RANGE,
  targetForceStringConvert,
} from '@sb/integrations/OnRobotScrewdriver';
import {
  RangeSpinner,
  useSingleValueRangeSpinner,
  Typography,
} from '@sb/ui/components';

import type { DualValueModeProps } from './types';

export default function GripperTargetForce({
  gripperControlState,
}: DualValueModeProps) {
  const { changeTargetForce, target } = gripperControlState;

  const spinnerProps = useSingleValueRangeSpinner({
    value: target.targetForce,
    min: FORCE_RANGE.min,
    max: FORCE_RANGE.max,
    step: FORCE_RANGE.step,
    onChange: changeTargetForce,
  });

  return (
    <>
      <Typography>Z Axis Force</Typography>
      <RangeSpinner
        {...spinnerProps}
        valueToString={targetForceStringConvert}
      />
    </>
  );
}

import { Typography } from '@sb/ui/components';
import { useFeatureFlag } from '@sbrc/hooks';

import styles from './LimitsSectionHeader.module.css';

interface LimitsSectionHeaderProps {
  title: string;
}

export function LimitsSectionHeader({ title }: LimitsSectionHeaderProps) {
  const isSafetyIOEnabled = useFeatureFlag('safetyIO');

  return (
    <>
      <Typography className={styles.cell} isBold variant="medium">
        {title}
      </Typography>
      <Typography className={styles.cell}>Full Speed</Typography>
      <Typography className={styles.cell}>
        {isSafetyIOEnabled && 'Slow Speed'}
        &nbsp;
      </Typography>
      <div className={styles.cell}>&nbsp;</div>
    </>
  );
}

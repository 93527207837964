import type { NumberInputProps } from '@sb/ui/components';
import { NumberInput } from '@sb/ui/components';
import { useDistanceUnitInfo } from '@sbrc/hooks';

import { convertMaxMinStepProps } from './convertMaxMinStepProps';

/**
 * Component for entering distances,
 * will convert value from meters to the user's preference for display/input
 */
export function DistanceNumberInput({
  onChange,
  value,
  ...rest
}: NumberInputProps) {
  const distanceUnitInfo = useDistanceUnitInfo();

  const maxMinStepProps = convertMaxMinStepProps(rest, {
    convertValue: distanceUnitInfo.fromMeters,
  });

  return (
    <NumberInput
      {...rest}
      {...maxMinStepProps}
      decimalPlaces={distanceUnitInfo.decimalPlaces}
      onChange={(newValue) => onChange(distanceUnitInfo.toMeters(newValue))}
      suffix={distanceUnitInfo.abbreviation}
      value={distanceUnitInfo.fromMeters(value)}
    />
  );
}

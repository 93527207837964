import type { Integrations } from '@sb/types';
import { WidgetView } from '@sbrc/components/visualizer-view-shared/widget-panel';
import DisabledWidgetBody from '@sbrc/components/visualizer-view-shared/widget-panel/DisabledWidgetBody';

interface GripperErrorWidgetProps {
  implementation: Integrations.IntegrationFrontend;
  message: string;
  additionalControls?: React.ReactNode;
  onClose: () => void;
}

export default function GripperErrorWidget({
  implementation,
  message,
  additionalControls,
  onClose,
}: GripperErrorWidgetProps) {
  return (
    <WidgetView
      headerTitle={implementation.getDisplayName()}
      headerIcon={<implementation.InlineIcon />}
      onClose={onClose}
    >
      {additionalControls}
      <DisabledWidgetBody
        contentHeader={implementation.getDisplayName()}
        content={message}
      />
    </WidgetView>
  );
}

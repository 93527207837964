import { useState } from 'react';

import { Modal, ModalHeader } from '@sb/ui/components';
import { useAuthentication } from '@sbrc/hooks';

import UnsavedChangesAlert from '../UnsavedChangesAlert';

import { UserSettingsForm } from './UserSettingsForm';

import styles from './UserSettingsModal.module.css';

interface SafetySettingsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export function UserSettingsModal({
  isOpen,
  onClose,
}: SafetySettingsModalProps) {
  const { settings } = useAuthentication();

  const [isUnsavedChangesAlertOpen, setIsUnsavedChangesAlertOpen] =
    useState<boolean>(false);

  const [hasChanges, setHasChanges] = useState<boolean>(false);

  const handleClose = (forceClose = false) => {
    if (hasChanges && !forceClose) {
      setIsUnsavedChangesAlertOpen(true);
    } else {
      onClose();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => handleClose()}
      className={styles.modal}
    >
      <ModalHeader title="User Settings" />

      {isOpen && settings && (
        <UserSettingsForm
          initialSettings={settings}
          onClose={handleClose}
          setHasChanges={setHasChanges}
        />
      )}

      <UnsavedChangesAlert
        isOpen={isUnsavedChangesAlertOpen}
        onDiscard={() => {
          setIsUnsavedChangesAlertOpen(false);
          setHasChanges(false);
          onClose();
        }}
        onKeepEditing={() => setIsUnsavedChangesAlertOpen(false)}
      />
    </Modal>
  );
}

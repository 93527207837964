import SectionContainer from '@sbrc/components/visualizer-view-shared/widget-panel/SectionContainer';

import type { DualValueModeProps } from './types';

export default function GripperError({
  gripperControlState,
}: DualValueModeProps) {
  const { linearSensorError, error, isConnected, uncalibratedError } =
    gripperControlState;

  if (!isConnected) {
    return (
      <SectionContainer title="Disconnected">
        <p>Gripper does not appear to be connected to the arm properly.</p>
      </SectionContainer>
    );
  }

  if (linearSensorError) {
    return (
      <SectionContainer title="Error">
        <p>
          Gripper encountered a linear sensor error. Please refer to OnRobot's
          2FG7 documentation to resolve this issue.
        </p>

        <p>
          <strong>Note:</strong> This also happens when a 3FG15 is attached.
        </p>
      </SectionContainer>
    );
  }

  if (uncalibratedError) {
    return (
      <SectionContainer title="Error">
        <p>Gripper appears to be uncalibrated.</p>
      </SectionContainer>
    );
  }

  if (error) {
    return (
      <SectionContainer title="Error">
        <p>The 2FG7 has encountered an error: {error}</p>
      </SectionContainer>
    );
  }

  return null;
}

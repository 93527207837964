import type { Integrations } from '@sb/types';
import { RangeSpinner, Typography } from '@sb/ui/components';
import { margin, padding } from '@sb/ui/styles';
import { WidgetView } from '@sbrc/components/visualizer-view-shared/widget-panel';
import {
  useGuidedMode,
  useRobotDynamicBaseState,
  useRobotRoutineRunningState,
  useRoutineRunnerHandle,
  useToast,
} from '@sbrc/hooks';

function heightToString(meters: number) {
  return `${(meters * 1000).toFixed(0)}mm`;
}

export function EwellixLiftTLTWidget({
  robot,
  isVizbot,
  onClose,
  implementation,
}: Integrations.WidgetProps) {
  const routineRunnerHandleArgs = {
    robotID: robot.id,
    isVizbot,
  };

  const routineRunnerHandle = useRoutineRunnerHandle(routineRunnerHandleArgs);
  const dynamicBaseState = useRobotDynamicBaseState(routineRunnerHandleArgs);

  const routineRunningState = useRobotRoutineRunningState(
    routineRunnerHandleArgs,
  );

  const isRoutineRunning = routineRunningState !== null;

  const { runAdHocCommand, stopGuidedMode } = useGuidedMode(
    routineRunnerHandleArgs,
  );

  const { setToast } = useToast();

  const minHeightMeters = dynamicBaseState?.minHeightMeters ?? 0;
  const maxHeightMeters = dynamicBaseState?.maxHeightMeters ?? 0;

  const onSpinnerHold = async (isPositive: boolean) => {
    if (!dynamicBaseState) {
      return;
    }

    await runAdHocCommand({
      onRunCommand: () => {
        const targetHeight = isPositive ? maxHeightMeters : minHeightMeters;

        return routineRunnerHandle.actuateDevice({
          command: {
            kind: 'EwellixLiftTLTCommand',
            heightMeters: targetHeight,
            speedPercentage: 1,
          },
        });
      },

      onError: () => {
        setToast({ kind: 'error', message: 'Failed to actuate lift' });
      },
    });
  };

  return (
    <WidgetView
      onClose={onClose}
      headerTitle={implementation.getDisplayName()}
      headerIcon={<implementation.InlineIcon />}
    >
      <div className={padding.small}>
        <Typography isBold isUppercase>
          Lift Height
        </Typography>

        <RangeSpinner
          className={margin.bottom.small}
          isDisabled={!dynamicBaseState || isRoutineRunning}
          primaryValue={dynamicBaseState?.heightMeters ?? 0}
          min={minHeightMeters}
          max={maxHeightMeters}
          isSpinPlusDisabled={
            !dynamicBaseState ||
            dynamicBaseState.heightMeters >= maxHeightMeters
          }
          isSpinMinusDisabled={
            !dynamicBaseState ||
            dynamicBaseState.heightMeters <= minHeightMeters
          }
          onSpinnerHold={onSpinnerHold}
          onSpinnerRelease={stopGuidedMode}
          valueToString={heightToString}
        />
      </div>
    </WidgetView>
  );
}

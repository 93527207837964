import type { Notification } from '@sb/types';

import { notificationActionMessages } from './notificationActionMessages';

export const getNotificationMessage = (
  notification: Notification.ConvertedResponse | null,
) => {
  if (!notification) return null;

  if (notification.kind === 'robotFailure') return 'has failed';

  return '';
};

export const getNotificationReason = (
  notification: Notification.ConvertedResponse | null,
) => {
  if (!notification) return null;

  if (notification.kind === 'robotFailure') return notification.failureReason;

  return '';
};

export const getNotificationAction = (
  notification: Notification.ConvertedResponse | null,
) => {
  if (notification) {
    for (const { message, predicate } of notificationActionMessages) {
      if (predicate(notification)) {
        return message;
      }
    }
  }

  return null;
};

import React, { createContext, useContext } from 'react';

import type { Robot, Equipment, Integrations } from '@sb/types';

interface CustomIOEquipmentManagerContextType {
  portKind: Integrations.IOPort['kind'];
  assignedPorts: Integrations.IOPort[];
  robotIOInputs: Robot.InputPort[];
  robotIOOutputs: Robot.OutputPort[];
  otherEquipment: Equipment.ConvertedResponse[];
}

const CustomIOEquipmentManagerContext =
  createContext<CustomIOEquipmentManagerContextType>(null!);

interface CustomIOEquipmentManagerContextProviderProps
  extends CustomIOEquipmentManagerContextType {
  children?: React.ReactNode;
}

export function CustomIOEquipmentManagerContextProvider({
  children,
  ...rest
}: CustomIOEquipmentManagerContextProviderProps) {
  return (
    <CustomIOEquipmentManagerContext.Provider value={rest}>
      {children}
    </CustomIOEquipmentManagerContext.Provider>
  );
}

export function useCustomIOEquipmentManagerContext() {
  return useContext(CustomIOEquipmentManagerContext);
}

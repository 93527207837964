import type { Integrations } from '@sb/types';
import { ScrewdriverIcon } from '@sb/ui/icons';

import { getGripperImplementationDefaults } from '../frontend/getGripperImplementationDefaults';

import {
  COMPUTEBOX_HOST_DEFAULT,
  OR_SCREWDRIVER_DEFAULT_TOOLTIP_POSE,
} from './constants';
import { EquipmentManagerEditForm } from './editor/EquipmentManagerEditorForm';
import type { OnRobotScrewdriverConfiguration } from './types/Configuration';
import { OnRobotScrewdriverWidget } from './widget/OnRobotScrewdriverWidget';

export const onRobotScrewdriverImplementation: Integrations.IntegrationFrontend<OnRobotScrewdriverConfiguration> =
  {
    ...getGripperImplementationDefaults(),
    InlineIcon: ScrewdriverIcon,
    getSupportedSteps: () => ['ActuateScrewdriver'],
    getDisplayName: () => {
      return 'OnRobot Screwdriver';
    },
    getManufacturerName: () => {
      return 'OnRobot';
    },
    getShortName: () => {
      return 'Screwdriver';
    },
    getIntegrationVariables: () => [],
    getDefaultItem: (): OnRobotScrewdriverConfiguration => {
      return {
        kind: 'OnRobotScrewdriver',
        connectionOptions: {
          host: COMPUTEBOX_HOST_DEFAULT,
          port: 502,
        },
        tooltipConfig: {
          tooltipPose: OR_SCREWDRIVER_DEFAULT_TOOLTIP_POSE,
        },
      };
    },
    Widget: OnRobotScrewdriverWidget,
    EquipmentManagerEditForm,
  };

import cx from 'classnames';

import { getEquipmentByKind } from '@sb/integrations/frontend/getEquipmentByKind';
import type { Equipment } from '@sb/types';
import { Card, Typography } from '@sb/ui/components';
import { Flex, FlexChild } from '@sb/ui/components/Layout';
import { ForwardIcon, TrashIcon } from '@sb/ui/icons';

import styles from './shared.module.css';

export type EquipmentConnectionStatus =
  | 'connected'
  | 'disconnected'
  | 'unknown';

interface EquipmentManagerItemProps {
  equipment: Equipment.ConvertedResponse;
  name?: string;
  status: EquipmentConnectionStatus;
  isSelected: boolean;
  editing: boolean;
  onClickDelete: () => void;
}

export function EquipmentManagerItem({
  equipment,
  name,
  status,
  isSelected,
  editing,
  onClickDelete,
}: EquipmentManagerItemProps) {
  const equipmentFrontendImplementation = getEquipmentByKind(
    equipment.config.kind,
  );

  return (
    <Card
      borderSize="medium"
      className={cx({ [styles.selectedEquipmentManagerItem]: isSelected })}
    >
      <div
        className={cx(
          styles.equipmentItem,
          !equipment.isEnabled && styles.isDisabled,
        )}
      >
        <equipmentFrontendImplementation.InlineIcon
          className={styles.equipmentIcon}
        />
        <FlexChild>
          <div>
            <Flex direction="vertical">
              <Typography variant="small" className={styles.manufacturer}>
                {equipmentFrontendImplementation.getManufacturerName()}
              </Typography>
              <Typography component="h5" className={styles.shortName}>
                {name ?? equipmentFrontendImplementation.getShortName()}
              </Typography>
            </Flex>
          </div>
        </FlexChild>

        <div className={styles.equipmentItemStatusAndActions}>
          <div>
            {status === 'connected' && (
              <div
                className={cx(styles.statusDot, styles.connected)}
                title="Connected"
              />
            )}

            {status === 'disconnected' && (
              <div
                className={cx(styles.statusDot, styles.disconnected)}
                title="Disconnected"
              />
            )}
          </div>
          {editing ? (
            <TrashIcon
              role="button"
              size="small"
              data-testid="delete-equipment-button"
              onClick={(e) => {
                onClickDelete();
                e.stopPropagation();
              }}
            />
          ) : (
            <ForwardIcon size="small" />
          )}
        </div>
      </div>
      {status === 'disconnected' && (
        <div>
          <Card hasBorder className={styles.disconnectedErrorMessage}>
            This equipment appears to be disconnected. Please ensure that it's
            properly mounted and connected to the robot.
          </Card>
        </div>
      )}
    </Card>
  );
}

import type { Integrations } from '@sb/types';
import { EmergencyStopIcon } from '@sb/ui/icons';

import { EquipmentManagerEditForm } from './editor/EquipmentManagerEditorForm';
import type { InvalidConfiguration } from './types/Configuration';

export const invalidConfigurationImplementation: Integrations.IntegrationFrontend<InvalidConfiguration> =
  {
    getIntegrationType: () => 'Other',
    canAddAsNewIntegration: () => false,
    InlineIcon: EmergencyStopIcon,
    getSupportedSteps: () => [],
    getManufacturerName: () => 'Invalid Configuration',
    getShortName: () => 'Invalid Configuration',
    getDisplayName: () => {
      return 'Invalid Configuration';
    },
    getDefaultItem: (): InvalidConfiguration => {
      return { kind: 'InvalidConfiguration' };
    },
    EquipmentManagerEditForm,
    getIntegrationVariables: () => [],
  };

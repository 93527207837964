import isValidHostname from 'is-valid-hostname';
import { useState } from 'react';

import type { Integrations } from '@sb/types';
import { InputField, Layout, Typography } from '@sb/ui/components';
import { margin } from '@sb/ui/styles';

export function EquipmentManagerEditForm({
  item,
  onUpdate,
  isFormDisabled,
}: Integrations.EquipmentManagerEditFormProps) {
  if (item.kind !== 'OnRobotVGP20') {
    throw new Error(`Invalid item kind: ${item.kind}`);
  }

  const { connectionOptions } = item;
  const [host, setHost] = useState<string>(connectionOptions.host);
  const hasError = !isValidHostname(connectionOptions.host);

  return (
    <Layout.Flex direction="vertical">
      <Typography component="h5" className={margin.bottom.extraSmall}>
        Network
      </Typography>
      <Layout.Flex direction="vertical">
        <InputField
          hasError={hasError}
          value={host}
          onChange={(e) => {
            setHost(e.target.value);

            onUpdate({
              ...item,
              connectionOptions: {
                ...item.connectionOptions,
                host: e.target.value,
              },
            });
          }}
          disabled={isFormDisabled}
        >
          Host IP
        </InputField>
      </Layout.Flex>
    </Layout.Flex>
  );
}

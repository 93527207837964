import { useRef } from 'react';

import type { Robot } from '@sb/types';
import { Button } from '@sb/ui/components';
import { PauseIcon, PlayIcon, EmergencyStopIcon } from '@sb/ui/icons';
import {
  useEmergencyStopRobot,
  useIsRobotMoving,
  useIsRobotPaused,
  usePlayRobot,
  useRobotStateKind,
} from '@sbrc/hooks';

import type { WaitForConfirmationModalRef } from '../notifications';
import { NotificationSystem, WaitForConfirmationModal } from '../notifications';
import RobotBadgeDropdown from '../robot-badge/RobotBadgeDropdown';
import { useSoftwareBuildData } from '../software-update';

import HeaderHamburgerMenu from './HeaderHamburgerMenu';

import styles from './AppHeader.module.css';

interface AppHeaderProps {
  isDisabled?: boolean;
  children: React.ReactNode;
  robot: Robot.ConvertedResponse;
}

const AppHeader = ({ children, isDisabled, robot }: AppHeaderProps) => {
  const robotID = robot.id;
  const routineRunnerArgs = { robotID };

  // routine data
  const routineRunnerKind = useRobotStateKind(routineRunnerArgs);
  const isPaused = useIsRobotPaused(routineRunnerArgs);
  const isRoutinePlaying = routineRunnerKind === 'RoutineRunning' && !isPaused;

  // robot data
  const { isRobotMoving } = useIsRobotMoving(routineRunnerArgs);
  const { onPlay, onPause } = usePlayRobot({ robot });
  const { emergencyStopRobot } = useEmergencyStopRobot(routineRunnerArgs);

  // build data
  const buildData = useSoftwareBuildData({
    shouldReloadOnActiveBuildIdChange: true,
  });

  const waitForConfirmationModalRef = useRef<WaitForConfirmationModalRef>(null);

  const onPauseButtonClick = () => {
    if (waitForConfirmationModalRef.current?.isWaitingForConfirmation) {
      waitForConfirmationModalRef.current.open();

      return;
    }

    if (isRoutinePlaying) {
      onPause();

      return;
    }

    onPlay();
  };

  return (
    <header className={styles.appHeader}>
      <div className={styles.headerLeft}>{children}</div>

      <div className={styles.headerMiddle}>
        <Button
          className={styles.button}
          buttonChildrenClassName={styles.buttonContent}
          disabled={!isRobotMoving}
          startIcon={<EmergencyStopIcon />}
          variant="alertPrimary"
          onClick={() => emergencyStopRobot()}
          data-testid="app-header-estop-button"
        >
          Emergency Stop
        </Button>
      </div>

      <div className={styles.headerRight}>
        <RobotBadgeDropdown
          robot={robot}
          isDisabled={isDisabled}
          buildData={buildData}
        />

        <Button
          className={styles.button}
          buttonChildrenClassName={styles.buttonContent}
          disabled={routineRunnerKind !== 'RoutineRunning'}
          startIcon={isRoutinePlaying ? <PauseIcon /> : <PlayIcon />}
          onClick={onPauseButtonClick}
          data-testid="app-header-play-pause-button"
        >
          {isRoutinePlaying ? 'Pause' : 'Play'}
        </Button>

        <HeaderHamburgerMenu />

        <NotificationSystem
          variant="light"
          dropdownClassName={styles.notificationSystemDropdown}
        />

        <WaitForConfirmationModal
          ref={waitForConfirmationModalRef}
          robotID={robot.id}
          shouldOpenAutomatically
        />
      </div>
    </header>
  );
};

export default AppHeader;

import { Modal, ModalHeader } from '@sb/ui/components';

import { DataBackupModalBody } from './DataBackupModalBody';

import styles from './DataBackupModal.module.css';

interface DataBackupModalProps {
  isOpen: boolean;
  onClose: () => void;
  robotID: string;
}

export const DataBackupModal = ({
  isOpen,
  robotID,
  onClose,
}: DataBackupModalProps) => {
  if (!isOpen) {
    return null;
  }

  return (
    <Modal className={styles.backupModal} isOpen onClose={onClose}>
      <ModalHeader title="Backups" />
      <DataBackupModalBody robotID={robotID} />
    </Modal>
  );
};

import { GripperIcon } from '@sb/ui/icons';
import { WidgetView } from '@sbrc/components/visualizer-view-shared/widget-panel';
import DisabledWidgetBody from '@sbrc/components/visualizer-view-shared/widget-panel/DisabledWidgetBody';

interface MoveRobotGripperWidgetProps {
  onClose: () => void;
  message?: string;
  additionalControls?: React.ReactNode;
}

export default function NoGripperWidget({
  onClose,
  additionalControls,
  message = 'Add the gripper that is attached to the robot in the equipment panel.',
}: MoveRobotGripperWidgetProps) {
  return (
    <WidgetView
      headerTitle="Gripper"
      headerIcon={<GripperIcon />}
      onClose={onClose}
      testPrefix="no-gripper-control"
    >
      {additionalControls}
      <DisabledWidgetBody contentHeader="No gripper found" content={message} />
    </WidgetView>
  );
}

import { Alert, AlertActions, AlertTitle, Button } from '@sb/ui/components';

interface UnsavedChangesAlertProps {
  isOpen: boolean;
  onDiscard: () => void;
  onKeepEditing: () => void;
}

const UnsavedChangesAlert = ({
  isOpen,
  onDiscard,
  onKeepEditing,
}: UnsavedChangesAlertProps) => {
  return (
    <Alert isOpen={isOpen}>
      <AlertTitle>
        Are you sure you want to leave without saving your changes?
      </AlertTitle>
      <AlertActions>
        <Button onClick={onKeepEditing} variant="primary">
          Keep Editing
        </Button>
        <Button onClick={onDiscard} variant="alertPrimary">
          Discard Changes
        </Button>
      </AlertActions>
    </Alert>
  );
};

export default UnsavedChangesAlert;

import { create } from 'zustand';

interface DualValueModeState {
  isDualValueMode: boolean;
  toggleDualValueMode: () => void;
}

export function createDualValueModeStore(initialValue = false) {
  return create<DualValueModeState>((set) => ({
    isDualValueMode: initialValue,
    toggleDualValueMode: () =>
      set((state) => ({
        isDualValueMode: !state.isDualValueMode,
      })),
  }));
}

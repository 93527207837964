import { useEffect } from 'react';

import type { DeviceCommand } from '@sb/integrations/device';
import { createDualValueModeStore } from '@sb/integrations/gripper-general/shared-components/createDualValueModeStore';
import { areOR3FG15GripperPositionsEqual } from '@sb/integrations/OnRobot3FG15';
import type { OnRobot3FG15State } from '@sb/integrations/OnRobot3FG15';
import { GripperControlDualValueMode } from '@sb/integrations/OnRobot3FG15/widget/dual-value-mode/GripperControlDualValueMode';
import { useGripperControlState } from '@sb/integrations/OnRobot3FG15/widget/gripper-control-state';
import { GripperControlSingleValueMode } from '@sb/integrations/OnRobot3FG15/widget/single-value-mode/GripperControlSingleValueMode';
import type { Integrations, Robot } from '@sb/types';
import { Button } from '@sb/ui/components';
import { DownloadIcon, ResetAllIcon, SwitcherIcon } from '@sb/ui/icons';
import {
  sharedStyles,
  WidgetView,
} from '@sbrc/components/visualizer-view-shared/widget-panel';

import type {
  CompatibleCommand,
  OnRobotDualQuickChangerCommand,
} from '../Command';

const useDualValueMode = createDualValueModeStore();

interface DualOnRobot3FG15WidgetInnerProps {
  isVizbot: boolean;
  robot: Robot.ConvertedResponse;
  onCommandChange?: React.Dispatch<React.SetStateAction<DeviceCommand | null>>;
  onClose: () => void;
  additionalControls?: React.ReactNode;
  routineRunnerGripperState: OnRobot3FG15State;
  otherRobotGripperState: OnRobot3FG15State | null;
  routineRunnerPayload: number;
  dualChangerCommand: OnRobotDualQuickChangerCommand;
  changeActiveGripperCommand: (action: CompatibleCommand) => void;
  getEquipmentByKind: Integrations.GetEquipmentByKind;
}

export function DualOnRobot3FG15WidgetInner({
  routineRunnerGripperState,
  isVizbot,
  robot,
  onCommandChange,
  onClose,
  otherRobotGripperState,
  routineRunnerPayload,
  additionalControls,
  dualChangerCommand,
  changeActiveGripperCommand,
  getEquipmentByKind,
}: DualOnRobot3FG15WidgetInnerProps) {
  const routineRunnerArgs = {
    robotID: robot.id,
    isVizbot,
  };

  const gripperControlState = useGripperControlState({
    ...routineRunnerArgs,
    routineRunnerGripperState,
    routineRunnerPayload,
  });

  const {
    canApplyGripperChanges,
    changeTargetDiameter,
    changeGripKind,
    conformGripperControlStateToActualState,
    targetDiameterMeters,
    command,
  } = gripperControlState;

  useEffect(() => {
    if (onCommandChange) {
      onCommandChange(dualChangerCommand);
    }
  }, [onCommandChange, dualChangerCommand]);

  useEffect(() => {
    changeActiveGripperCommand(command);
  }, [changeActiveGripperCommand, command]);

  const { isDualValueMode, toggleDualValueMode } = useDualValueMode();

  const buttonText = isVizbot ? 'Live Robot' : 'Visualizer';

  const onRobot3FG15Implementation = getEquipmentByKind('OnRobot3FG15');

  const areGripperPositionsEqual = areOR3FG15GripperPositionsEqual(
    otherRobotGripperState,
    targetDiameterMeters,
    command,
  );

  return (
    <WidgetView
      onClose={onClose}
      headerTitle={onRobot3FG15Implementation.getDisplayName()}
      testPrefix="onrobot-3fg15-control"
      headerIcon={<onRobot3FG15Implementation.InlineIcon />}
      headerButton={
        <>
          <Button
            className={sharedStyles.headerButtonText}
            startIcon={<DownloadIcon className={sharedStyles.buttonIcon} />}
            label="Import gripper state"
            size="extraSmall"
            variant="gray"
            onClick={() => {
              if (otherRobotGripperState) {
                changeGripKind(otherRobotGripperState?.gripKind);
                changeTargetDiameter(otherRobotGripperState?.diameterMeters);
              }
            }}
            disabled={!isDualValueMode || areGripperPositionsEqual}
          >
            {buttonText}
          </Button>

          <Button
            startIcon={<ResetAllIcon className={sharedStyles.buttonIcon} />}
            label="Reset to current gripper state"
            size="extraSmall"
            variant="gray"
            onClick={() => {
              if (routineRunnerGripperState) {
                conformGripperControlStateToActualState(
                  routineRunnerGripperState,
                );
              }
            }}
            disabled={!isDualValueMode || !canApplyGripperChanges}
            data-testid="gripper-widget-reset-current-gripper-state-button"
          />

          <Button
            startIcon={<SwitcherIcon className={sharedStyles.buttonIcon} />}
            label="Switch gripper control mode"
            size="extraSmall"
            onClick={toggleDualValueMode}
            variant={isDualValueMode ? 'primary' : 'gray'}
          />
        </>
      }
    >
      <div className={sharedStyles.controls}>
        {additionalControls}

        {isDualValueMode ? (
          <GripperControlDualValueMode
            gripperControlState={gripperControlState}
            routineRunnerArgs={routineRunnerArgs}
            dualChangerCommand={dualChangerCommand}
          />
        ) : (
          <GripperControlSingleValueMode
            gripperState={routineRunnerGripperState}
            routineRunnerArgs={routineRunnerArgs}
            activeDualChangeGripper={dualChangerCommand.active}
          />
        )}
      </div>
    </WidgetView>
  );
}

import cx from 'classnames';

import type { Integrations } from '@sb/types';
import { Button, InputField } from '@sb/ui/components';
import { ArrowDownFilledIcon } from '@sb/ui/icons';
import { padding } from '@sb/ui/styles';
import { IOBadge, IOSendSignalButton } from '@sbrc/components/io-manager';
import { useRobotIOState } from '@sbrc/hooks';

import { PortDropdown } from './PortDropdown';

import styles from './IOPortRow.module.css';

type IOPortRowProps = {
  port: Integrations.IOPort;
  onChange: (port: Integrations.IOPort) => void;
  onDelete: () => void;
};

export const IOPortRow = ({ port, onChange, onDelete }: IOPortRowProps) => {
  const ioState = useRobotIOState({
    kind: port.kind,
    port: port.port,
    robotID: null,
  });

  return (
    <tr>
      <td className={cx(padding.top.extraSmall)}>
        <PortDropdown
          selectedPort={port}
          onSelect={(p) => onChange({ ...port, port: p.port })}
          onUnassign={onDelete}
        >
          {(onOpen) => (
            <Button
              aria-label="Change port number"
              variant="gray"
              onClick={onOpen}
              className={styles.portDropdownButton}
              buttonChildrenClassName={styles.portDropdownButtonChildren}
              endIcon={
                <ArrowDownFilledIcon className={styles.portDropdownIcon} />
              }
            >
              {port.kind === 'Input' ? 'IN' : 'OUT'} {port.port}
            </Button>
          )}
        </PortDropdown>
      </td>
      <td className={cx(padding.left.small, padding.top.extraSmall)}>
        <InputField
          aria-label={`${port.kind} port ${port.port} name`}
          sizeVariant="small"
          value={port.name}
          onChange={(e) => onChange({ ...port, name: e.target.value })}
          inputContainerClassName={styles.portRowInput}
        />
      </td>
      <td className={cx(padding.left.small, padding.top.extraSmall)}>
        <div className={styles.inputContainer}>
          <IOBadge
            className={cx(styles.inputDecorator, styles.inlinePortBadge, {
              [styles.activePort]: ioState === 'high',
            })}
          >
            H
          </IOBadge>
          <InputField
            sizeVariant="small"
            value={port.highSignalName}
            onChange={(e) =>
              onChange({ ...port, highSignalName: e.target.value })
            }
            inputContainerClassName={cx(
              styles.portRowInput,
              styles.inputWithDecorator,
            )}
          />
          {port.kind === 'Output' && (
            <IOSendSignalButton
              className={styles.inputEndDecorator}
              ioPort={port.port}
              ioLevel="high"
              robotID={null}
              disabled={ioState === 'high'}
              kind={port.kind}
            />
          )}
        </div>
      </td>
      <td className={cx(padding.left.small, padding.top.extraSmall)}>
        <div className={styles.inputContainer}>
          <IOBadge
            className={cx(styles.inputDecorator, styles.inlinePortBadge, {
              [styles.activePort]: ioState === 'low',
            })}
          >
            L
          </IOBadge>
          <InputField
            sizeVariant="small"
            value={port.lowSignalName}
            onChange={(e) =>
              onChange({ ...port, lowSignalName: e.target.value })
            }
            inputContainerClassName={cx(
              styles.portRowInput,
              styles.inputWithDecorator,
            )}
          />
          {port.kind === 'Output' && (
            <IOSendSignalButton
              className={styles.inputEndDecorator}
              ioPort={port.port}
              ioLevel="low"
              robotID={null}
              disabled={ioState === 'low'}
              kind={port.kind}
            />
          )}
        </div>
      </td>
    </tr>
  );
};

import cx from 'classnames';
import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';

import { canAddIntegration } from '@sb/integrations/frontend/util';
import type { Equipment, Integrations, Robot } from '@sb/types';
import {
  Typography,
  Button,
  IconButton,
  InputField,
  Switch,
} from '@sb/ui/components';
import { CloseIcon, ArrowBackIcon } from '@sb/ui/icons';
import { display, justifyContent, margin } from '@sb/ui/styles';

import { NoEquipmentEditorForm } from './NoEquipmentEditorForm';

import styles from './shared.module.css';

type EditEquipmentProps = {
  equipmentItem: Equipment.ConvertedResponse;
  otherEquipment: Equipment.ConvertedResponse[];
  robot: Robot.ConvertedResponse;
  onSave: (settings: Equipment.EditableFields) => void;
  onClose: () => void;
  onBack?: () => void;
  getEquipmentByKind: Integrations.GetEquipmentByKind;
  showEnabledSwitch?: boolean;
  isFormDisabled: boolean;
};

export function EditEquipment({
  equipmentItem,
  otherEquipment,
  robot,
  onSave,
  onClose,
  onBack,
  getEquipmentByKind,
  showEnabledSwitch,
  isFormDisabled,
}: EditEquipmentProps) {
  const [settingsState = equipmentItem, setSettingsState] = useState<
    Equipment.EditableFields | undefined
  >(equipmentItem);

  const [childContent, setChildContent] = useState<React.ReactElement | null>(
    null,
  );

  useEffect(() => {
    setSettingsState(undefined);
    setChildContent(null);
  }, [equipmentItem.id]);

  const { EquipmentManagerEditForm = NoEquipmentEditorForm, getDisplayName } =
    getEquipmentByKind(settingsState.config.kind);

  if (childContent) {
    return childContent;
  }

  const isEnableable =
    showEnabledSwitch &&
    canAddIntegration(
      settingsState.config.kind,
      otherEquipment,
      getEquipmentByKind,
    );

  return (
    <>
      <div className={styles.detailContainerHeader}>
        <IconButton
          onClick={() => onBack?.()}
          data-testid="edit-equipment-back"
          className={cx({ [styles.detailContainerHeaderBackButton]: !onBack })}
        >
          <ArrowBackIcon size="small" />
        </IconButton>
        <div className={styles.detailContainerHeaderText}>
          <Typography component="h5">{getDisplayName()}</Typography>
        </div>
        <IconButton
          onClick={() => onClose()}
          data-testid="close-edit-equipment"
        >
          <CloseIcon size="small" />
        </IconButton>
      </div>
      <div
        className={cx(
          styles.equipmentModalContent,
          styles.detailContainerContent,
        )}
      >
        <div className={margin.bottom.medium}>
          <div className={cx(display.flex.row, justifyContent.spaceBetween)}>
            <Typography component="h5">Equipment info</Typography>
            {showEnabledSwitch && (
              <Switch
                checked={settingsState.isEnabled}
                rightLabel="Enabled"
                disabled={
                  isFormDisabled || (!settingsState.isEnabled && !isEnableable)
                }
                onChange={(e) => {
                  if (e.target.checked && !isEnableable) {
                    return;
                  }

                  const newSettingsState = {
                    ...settingsState,
                    isEnabled: e.target.checked,
                  };

                  onSave(newSettingsState);
                  setSettingsState(newSettingsState);
                }}
              />
            )}
          </div>
          <InputField
            sizeVariant="small"
            value={settingsState.config.name ?? ''}
            placeholder="None"
            onChange={(e) => {
              setSettingsState({
                ...settingsState,
                config: { ...settingsState.config, name: e.target.value },
              });
            }}
            data-testid="name-input"
            disabled={isFormDisabled}
          >
            Name
          </InputField>
        </div>
        <div>
          <EquipmentManagerEditForm
            item={settingsState.config}
            otherEquipment={otherEquipment}
            robot={robot}
            onUpdate={(value) =>
              setSettingsState({ ...settingsState, config: value })
            }
            getEquipmentByKind={getEquipmentByKind}
            onClose={onClose}
            setChildContent={setChildContent}
            isFormDisabled={isFormDisabled}
          />
        </div>
      </div>
      <div className={cx(styles.footer, styles.detailFooter)}>
        <div className={cx(display.flex.row, justifyContent.end)}>
          <Button
            onClick={() => onSave(settingsState)}
            disabled={
              isFormDisabled ||
              isEqual(settingsState.config, equipmentItem.config)
            }
          >
            Save
          </Button>
        </div>
      </div>
    </>
  );
}

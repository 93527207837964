import { Button, Typography } from '@sb/ui/components';
import { ResetIcon, SwitchIcon } from '@sb/ui/icons';
import SectionContainer from '@sbrc/components/visualizer-view-shared/widget-panel/SectionContainer';

import type { DualValueModeProps } from './types';

import styles from './GripKind.module.css';

export default function GripKind({ gripperControlState }: DualValueModeProps) {
  const { changeGripKind, command, routineRunnerGripKind } =
    gripperControlState;

  const { gripKind: targetGripKind } = command;

  const hasChangedGripKind = routineRunnerGripKind !== command.gripKind;

  const toggleGripKind = () => {
    const newGripKind = command.gripKind === 'inward' ? 'outward' : 'inward';

    changeGripKind(newGripKind);
  };

  return (
    <SectionContainer title="Grip Kind">
      <div className={styles.gripKindContainer}>
        <button className={styles.gripKindToggle} onClick={toggleGripKind}>
          <Typography
            className={styles.label}
            color="black"
            isCapitalized
            hasLineThrough={hasChangedGripKind}
            isBold={!hasChangedGripKind}
            data-testid="grip-kind-slider-first-widget"
          >
            {routineRunnerGripKind}
          </Typography>

          {hasChangedGripKind && (
            <Typography
              color="primary"
              isCapitalized
              isBold
              data-testid="grip-kind-slider-second-widget"
            >
              {targetGripKind}
            </Typography>
          )}
        </button>

        <Button
          startIcon={<SwitchIcon />}
          aria-label="Switch grip kind"
          onClick={toggleGripKind}
          variant="primary"
          data-testid="grip-kind-button-second-widget"
        />

        <Button
          disabled={!hasChangedGripKind}
          variant="gray"
          startIcon={<ResetIcon />}
          aria-label="Reset grip kind"
          onClick={() => changeGripKind(routineRunnerGripKind)}
          data-testid="grip-kind-slider-first-widget"
        />
      </div>
    </SectionContainer>
  );
}

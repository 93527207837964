import cx from 'classnames';

import { Typography } from '@sb/ui/components';
import { padding } from '@sb/ui/styles';

import styles from './SectionContainer.module.css';

interface SectionContainerProps {
  children: React.ReactNode;
  hasMeter?: boolean;
  isDisabled?: boolean;
  title?: string;
  extraTitle?: React.ReactNode;
}

export default function SectionContainer({
  children,
  hasMeter,
  isDisabled,
  title,
  extraTitle,
}: SectionContainerProps) {
  return (
    <div
      className={cx(padding.small, {
        [padding.top.extraSmall]: title,
        [padding.bottom.extraSmall]: hasMeter,
      })}
    >
      {(title || extraTitle) && (
        <div className={styles.titleContainer}>
          {title && (
            <Typography
              isBold
              isUppercase
              className={cx(isDisabled && styles.disabled)}
            >
              {title}
            </Typography>
          )}
          <Typography>{extraTitle}</Typography>
        </div>
      )}

      {children}
    </div>
  );
}

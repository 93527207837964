import cx from 'classnames';

import { Card, Typography } from '@sb/ui/components';

import styles from './IOCard.module.css';

interface IOCardProps {
  children: React.ReactNode;
  className?: string;
  containerClassName?: string;
  title?: string;
  subtitle?: string;
}

const IOCard = ({
  children,
  className,
  containerClassName,
  title,
  subtitle,
}: IOCardProps) => {
  return (
    <Card
      hasBorder
      borderSize="medium"
      className={cx(styles.ioCard, className)}
    >
      {title && (
        <div className={styles.ioCardHeader}>
          <Typography variant="medium" isBold hasNoWrap>
            {title}
          </Typography>
          {subtitle && (
            <Typography
              variant="extraSmall"
              isUppercase
              isBold
              color="gray"
              hasNoWrap
            >
              {subtitle}
            </Typography>
          )}
        </div>
      )}

      <div className={cx(styles.childrenContainer, containerClassName)}>
        {children}
      </div>
    </Card>
  );
};

export default IOCard;

import cx from 'classnames';

import { Switch, Typography } from '@sb/ui/components';
import { six } from '@sb/utilities';

import { useFactoryPreset, useSettingValue } from '../../store';

import type { FactoryPresetInfo } from './factoryPresets';
import {
  FACTORY_PRESET_INFOS,
  getFactoryPresetForTooltipSpeed,
} from './factoryPresets';
import { Thumb } from './Thumb';

import styles from './FactoryPresetChooser.module.css';

export function FactoryPresetChooser() {
  const [maxTooltipSpeed] = useSettingValue('maxTooltipSpeed', (v) =>
    typeof v === 'number' ? v : null,
  );

  const [factoryPreset, setFactoryPresetSettings, isDisabled] =
    useFactoryPreset();

  const factoryPresetInfo = FACTORY_PRESET_INFOS.find(
    (info) => info.factoryPreset === factoryPreset,
  );

  const handleClearFactoryPreset = () => {
    if (isDisabled || factoryPreset === 'none') {
      return;
    }

    setFactoryPresetSettings({ factoryPreset: 'none' });
  };

  const handleSelectFactoryPreset = (info: FactoryPresetInfo) => {
    if (isDisabled || factoryPreset === info.factoryPreset) {
      return;
    }

    setFactoryPresetSettings({
      factoryPreset: info.factoryPreset,
      maxTooltipSpeed: info.maxTooltipSpeed,
      slowTooltipSpeed: info.slowTooltipSpeed,
      maxJointAccelerations: info.maxJointAccelerations ?? six(null),
      maxJointSpeeds: info.maxJointSpeeds ?? six(null),
      maxJointTorques: six(null),
      slowJointAccelerations: six(null),
      slowJointSpeeds: six(null),
      slowJointTorques: six(null),
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.switchContainer}>
        <Switch
          checked={factoryPreset !== 'none'}
          onChange={(e) => {
            if (e.target.checked) {
              handleSelectFactoryPreset(
                getFactoryPresetForTooltipSpeed(maxTooltipSpeed),
              );
            } else {
              handleClearFactoryPreset();
            }
          }}
          disabled={isDisabled}
          rightLabel="Use Factory Preset"
        />

        {factoryPreset === 'none' && (
          <Typography color="gray">
            Custom limits entered below will apply.
          </Typography>
        )}
      </div>

      {factoryPreset !== 'none' && (
        <div
          className={cx(styles.optionsContainer, isDisabled && styles.disabled)}
        >
          <div className={styles.optionGroup}>
            {FACTORY_PRESET_INFOS.map((info) => (
              <label
                key={info.factoryPreset}
                className={styles.option}
                onPointerEnter={(e) =>
                  e.buttons && handleSelectFactoryPreset(info)
                }
                onPointerDown={(e) => {
                  (e.target as HTMLLabelElement).releasePointerCapture(
                    e.pointerId,
                  );

                  handleSelectFactoryPreset(info);
                }}
              >
                <input
                  className={cx(styles.radioButton, styles[info.factoryPreset])}
                  type="radio"
                  checked={info.factoryPreset === factoryPreset}
                  disabled={isDisabled}
                  onChange={(e) => {
                    if (e.target.checked) {
                      handleSelectFactoryPreset(info);
                    }
                  }}
                />
                <Typography component="span" variant="small" color="gray">
                  {info.label}
                </Typography>
              </label>
            ))}
          </div>

          <div className={cx(styles.thumbContainer, styles[factoryPreset])}>
            <Thumb className={styles.thumb} />
          </div>
        </div>
      )}

      {factoryPresetInfo && (
        <Typography variant="small">
          <strong>{factoryPresetInfo.label}:</strong>{' '}
          {factoryPresetInfo.description}
        </Typography>
      )}
    </div>
  );
}

import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function InwardMountingPosition(props: IconProps) {
  return (
    <Icon viewBox="0 0 14 12" {...props}>
      <path
        fillRule="evenodd"
        d="M12.5 0H1.5C0.671573 0 0 0.671573 0 1.5C0 2.15441 0.419071 2.71095 1.00349 2.91588C1.00118 2.94361 1 2.97167 1 3V5C1 5.55228 1.44772 6 2 6H4.5V11.25C4.5 11.6642 4.83579 12 5.25 12C5.66421 12 6 11.6642 6 11.25V3H8V11.25C8 11.6642 8.33579 12 8.75 12C9.16421 12 9.5 11.6642 9.5 11.25V6H12C12.5523 6 13 5.55228 13 5V3C13 2.97167 12.9988 2.94361 12.9965 2.91588C13.5809 2.71095 14 2.15441 14 1.5C14 0.671572 13.3284 0 12.5 0ZM12 3H9V5H12V3ZM12.5 2C12.7761 2 13 1.77614 13 1.5C13 1.22386 12.7761 1 12.5 1H1.5C1.22386 1 1 1.22386 1 1.5C1 1.77614 1.22386 2 1.5 2H12.5ZM5 3H2V5H5V3Z"
      />
    </Icon>
  );
}

/* eslint-disable react/destructuring-assignment */

import GripperErrorWidget from '@sb/integrations/gripper-general/shared-components/GripperError';
import type { Integrations } from '@sb/types';
import { useRobotGripperState } from '@sbrc/hooks';

import { OnRobotScrewdriverWidgetInner } from './OnRobotScrewdriverWidgetInner';

export function OnRobotScrewdriverWidget(args: Integrations.WidgetProps) {
  const routineRunnerArgs = {
    robotID: args.robot.id,
    isVizbot: args.isVizbot,
  };

  const routineRunnerGripperState = useRobotGripperState(
    routineRunnerArgs,
    'OnRobotScrewdriver',
  );

  if (!routineRunnerGripperState || !routineRunnerGripperState.isConnected) {
    return (
      <GripperErrorWidget
        implementation={args.implementation}
        onClose={args.onClose}
        message="The gripper does not appear to be connected. Please ensure a Screwdriver gripper is properly mounted and connected."
      />
    );
  }

  return (
    <OnRobotScrewdriverWidgetInner
      routineRunnerGripperState={routineRunnerGripperState}
      args={args}
    />
  );
}

import cx from 'classnames';
import { useState } from 'react';

import type { Integrations } from '@sb/types';
import {
  Layout,
  InputLabel,
  NumberInput,
  Select,
  SelectItem,
  Typography,
  Button,
  Dropdown,
  MenuItem,
} from '@sb/ui/components';
import { SettingsIcon, ArrowDownIcon } from '@sb/ui/icons';
import { margin } from '@sb/ui/styles';

import styles from './shared.module.css';

export function EquipmentManagerEditForm({
  item,
  onUpdate,
  isFormDisabled,
}: Integrations.EquipmentManagerEditFormProps) {
  if (item.kind !== 'OnRobot3FG15') {
    throw new Error(`Invalid item kind: ${item.kind}`);
  }

  const { fingerConfiguration } = item;

  if (!fingerConfiguration) {
    throw new Error(
      'This form only works for 3FG15 grippers with finger configurations set.',
    );
  }

  const [isOffsetPresetDropdownOpen, setIsOffsetPresetDropdownOpen] =
    useState(false);

  const updateOffset = (value: number) => {
    onUpdate({
      ...item,
      fingerConfiguration: {
        ...fingerConfiguration,
        offsetInMeters: value / 1000,
      },
    });
  };

  return (
    <Layout.Flex direction="vertical">
      <Typography component="h5" className={margin.bottom.extraSmall}>
        Finger geometry
      </Typography>
      <Layout.Flex direction="vertical">
        <Layout.FlexChild grow={1}>
          <Layout.Flex direction="vertical">
            <div className={styles.threeColumnInput}>
              <Select
                label={<InputLabel>Mounting position</InputLabel>}
                activeLabel={`Position ${fingerConfiguration.mountingPosition}`}
                isDisabled={isFormDisabled}
              >
                <SelectItem
                  value={1}
                  onClick={() =>
                    onUpdate({
                      ...item,
                      fingerConfiguration: {
                        ...fingerConfiguration,
                        mountingPosition: 1,
                      },
                    })
                  }
                >
                  Position 1
                </SelectItem>
                <SelectItem
                  value={2}
                  onClick={() =>
                    onUpdate({
                      ...item,
                      fingerConfiguration: {
                        ...fingerConfiguration,
                        mountingPosition: 2,
                      },
                    })
                  }
                >
                  Position 2
                </SelectItem>
                <SelectItem
                  value={3}
                  onClick={() =>
                    onUpdate({
                      ...item,
                      fingerConfiguration: {
                        ...fingerConfiguration,
                        mountingPosition: 3,
                      },
                    })
                  }
                >
                  Position 3
                </SelectItem>
              </Select>
            </div>
          </Layout.Flex>
        </Layout.FlexChild>
        <Layout.FlexChild grow={1} className={margin.top.small}>
          <div className={styles.threeColumnInput}>
            <NumberInput
              value={fingerConfiguration.lengthInMeters * 1000}
              step={0.01}
              decimalPlaces={2}
              suffix="mm"
              onChange={(value) =>
                onUpdate({
                  ...item,
                  fingerConfiguration: {
                    ...fingerConfiguration,
                    lengthInMeters: value / 1000,
                  },
                })
              }
              disabled={isFormDisabled}
            >
              <InputLabel>Finger length</InputLabel>
            </NumberInput>
          </div>
        </Layout.FlexChild>
        <Layout.FlexChild grow={1} className={margin.top.small}>
          <Layout.Flex direction="vertical">
            <Layout.Flex
              direction="horizontal"
              className={styles.inputWithDropdown}
            >
              <div className={styles.threeColumnInput}>
                <NumberInput
                  value={fingerConfiguration.offsetInMeters * 1000}
                  step={0.01}
                  suffix="mm"
                  onChange={updateOffset}
                  disabled={isFormDisabled}
                >
                  <InputLabel>Fingertip offset</InputLabel>
                </NumberInput>
              </div>
              <Dropdown
                isOpen={isOffsetPresetDropdownOpen}
                onClose={() => setIsOffsetPresetDropdownOpen(false)}
                content={
                  <>
                    <MenuItem hasDivider onClick={() => updateOffset(10)}>
                      10mm steel
                    </MenuItem>
                    <MenuItem hasDivider onClick={() => updateOffset(13)}>
                      13mm steel
                    </MenuItem>
                    <MenuItem hasDivider onClick={() => updateOffset(13.5)}>
                      13.5mm silicon
                    </MenuItem>
                    <MenuItem hasDivider onClick={() => updateOffset(16.5)}>
                      16.5mm silicon
                    </MenuItem>
                  </>
                }
              >
                <Button
                  variant="gray"
                  className={cx(
                    styles.inputHeightButton,
                    margin.left.extraSmall,
                  )}
                  onClick={() => setIsOffsetPresetDropdownOpen(true)}
                  disabled={isFormDisabled}
                >
                  <SettingsIcon size="small" />
                  <ArrowDownIcon
                    size="extraSmall"
                    className={margin.left.small}
                  />
                </Button>
              </Dropdown>
            </Layout.Flex>
          </Layout.Flex>
        </Layout.FlexChild>
      </Layout.Flex>
    </Layout.Flex>
  );
}

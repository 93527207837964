import { useRouter } from 'next/router';
import { useState } from 'react';

import type { Robot } from '@sb/types';
import { Dropdown, MenuItem } from '@sb/ui/components';
import {
  AccessoriesIcon,
  BoxIcon,
  ControlPanelIcon,
  EditIcon,
  ForwardIcon,
  IOIcon,
  RoutineIcon,
  SaveIcon,
  ShieldIcon,
  SyncIcon,
  TeamIcon,
  VisualizerIcon,
  WarningIcon,
} from '@sb/ui/icons';
import type { RobotBadgeStatus } from '@sbrc/hooks';
import { useFeatureFlag, useRobotUnbrake } from '@sbrc/hooks';
import { getLinkToRobotScenePage, getLinkToRoutinePage } from '@sbrc/utils';

import { DataBackupModal } from '../data-backup/DataBackupModal';
import { DeveloperAPIModal } from '../developer-api/DeveloperAPIModal';
import { EquipmentManagerModal } from '../equipment-manager';
import { IOManagerModal } from '../io-manager';
import RobotFormModal from '../robot-form/RobotFormModal';
import BoxRobotModal from '../robots-list/BoxRobotModal';
import SafetySettingsModal from '../safety-settings/SafetySettingsModal';
import type { SoftwareBuildData } from '../software-update';
import { SoftwareUpdateModal } from '../software-update';

import { DisconnectedCard } from './DisconnectedCard';
import { SpeedCard } from './SpeedCard';

import styles from './RobotBadgeMenu.module.css';

export type RobotDropdownModalKind =
  | 'box'
  | 'io'
  | 'move'
  | 'pin'
  | 'renameRobot'
  | 'safetySettings'
  | 'dataBackups'
  | 'settings'
  | 'software'
  | 'developerAPI'
  | 'equipmentManager'
  | null;

interface RobotBadgeMenuProps {
  robot: Robot.ConvertedResponse;
  robotBadgeStatus: RobotBadgeStatus;
  buildData: SoftwareBuildData;
}

export default function RobotBadgeMenu({
  robot,
  robotBadgeStatus,
  buildData,
}: RobotBadgeMenuProps) {
  const { push } = useRouter();
  const enableDeveloperAPI = useFeatureFlag('enableDeveloperAPI');

  const { brakeRobot } = useRobotUnbrake({ robotID: robot.id });

  const [modal, setModal] = useState<RobotDropdownModalKind>(null);

  const [isSettingsOpen, setIsSettingsOpen] = useState<boolean>(false);

  const closeModal = () => setModal(null);

  const { id, latestRoutine } = robot;

  return (
    <>
      <div className={styles.robotBadgeMenu}>
        {robotBadgeStatus.kind === 'disconnected' && <DisconnectedCard />}

        {robotBadgeStatus.kind !== 'disconnected' && (
          <SpeedCard robot={robot} />
        )}

        <MenuItem
          hasDivider
          onClick={() => setModal('equipmentManager')}
          startIcon={<AccessoriesIcon />}
        >
          Equipment
        </MenuItem>

        <MenuItem
          hasDivider
          onClick={() => setModal('io')}
          startIcon={<IOIcon />}
          disabled={!robotBadgeStatus.isConnected}
        >
          I/O Manager
        </MenuItem>

        <MenuItem
          hasDivider
          disabled={!latestRoutine}
          onClick={() =>
            push(
              `${getLinkToRoutinePage(
                String(latestRoutine?.id),
              )}?robotID=${id}`,
            )
          }
          startIcon={<RoutineIcon />}
        >
          Edit Routine
        </MenuItem>

        <MenuItem
          hasDivider
          disabled={!latestRoutine}
          onClick={() => push(getLinkToRobotScenePage(id))}
          startIcon={<VisualizerIcon />}
        >
          Play Routine in Visualizer
        </MenuItem>

        <Dropdown
          isOpen={isSettingsOpen}
          onClose={() => setIsSettingsOpen(false)}
          placement="right-start"
          offset={0}
          content={
            <div>
              <MenuItem
                onClick={() => setModal('safetySettings')}
                startIcon={<ShieldIcon />}
                hasDivider
              >
                Safety
              </MenuItem>

              <MenuItem
                onClick={() => setModal('dataBackups')}
                startIcon={<SaveIcon />}
                hasDivider
              >
                Backups
              </MenuItem>

              <MenuItem
                onClick={() => setModal('software')}
                startIcon={<SyncIcon />}
                endIcon={
                  buildData.isSoftwareUpdateAvailable ? (
                    <div className={styles.softwareUpdateIndicator} />
                  ) : undefined
                }
                hasDivider
              >
                Software Update
              </MenuItem>

              <MenuItem
                hasDivider
                onClick={() => setModal('renameRobot')}
                startIcon={<EditIcon />}
              >
                Robot Name
              </MenuItem>

              <MenuItem
                hasDivider
                onClick={() => setModal('box')}
                startIcon={<BoxIcon />}
                disabled={!robotBadgeStatus.isConnected}
              >
                Box Robot
              </MenuItem>

              {enableDeveloperAPI && (
                <MenuItem
                  onClick={() => setModal('developerAPI')}
                  startIcon={<TeamIcon />}
                  hasDivider
                >
                  Configure Developer API
                </MenuItem>
              )}
            </div>
          }
        >
          <MenuItem
            hasDivider
            startIcon={<ControlPanelIcon />}
            endIcon={
              <div className={styles.settingsEndIconContainer}>
                {buildData.isSoftwareUpdateAvailable && (
                  <div className={styles.softwareUpdateIndicator} />
                )}

                <ForwardIcon fontSize="inherit" />
              </div>
            }
            onClick={(e) => {
              setIsSettingsOpen(true);
              e.stopPropagation();
            }}
          >
            Settings
          </MenuItem>
        </Dropdown>

        {process.env.NODE_ENV === 'development' && (
          <MenuItem
            disabled={!robotBadgeStatus.isConnected}
            onClick={brakeRobot}
            variant="alertPrimary"
            startIcon={<WarningIcon />}
            hasDivider
          >
            Brake Robot (Simulate Failure)
          </MenuItem>
        )}
      </div>

      {modal === 'io' && (
        <IOManagerModal
          isOpen={modal === 'io'}
          onClose={closeModal}
          onOpenSafetySettings={() => setModal('safetySettings')}
          robot={robot}
        />
      )}

      {modal === 'equipmentManager' && (
        <EquipmentManagerModal
          isOpen={modal === 'equipmentManager'}
          onClose={closeModal}
          robot={robot}
        />
      )}

      <RobotFormModal
        isOpen={modal === 'renameRobot'}
        onClose={closeModal}
        robot={robot}
      />

      <BoxRobotModal
        isOpen={modal === 'box'}
        onClose={closeModal}
        robot={robot}
      />

      <SafetySettingsModal
        isOpen={modal === 'safetySettings'}
        onClose={closeModal}
        robot={robot}
      />

      <DataBackupModal
        isOpen={modal === 'dataBackups'}
        robotID={robot.id}
        onClose={closeModal}
      />

      {modal === 'software' && (
        <SoftwareUpdateModal
          isOpen={modal === 'software'}
          onClose={closeModal}
          buildData={buildData}
        />
      )}

      <DeveloperAPIModal
        isOpen={modal === 'developerAPI'}
        onClose={closeModal}
        robot={robot}
      />
    </>
  );
}

import { useState } from 'react';

import { Button, Popover, TabItem, Tabs, Typography } from '@sb/ui/components';
import { WarningIcon } from '@sb/ui/icons';
import { useFeatureFlag, useToast } from '@sbrc/hooks';

import UnsavedChangesAlert from '../../UnsavedChangesAlert';
import {
  useFormError,
  useSafetySettingsStore,
  useUpdateSettings,
} from '../store';

import { CollisionsTab } from './collisions-tab/CollisionsTab';
import { ConfirmSaveAlert } from './ConfirmSaveAlert';
import { IOTab } from './io-tab/IOTab';
import { LimitsTab } from './limits-tab/LimitsTab';

import styles from './SettingsForm.module.css';

interface SettingsFormProps {
  onClose: () => void;
}

type ConfirmModalKind = null | 'save' | 'discard';

export function SettingsForm({ onClose }: SettingsFormProps) {
  const updateSettings = useUpdateSettings();
  const { setToast } = useToast();
  const [confirmModal, setConfirmModal] = useState<ConfirmModalKind>(null);

  const { updatedAt, hasUnsavedChanges, lockState, setLockState } =
    useSafetySettingsStore(
      'updatedAt',
      'hasUnsavedChanges',
      'lockState',
      'setLockState',
    );

  const formError = useFormError();

  const isSafetyIOEnabled = useFeatureFlag('safetyIO');

  const handleDiscard = () => {
    if (hasUnsavedChanges) {
      setConfirmModal('discard');
    } else {
      onClose();
    }
  };

  const handleSave = async () => {
    if (lockState === 'unlocked') {
      try {
        setLockState('locked');
        await updateSettings();
        setConfirmModal(null);
      } catch {
        setLockState('unlocked');
        setToast({ kind: 'error', message: 'Safety settings update failed' });
      }
    }
  };

  const handleSubmit: React.FormEventHandler = (event) => {
    event.preventDefault();
    setConfirmModal('save');
  };

  return (
    <>
      <form className={styles.form} onSubmit={handleSubmit}>
        <Tabs
          tabListClassName={styles.tabList}
          tabPanelContainerClassName={styles.tabPanelContainer}
        >
          <TabItem id="limits" label="Limits" panel={<LimitsTab />} />
          {isSafetyIOEnabled && (
            <TabItem id="safetyIO" label="I/O" panel={<IOTab />} />
          )}
          <TabItem
            id="collisions"
            label="Collisions"
            panel={<CollisionsTab />}
          />
        </Tabs>

        <div className={styles.actionButtonBar}>
          <Typography className={styles.updatedAt} color="gray">
            {updatedAt && `Last Updated: ${updatedAt.toLocaleString()}`}
          </Typography>
          <Button
            type="button"
            variant="gray"
            size="small"
            onClick={handleDiscard}
          >
            Cancel
          </Button>
          {formError && lockState === 'unlocked' && (
            <Popover
              content={<Typography textAlign="left">{formError}</Typography>}
            >
              <WarningIcon
                size="extraSmall"
                className={styles.hasErrorsPopover}
              />
            </Popover>
          )}
          <Button
            type="submit"
            variant="primary"
            size="small"
            disabled={
              lockState !== 'unlocked' ||
              formError !== null ||
              !hasUnsavedChanges
            }
          >
            Apply Settings
          </Button>
        </div>
      </form>

      <UnsavedChangesAlert
        isOpen={confirmModal === 'discard'}
        onDiscard={onClose}
        onKeepEditing={() => setConfirmModal(null)}
      />

      <ConfirmSaveAlert
        isOpen={confirmModal === 'save'}
        onConfirm={handleSave}
        onCancel={() => setConfirmModal(null)}
      />
    </>
  );
}

import {
  ABSOLUTE_MAX_JOINT_ACCELERATIONS,
  ABSOLUTE_MAX_JOINT_SPEEDS,
  ABSOLUTE_MAX_JOINT_TORQUES,
  COLLABORATIVE_MAX_JOINT_ACCELERATIONS,
  COLLABORATIVE_MAX_JOINT_SPEEDS,
  COLLABORATIVE_MAX_JOINT_TORQUES,
  DEFAULT_MAX_JOINT_ACCELERATIONS,
  DEFAULT_MAX_JOINT_SPEEDS,
  DEFAULT_MAX_JOINT_TORQUES,
} from '@sb/motion-planning';
import { Typography } from '@sb/ui/components';
import { JOINT_NAMES } from '@sbrc/utils';

import { LimitField } from './LimitField';
import { LimitsSectionHeader } from './LimitsSectionHeader';

const SPEED_STEP = 0.1;
const ACCELERATION_STEP = 0.1;
const TORQUE_STEP = 1;

interface JointLimitsProps {
  jointNumber: number;
}

export function JointLimits({ jointNumber }: JointLimitsProps) {
  return (
    <>
      <LimitsSectionHeader title={JOINT_NAMES[jointNumber]} />

      <Typography variant="medium">Max Velocity</Typography>
      <LimitField
        fieldName={`maxJointSpeeds[${jointNumber}]`}
        slowFieldName={`slowJointSpeeds[${jointNumber}]`}
        suffix="rad/s"
        defaultValue={DEFAULT_MAX_JOINT_SPEEDS[jointNumber]}
        minValue={SPEED_STEP}
        maxValue={ABSOLUTE_MAX_JOINT_SPEEDS[jointNumber]}
        collaborativeValue={COLLABORATIVE_MAX_JOINT_SPEEDS[jointNumber]}
        step={SPEED_STEP}
        isSpeedLimitField
      />

      <Typography variant="medium">Max Acceleration</Typography>
      <LimitField
        fieldName={`maxJointAccelerations[${jointNumber}]`}
        slowFieldName={`slowJointAccelerations[${jointNumber}]`}
        suffix="rad/s²"
        defaultValue={DEFAULT_MAX_JOINT_ACCELERATIONS[jointNumber]}
        minValue={ACCELERATION_STEP}
        maxValue={ABSOLUTE_MAX_JOINT_ACCELERATIONS[jointNumber]}
        collaborativeValue={COLLABORATIVE_MAX_JOINT_ACCELERATIONS[jointNumber]}
        step={ACCELERATION_STEP}
      />

      <Typography variant="medium">Max Torque</Typography>
      <LimitField
        fieldName={`maxJointTorques[${jointNumber}]`}
        slowFieldName={`slowJointTorques[${jointNumber}]`}
        suffix="N⋅m"
        defaultValue={DEFAULT_MAX_JOINT_TORQUES[jointNumber]}
        minValue={TORQUE_STEP}
        maxValue={ABSOLUTE_MAX_JOINT_TORQUES[jointNumber]}
        collaborativeValue={COLLABORATIVE_MAX_JOINT_TORQUES[jointNumber]}
        step={TORQUE_STEP}
      />
    </>
  );
}

import GripKind from './GripKind';
import GripperDiameter from './GripperDiameter';
import GripperForce from './GripperForce';
import GripperPayload from './GripperPayload';
import type { SingleValueModeProps } from './types';

export function GripperControlSingleValueMode(props: SingleValueModeProps) {
  return (
    <>
      <GripperPayload {...props} />
      <GripKind {...props} />
      <GripperForce {...props} />
      <GripperDiameter {...props} />
    </>
  );
}

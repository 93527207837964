import type { RangeSliderInputProps } from '@sb/ui/components';
import { RangeSliderInput } from '@sb/ui/components';
import { countDecimalPlaces } from '@sb/utilities';
import { useDistanceUnitInfo } from '@sbrc/hooks';

import { convertMaxMinStepProps } from './convertMaxMinStepProps';

/**
 * Component for entering linear velocity,
 * will convert value from m/s to the user's preference for display/input.
 *
 * NOTE the onChange handler must read the input value from `event.target.valueAsNumber`
 */
export function VelocityRangeSliderInput({
  onChange,
  value,
  ...rest
}: RangeSliderInputProps) {
  const distanceUnitInfo = useDistanceUnitInfo();

  const maxMinStepProps = convertMaxMinStepProps(rest, {
    convertValue: distanceUnitInfo.fromMeters,
    isSlider: true,
  });

  const decimalPlaces = maxMinStepProps.step
    ? countDecimalPlaces(maxMinStepProps.step)
    : distanceUnitInfo.decimalPlaces;

  return (
    <RangeSliderInput
      {...rest}
      {...maxMinStepProps}
      decimalPlaces={decimalPlaces}
      onChange={
        onChange &&
        ((e) =>
          onChange({
            ...e,
            target: {
              ...e.target,
              valueAsNumber: distanceUnitInfo.toMeters(e.target.valueAsNumber),
            },
          }))
      }
      numberContext={`${distanceUnitInfo.abbreviation}/s`}
      value={distanceUnitInfo.fromMeters(value)}
    />
  );
}

import { Alert, AlertActions, AlertTitle, Button } from '@sb/ui/components';

interface UnsavedChangesAlertProps {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

export function ConfirmSaveAlert({
  isOpen,
  onConfirm,
  onCancel,
}: UnsavedChangesAlertProps) {
  return (
    <Alert isOpen={isOpen}>
      <AlertTitle>
        Are you sure you want to update the safety settings on the robot?
      </AlertTitle>
      <AlertActions>
        <Button onClick={onCancel} variant="gray">
          Cancel
        </Button>
        <Button onClick={onConfirm} variant="primary">
          Confirm
        </Button>
      </AlertActions>
    </Alert>
  );
}

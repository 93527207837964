import { useEffect } from 'react';

import { createDualValueModeStore } from '@sb/integrations/gripper-general/shared-components/createDualValueModeStore';
import type { Integrations } from '@sb/types';
import { Loader, Button } from '@sb/ui/components';
import { DownloadIcon, ResetAllIcon, SwitcherIcon } from '@sb/ui/icons';
import {
  WidgetView,
  sharedStyles,
} from '@sbrc/components/visualizer-view-shared/widget-panel';

import type { OnRobot3FG15State } from '../State';
import { areOR3FG15GripperPositionsEqual } from '../utils';

import { GripperControlDualValueMode } from './dual-value-mode/GripperControlDualValueMode';
import { useGripperControlState } from './gripper-control-state';
import { GripperControlSingleValueMode } from './single-value-mode/GripperControlSingleValueMode';

const useDualValueMode = createDualValueModeStore();

interface OnRobot3FG15WidgetInnerProps {
  routineRunnerPayload: number;
  routineRunnerGripperState: OnRobot3FG15State;
  otherRobotGripperState: OnRobot3FG15State | null;
  args: Integrations.WidgetProps;
}

export function OnRobot3FG15WidgetInner({
  routineRunnerPayload,
  routineRunnerGripperState,
  otherRobotGripperState,
  args,
}: OnRobot3FG15WidgetInnerProps) {
  const {
    robot,
    isVizbot,
    onCommandChange = () => {},
    onClose,
    implementation,
  } = args;

  const routineRunnerArgs = {
    robotID: robot.id,
    isVizbot,
  };

  const gripperControlState = useGripperControlState({
    ...routineRunnerArgs,
    routineRunnerGripperState,
    routineRunnerPayload,
  });

  const {
    canApplyGripperChanges,
    changeTargetDiameter,
    changeGripKind,
    conformGripperControlStateToActualState,
    targetDiameterMeters,
    command,
  } = gripperControlState;

  useEffect(() => {
    onCommandChange(command);
  }, [command, onCommandChange]);

  const areGripperPositionsEqual = areOR3FG15GripperPositionsEqual(
    otherRobotGripperState,
    targetDiameterMeters,
    command,
  );

  const { isDualValueMode, toggleDualValueMode } = useDualValueMode();

  const buttonText = isVizbot ? 'Live Robot' : 'Visualizer';

  return (
    <WidgetView
      onClose={onClose}
      headerTitle={implementation.getDisplayName()}
      testPrefix="onrobot-3fg15-control"
      headerIcon={<args.implementation.InlineIcon />}
      headerButton={
        <>
          <Button
            className={sharedStyles.headerButtonText}
            startIcon={<DownloadIcon className={sharedStyles.buttonIcon} />}
            label="Import gripper state"
            size="extraSmall"
            variant="gray"
            onClick={() => {
              if (otherRobotGripperState) {
                changeGripKind(otherRobotGripperState?.gripKind);
                changeTargetDiameter(otherRobotGripperState?.diameterMeters);
              }
            }}
            disabled={!isDualValueMode || areGripperPositionsEqual}
          >
            {buttonText}
          </Button>

          <Button
            startIcon={<ResetAllIcon className={sharedStyles.buttonIcon} />}
            label="Reset to current gripper state"
            size="extraSmall"
            variant="gray"
            onClick={() => {
              if (routineRunnerGripperState) {
                conformGripperControlStateToActualState(
                  routineRunnerGripperState,
                );
              }
            }}
            disabled={!isDualValueMode || !canApplyGripperChanges}
            data-testid="gripper-widget-reset-current-gripper-state-button"
          />

          <Button
            startIcon={<SwitcherIcon className={sharedStyles.buttonIcon} />}
            label="Switch gripper control mode"
            size="extraSmall"
            onClick={toggleDualValueMode}
            variant={isDualValueMode ? 'primary' : 'gray'}
            data-testid="move-robot-control-widgets-tool-switch-control-mode-button"
          />
        </>
      }
    >
      {routineRunnerGripperState ? (
        <div className={sharedStyles.controls}>
          {isDualValueMode ? (
            <GripperControlDualValueMode
              gripperControlState={gripperControlState}
              routineRunnerArgs={routineRunnerArgs}
            />
          ) : (
            <GripperControlSingleValueMode
              gripperState={routineRunnerGripperState}
              routineRunnerArgs={routineRunnerArgs}
            />
          )}
        </div>
      ) : (
        <div className={sharedStyles.loaderContainer}>
          <Loader />
        </div>
      )}
    </WidgetView>
  );
}

/* eslint-disable react/destructuring-assignment */

import GripperErrorWidget from '@sb/integrations/gripper-general/shared-components/GripperError';
import NoGripperWidget from '@sb/integrations/gripper-general/shared-components/NoGripperWidget';
import type { Integrations } from '@sb/types';
import SectionContainer from '@sbrc/components/visualizer-view-shared/widget-panel/SectionContainer';
import { useRobotGripperState } from '@sbrc/hooks';

import { DualOnRobot2FG7Widget } from './DualOnRobot2FG7Widget';
import { DualOnRobot3FG15Widget } from './DualOnRobot3FG15Widget';
import { GripperSelect } from './GripperSelect';
import { useGripperControlState } from './useGripperControlState';

export function OnRobotDualQuickChangerWidget(args: Integrations.WidgetProps) {
  const {
    command: dualChangerCommand,
    changeActiveGripper,
    changeActiveGripperCommand,
  } = useGripperControlState({
    active:
      args.defaultCommand?.kind === 'OnRobotDualQuickChangerCommand'
        ? args.defaultCommand.active
        : undefined,
  });

  let dualChangerPrimary;
  let dualChangerSecondary;

  if (args.endEffector.kind === 'OnRobotDualQuickChanger') {
    dualChangerPrimary = args.endEffector.grippers.primary.kind;

    dualChangerSecondary = args.endEffector.grippers.secondary.kind;
  }

  const selectedGripper =
    dualChangerCommand.active === 'primary'
      ? dualChangerPrimary
      : dualChangerSecondary;

  const gripperDropdown = (
    <SectionContainer title="Choose Gripper">
      <GripperSelect
        onGripperChange={changeActiveGripper}
        selectedGripper={dualChangerCommand.active}
        getEquipmentByKind={args.getEquipmentByKind}
      />
    </SectionContainer>
  );

  const routineRunnerArgs = {
    robotID: args.robot.id,
    isVizbot: args.isVizbot,
  };

  const routineRunnerDualChangerState = useRobotGripperState(
    routineRunnerArgs,
    'OnRobotDualQuickChanger',
  );

  const otherRobotRoutineRunnerArgs = {
    robotID: args.robot.id,
    isVizbot: !args.isVizbot,
  };

  const otherRobotDualChangerState = useRobotGripperState(
    otherRobotRoutineRunnerArgs,
    'OnRobotDualQuickChanger',
  );

  if (!routineRunnerDualChangerState) {
    return (
      <GripperErrorWidget
        implementation={args.implementation}
        onClose={args.onClose}
        message="The gripper does not appear to be connected. Please ensure a Dual Quick Changer is properly mounted and connected."
      />
    );
  }

  if (selectedGripper === 'OnRobot3FG15') {
    return (
      <DualOnRobot3FG15Widget
        {...args}
        routineRunnerDualChangerState={routineRunnerDualChangerState}
        otherRobotDualChangerState={otherRobotDualChangerState}
        additionalControls={gripperDropdown}
        dualChangerCommand={dualChangerCommand}
        changeActiveGripperCommand={changeActiveGripperCommand}
      />
    );
  }

  if (selectedGripper === 'OnRobot2FG7') {
    return (
      <DualOnRobot2FG7Widget
        {...args}
        routineRunnerDualChangerState={routineRunnerDualChangerState}
        otherRobotDualChangerState={otherRobotDualChangerState}
        additionalControls={gripperDropdown}
        dualChangerCommand={dualChangerCommand}
        changeActiveGripperCommand={changeActiveGripperCommand}
      />
    );
  }

  return (
    <NoGripperWidget
      onClose={args.onClose}
      additionalControls={gripperDropdown}
    />
  );
}

import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function OutwardMountingPosition(props: IconProps) {
  return (
    <Icon viewBox="0 0 14 12" {...props}>
      <path
        fillRule="evenodd"
        d="M12.5 0H1.5C0.671573 0 0 0.671573 0 1.5C0 2.15441 0.419071 2.71095 1.00349 2.91588C1.00118 2.94361 1 2.97167 1 3V11.25C1 11.6642 1.33579 12 1.75 12C2.16421 12 2.5 11.6642 2.5 11.25V6H5C5.55228 6 6 5.55228 6 5V3H8V5C8 5.55228 8.44771 6 9 6H11.5V11.25C11.5 11.6642 11.8358 12 12.25 12C12.6642 12 13 11.6642 13 11.25V3C13 2.97167 12.9988 2.94361 12.9965 2.91588C13.5809 2.71095 14 2.15441 14 1.5C14 0.671572 13.3284 0 12.5 0ZM12 3H9V5H12V3ZM12.5 2C12.7761 2 13 1.77614 13 1.5C13 1.22386 12.7761 1 12.5 1H1.5C1.22386 1 1 1.22386 1 1.5C1 1.77614 1.22386 2 1.5 2H12.5ZM5 3H2V5H5V3Z"
      />
    </Icon>
  );
}

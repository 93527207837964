import type { Integrations } from '@sb/types';
import { Button } from '@sb/ui/components';
import { AddIcon } from '@sb/ui/icons';

import { PortDropdown } from './PortDropdown';

interface AddPortButtonProps {
  onAdd: (port: Integrations.IOPort) => void;
  label: string;
}

export function AddPortButton({ onAdd, label }: AddPortButtonProps) {
  return (
    <PortDropdown onSelect={onAdd}>
      {(onOpen) => (
        <Button startIcon={<AddIcon />} variant="secondary" onClick={onOpen}>
          {label}
        </Button>
      )}
    </PortDropdown>
  );
}

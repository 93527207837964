import React from 'react';

import type { SafetySettingsPreset } from '@sb/feathers-types';
import type {
  ArmJointAccelerations,
  ArmJointVelocities,
} from '@sb/motion-planning';
import {
  ABSOLUTE_MAX_JOINT_SPEEDS,
  ABSOLUTE_MAX_JOINT_ACCELERATIONS,
  ABSOLUTE_MAX_TOOLTIP_SPEED,
} from '@sb/motion-planning';
import { six } from '@sb/utilities';

export interface FactoryPresetInfo {
  factoryPreset: SafetySettingsPreset;
  label: string;
  description: React.ReactNode;
  maxTooltipSpeed: number;
  slowTooltipSpeed: number;
  maxJointSpeeds?: ArmJointVelocities;
  maxJointAccelerations?: ArmJointAccelerations;
}

export const FACTORY_PRESET_INFOS: FactoryPresetInfo[] = [
  {
    factoryPreset: 'safest',
    label: 'Safest',
    description:
      'Best for fully collaborative settings where humans may not be aware of the robot or the robot’s tools or payload poses extreme hazard.',
    maxTooltipSpeed: 0.25,
    slowTooltipSpeed: 0.25,
  },
  {
    factoryPreset: 'safer',
    label: 'Safer',
    description:
      'Best for fully collaborative settings where humans may not be aware of the robot and its payload.',
    maxTooltipSpeed: 0.5,
    slowTooltipSpeed: 0.25,
  },
  {
    factoryPreset: 'default',
    label: 'Default',
    description:
      'Best for collaborative or hybrid settings where humans are trained and aware of the robot and the environment is free of sharp or dangerous objects.',
    /* NB: if default settings change, also change initializeSafetySettings.ts */
    maxTooltipSpeed: 0.75,
    slowTooltipSpeed: 0.5,
  },
  {
    factoryPreset: 'faster',
    label: 'Faster',
    description: (
      <>
        Best for <strong>non-collaborative settings</strong> where humans are
        separated from the robot’s working space.
      </>
    ),
    maxTooltipSpeed: 1.25,
    slowTooltipSpeed: 0.75,
    maxJointSpeeds: ABSOLUTE_MAX_JOINT_SPEEDS,
    maxJointAccelerations: six(2),
  },
  {
    factoryPreset: 'fastest',
    label: 'Fastest',
    description: (
      <>
        Robot operates at maximum achievable speed; only for{' '}
        <strong>non-collaborative settings</strong> where humans are protected
        by <strong>multiple, redundant safeguards</strong>.
      </>
    ),
    maxTooltipSpeed: ABSOLUTE_MAX_TOOLTIP_SPEED,
    slowTooltipSpeed: 0.75,
    maxJointSpeeds: ABSOLUTE_MAX_JOINT_SPEEDS,
    maxJointAccelerations: ABSOLUTE_MAX_JOINT_ACCELERATIONS,
  },
];

export function getFactoryPresetForTooltipSpeed(
  maxTooltipSpeed: number | null,
): FactoryPresetInfo {
  for (const info of FACTORY_PRESET_INFOS) {
    if (maxTooltipSpeed === null) {
      if (info.factoryPreset === 'default') {
        return info;
      }
    } else if (maxTooltipSpeed <= info.maxTooltipSpeed) {
      return info;
    }
  }

  return FACTORY_PRESET_INFOS[FACTORY_PRESET_INFOS.length - 1];
}

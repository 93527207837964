import cx from 'classnames';
import { useMemo, cloneElement } from 'react';

import { Typography } from '@sb/ui/components';
import { CloseIcon } from '@sb/ui/icons';
import { margin } from '@sb/ui/styles';

import styles from './WidgetView.module.css';

interface WidgetViewProps {
  children: React.ReactNode;
  className?: string;
  headerClassName?: string;
  hasNoBorder?: boolean;
  headerButton?: React.ReactNode;
  headerIcon?: React.ReactElement;
  headerTitle?: string;
  isScrollable?: boolean;
  onClose: () => void;
  testPrefix?: string;
}

const WidgetView = ({
  children,
  className,
  headerClassName,
  hasNoBorder = false,
  headerButton,
  headerIcon,
  headerTitle,
  isScrollable,
  onClose,
  testPrefix,
}: WidgetViewProps) => {
  const HeaderIconComponent = useMemo(() => {
    return (
      headerIcon &&
      cloneElement(headerIcon, {
        className: styles.headerIcon,
      })
    );
  }, [headerIcon]);

  return (
    <div className={cx(styles.widgetView, className)}>
      {headerTitle && (
        <div
          className={cx(
            styles.header,
            { [styles.noBorder]: hasNoBorder },
            headerClassName,
          )}
        >
          {HeaderIconComponent}

          <Typography
            className={cx(styles.headerTitle, margin.bottom.extraSmall)}
            role="heading"
            hasNoWrap
            testPrefix={testPrefix ? `${testPrefix}-widget` : 'widget'}
          >
            {headerTitle}
          </Typography>

          <div className={styles.headerButtonContainer}>{headerButton}</div>

          <CloseIcon
            role="button"
            data-testid="widget-panel-close-button"
            className={styles.closeIcon}
            onClick={onClose}
          />
        </div>
      )}

      <div className={cx({ [styles.scrollable]: isScrollable })}>
        {children}
      </div>
    </div>
  );
};

export default WidgetView;

import { Badge } from '@sb/ui/components';
import { SpeedIcon } from '@sb/ui/icons';

interface SpeedBadgeProps {
  hidden?: boolean;
  speedRestrictionPercentage: number;
  isSlowSpeed: boolean;
}

export function SpeedBadge({
  hidden,
  speedRestrictionPercentage,
  isSlowSpeed,
}: SpeedBadgeProps) {
  if (hidden) {
    return null;
  }

  const content: string[] = [];

  if (isSlowSpeed) {
    content.push('Slow');
  }

  if (speedRestrictionPercentage < 1) {
    content.push(`${Math.round(speedRestrictionPercentage * 100)}%`);
  }

  if (content.length === 0) {
    return null;
  }

  return (
    <Badge
      data-testid="robot-speed-badge"
      variant="black"
      startIcon={<SpeedIcon />}
    >
      {content.join(' - ')}
    </Badge>
  );
}

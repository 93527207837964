import type { Robot } from '@sb/types';
import { Typography, Button, Layout } from '@sb/ui/components';
import { useCopy } from '@sb/ui/hooks';
import { SyncIcon, DuplicateIcon, CompletedIcon } from '@sb/ui/icons';
import { margin } from '@sb/ui/styles';
import { useDevToken } from '@sbrc/services';

import styles from './DeveloperAPI.module.css';

type Props = {
  robot: Robot.ConvertedResponse;
};

export const DeveloperAPIModalBody = (props: Props) => {
  const { robot } = props;
  const [copyElURL, copyStringURL, blinkingURL] = useCopy();
  const [copyElToken, copyStringToken, blinkingToken] = useCopy();
  const { token, regenerate, clearToken, loaded } = useDevToken();
  const enabled = token !== null;
  const apiURL = `http://${robot.lastKnownLocalIP ?? 'localhost'}:3000`;

  return (
    <div>
      {copyElURL}
      {copyElToken}
      <Typography className={margin.bottom.small}>
        Extend your Routines with the Standard Bots API.
      </Typography>
      {loaded && !enabled && (
        <Button
          onClick={() => {
            regenerate();
          }}
        >
          Enable Developer API
        </Button>
      )}
      {loaded && enabled && (
        <div>
          <Typography component="h5">Endpoint URL</Typography>
          <Layout.Flex direction="horizontal" className={styles.informationRow}>
            <Layout.FlexChild grow={1} shrink={1}>
              <Typography color="gray">{apiURL}</Typography>
            </Layout.FlexChild>
            <Layout.FlexChild grow={0} shrink={0}>
              <Button
                variant="link"
                startIcon={blinkingURL ? <CompletedIcon /> : <DuplicateIcon />}
                onClick={() => copyStringURL(apiURL)}
              >
                {blinkingURL ? 'Copied' : 'Copy'}
              </Button>
            </Layout.FlexChild>
          </Layout.Flex>
          <Typography component="h5" className={margin.top.small}>
            Authorization Token
          </Typography>
          <Layout.Flex direction="horizontal" className={styles.informationRow}>
            <Layout.FlexChild grow={1} shrink={1}>
              <Typography color="gray">{token}</Typography>
            </Layout.FlexChild>
            <Layout.FlexChild grow={0} shrink={0}>
              <Button
                variant="link"
                onClick={() => regenerate()}
                startIcon={<SyncIcon />}
              >
                Regenerate
              </Button>
            </Layout.FlexChild>
            <Layout.FlexChild grow={0} shrink={0}>
              <Button
                variant="link"
                startIcon={
                  blinkingToken ? <CompletedIcon /> : <DuplicateIcon />
                }
                onClick={() => copyStringToken(token ?? '')}
              >
                {blinkingToken ? 'Copied' : 'Copy'}
              </Button>
            </Layout.FlexChild>
          </Layout.Flex>
          <Button
            className={margin.top.small}
            variant="alertPrimary"
            onClick={() => {
              clearToken();
            }}
          >
            Disable Developer API
          </Button>
        </div>
      )}
    </div>
  );
};

import cx from 'classnames';

import { CloseIcon, DriveStorageIcon, TickIcon } from '@sb/ui/icons';

import styles from './DriveStatusIcon.module.css';

export type DriveStatusProps = {
  className?: string;
  status: 'creating' | 'restoring' | 'failed' | 'success' | undefined; // 'none' | 'success' | 'failure'
};

export const DriveStatusIcon = ({ className, status }: DriveStatusProps) => {
  return (
    <div className={cx(styles.iconContainer, className)}>
      <DriveStorageIcon size="large" />
      {status === 'success' && (
        <div className={cx(styles.statusBadge, styles.statusSuccess)}>
          <TickIcon size="extraSmall" />
        </div>
      )}
      {status === 'failed' && (
        <div className={cx(styles.statusBadge, styles.statusFailure)}>
          <CloseIcon size="extraSmall" />
        </div>
      )}
    </div>
  );
};

import { useState } from 'react';

import {
  Button,
  InputField,
  Modal,
  ModalContent,
  ModalHeader,
} from '@sb/ui/components';
import { margin } from '@sb/ui/styles';
import { useToast } from '@sbrc/hooks';
import { updatePIN } from '@sbrc/services';

interface RobotFormModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function UpdatePINModal({
  isOpen,
  onClose,
}: RobotFormModalProps) {
  const { setToast } = useToast();

  const [currentPIN, setCurrentPIN] = useState<string>('');
  const [currentPINError, setCurrentPINError] = useState<string | undefined>();
  const [newPIN, setNewPIN] = useState<string>('');
  const [newPINError, setNewPINError] = useState<string | undefined>();
  const [confirmPIN, setConfirmPIN] = useState<string>('');
  const [confirmPINError, setConfirmPINError] = useState<string | undefined>();

  const isSubmittable = Boolean(currentPIN && newPIN && confirmPIN);

  const validate = () => {
    let isValid = true;

    if (newPIN.match(/^\d{4,}$/)) {
      setNewPINError(undefined);
    } else if (newPIN.length >= 4) {
      setNewPINError(
        'PIN must be only numeric digits (no letters or special characters)',
      );

      isValid = false;
    } else {
      setNewPINError('PIN must be 4 or more digits');
      isValid = false;
    }

    if (newPIN === confirmPIN) {
      setConfirmPINError(undefined);
    } else {
      setConfirmPINError('Confirmed PIN must match new PIN');
      isValid = false;
    }

    setCurrentPINError(undefined);

    return isValid;
  };

  const handleOnClose = () => {
    setCurrentPIN('');
    setCurrentPINError(undefined);
    setNewPIN('');
    setNewPINError(undefined);
    setConfirmPIN('');
    setConfirmPINError(undefined);
    onClose();
  };

  const onSubmitForm = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!validate()) {
      return;
    }

    try {
      setToast({
        kind: 'progress',
        message: 'Updating PIN',
      });

      await updatePIN(currentPIN, newPIN);

      setToast({
        kind: 'success',
        message: 'PIN updated successfully',
      });

      handleOnClose();
    } catch (error) {
      setToast({ kind: 'error', message: 'Error updating PIN' });
      setCurrentPINError('PIN not accepted');
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={handleOnClose}>
      <ModalHeader title="Update PIN" />

      <ModalContent>
        <form onSubmit={onSubmitForm}>
          <InputField
            className={margin.bottom.medium}
            value={currentPIN}
            onChange={(e) => setCurrentPIN(e.target.value)}
            hasError={Boolean(currentPINError)}
            helperText={currentPINError}
            type="password"
            autoFocus
          >
            Current PIN
          </InputField>

          <InputField
            className={margin.bottom.medium}
            value={newPIN}
            onChange={(e) => setNewPIN(e.target.value)}
            hasError={Boolean(newPINError)}
            helperText={newPINError}
            type="password"
          >
            New PIN
          </InputField>

          <InputField
            className={margin.bottom.medium}
            value={confirmPIN}
            onChange={(e) => setConfirmPIN(e.target.value)}
            hasError={Boolean(confirmPINError)}
            helperText={confirmPINError}
            type="password"
          >
            Confirm New PIN
          </InputField>

          <div>
            <Button type="submit" disabled={!isSubmittable}>
              Update PIN
            </Button>
          </div>
        </form>
      </ModalContent>
    </Modal>
  );
}

import { Button } from '@sb/ui/components';
import { useGuidedMode, useRoutineRunnerHandle, useToast } from '@sbrc/hooks';
import type { UseRoutineRunnerHandleArguments } from '@sbrc/hooks';

import type { OnRobotScrewdriverCommand } from '../..';
import type { useGripperControlState } from '../useGripperControlState';

import styles from './CommandExecute.module.css';

interface CommandExecuteProps {
  routineRunnerArgs: UseRoutineRunnerHandleArguments;
  gripperControlState: ReturnType<typeof useGripperControlState>;
}

export function CommandExecute({
  routineRunnerArgs,
  gripperControlState,
}: CommandExecuteProps) {
  const { setToast } = useToast();

  const routineRunnerHandle = useRoutineRunnerHandle(routineRunnerArgs);

  const { runAdHocCommand, stopGuidedMode } = useGuidedMode({
    ...routineRunnerArgs,
    shouldStopBeforeRun: false,
  });

  const { setConformedState } = gripperControlState;

  const onHoldApplyChangesButton =
    (command: OnRobotScrewdriverCommand) => () => {
      runAdHocCommand({
        onRunCommand: async () => {
          const actuateGripper = await routineRunnerHandle.actuateDevice({
            command,
          });

          setConformedState('outdated');

          return actuateGripper;
        },

        onGuidedModeStop: async () => {},

        onError: (error) => {
          setToast({
            kind: 'error',
            message: `Error controlling screwdriver: ${error}`,
          });
        },
      });
    };

  return (
    <div className={styles.applyButtonContainer}>
      <Button
        variant="blackPrimary"
        className={styles.applyButton}
        isFullWidth
        onHold={onHoldApplyChangesButton({
          ...gripperControlState.command,
          commandKind: 'TIGHTEN',
        })}
        onRelease={stopGuidedMode}
        data-testid="gripper-widget-apply-changes-button"
      >
        Tighten
      </Button>
      <Button
        variant="blackPrimary"
        className={styles.applyButton}
        isFullWidth
        onHold={onHoldApplyChangesButton({
          ...gripperControlState.command,
          commandKind: 'LOOSEN',
        })}
        onRelease={stopGuidedMode}
        data-testid="gripper-widget-apply-changes-button"
      >
        Loosen
      </Button>
    </div>
  );
}

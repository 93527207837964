import { Button, ModalHeader } from '@sb/ui/components';
import { UnlockIcon } from '@sb/ui/icons';
import { display } from '@sb/ui/styles';

import styles from '../shared.module.css';
import { useSafetySettingsStore } from '../store';

import { SettingsForm } from './SettingsForm';

interface SettingsFormProps {
  onClose: () => void;
  isUnlockDisabled: boolean;
}

export function SettingsFormModal({
  onClose,
  isUnlockDisabled,
}: SettingsFormProps) {
  const { lockState, setLockState } = useSafetySettingsStore(
    'lockState',
    'setLockState',
  );

  // Hide (don't unmount) form when not showing, so the active tab is
  // retained when switching to the PIN entry and back
  const hidden = lockState !== 'locked' && lockState !== 'unlocked';

  return (
    <>
      {!hidden && (
        <ModalHeader
          title="Safety Settings"
          hideCloseIcon
          className={styles.modalHeader}
        >
          {lockState === 'locked' && (
            <Button
              type="button"
              variant="blackPrimary"
              size="small"
              startIcon={<UnlockIcon />}
              onClick={() => setLockState('enterPIN')}
              disabled={isUnlockDisabled}
            >
              Unlock
            </Button>
          )}
        </ModalHeader>
      )}

      <div className={hidden ? display.none : styles.modalContent}>
        <SettingsForm onClose={onClose} />
      </div>
    </>
  );
}

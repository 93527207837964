import { useRouter } from 'next/router';
import { useState } from 'react';

import { MenuItem } from '@sb/ui/components';
import { ControlPanelIcon, LogoutIcon, LockIcon } from '@sb/ui/icons';
import { USE_PIN_AUTHENTICATION } from '@sbrc/services';
import { LINK_TO_LOGOUT_PAGE } from '@sbrc/utils';

import UpdatePINModal from '../auth/UpdatePINModal';
import { UserSettingsModal } from '../user-settings/UserSettingsModal';

import styles from './AccountMenu.module.css';

type AccountMenuModalKind = null | 'pin' | 'settings';

const AccountMenu = () => {
  const { push } = useRouter();

  const [modal, setModal] = useState<AccountMenuModalKind>(null);

  return (
    <div className={styles.accountMenu}>
      <MenuItem
        onClick={() => setModal('settings')}
        startIcon={<ControlPanelIcon />}
      >
        User Settings
      </MenuItem>

      <UserSettingsModal
        isOpen={modal === 'settings'}
        onClose={() => setModal(null)}
      />

      {USE_PIN_AUTHENTICATION && (
        <>
          <MenuItem onClick={() => setModal('pin')} startIcon={<LockIcon />}>
            Update PIN
          </MenuItem>

          <UpdatePINModal
            isOpen={modal === 'pin'}
            onClose={() => setModal(null)}
          />
        </>
      )}

      <MenuItem
        onClick={() => {
          // dispatch an event that the iPad app can detect
          document.dispatchEvent(new Event('logout'));

          push(LINK_TO_LOGOUT_PAGE);
        }}
        startIcon={<LogoutIcon data-testid="logout-button" />}
        variant="alertPrimary"
        data-testid="logout-button"
      >
        Logout
      </MenuItem>
    </div>
  );
};

export default AccountMenu;

import { useEffect } from 'react';

import type { Integrations } from '@sb/types';
import {
  sharedStyles,
  WidgetView,
} from '@sbrc/components/visualizer-view-shared/widget-panel';

import type { OnRobotScrewdriverState } from '../State';

import GripperControlDualValueMode from './dual-value-mode/GripperControlDualValueMode';
import { useGripperControlState } from './useGripperControlState';

interface OnRobotScrewdriverWidgetInnerProps {
  routineRunnerGripperState: OnRobotScrewdriverState;
  args: Integrations.WidgetProps;
}

export function OnRobotScrewdriverWidgetInner({
  routineRunnerGripperState,
  args,
}: OnRobotScrewdriverWidgetInnerProps) {
  const {
    robot,
    isVizbot,
    onCommandChange = () => {},
    onClose,
    defaultCommand,
    implementation,
  } = args;

  const routineRunnerArgs = {
    robotID: robot.id,
    isVizbot,
  };

  const gripperControlState = useGripperControlState({
    ...routineRunnerArgs,
    routineRunnerGripperState,
    defaultCommand:
      defaultCommand?.kind === 'OnRobotScrewdriverCommand'
        ? defaultCommand
        : undefined,
  });

  const { command } = gripperControlState;

  useEffect(() => {
    onCommandChange(command);
  }, [command, onCommandChange]);

  return (
    <WidgetView
      isScrollable
      onClose={onClose}
      headerTitle={implementation.getDisplayName()}
      testPrefix="onrobot-screwdriver-control"
      headerIcon={<implementation.InlineIcon />}
    >
      <div className={sharedStyles.controls}>
        <GripperControlDualValueMode
          gripperControlState={gripperControlState}
          routineRunnerArgs={routineRunnerArgs}
          selectedCommandKind={command.commandKind}
        />
      </div>
    </WidgetView>
  );
}

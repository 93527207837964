import { Loader } from '@sb/ui/components';

import styles from './FullPageLoader.module.css';

interface FullPageLoaderProps {
  label?: string;
}

/**
 * Reusable component for displaying a loader centralized in the middle of the screen.
 */
const FullPageLoader = ({ label = 'Loading' }: FullPageLoaderProps) => {
  return (
    <div className={styles.fullPageLoader} role="status" aria-label={label}>
      <Loader />
    </div>
  );
};

export default FullPageLoader;

import cx from 'classnames';

import { Typography } from '@sb/ui/components';
import { margin } from '@sb/ui/styles';

import styles from './PortraitBlocker.module.css';

const PortraitBlocker = () => {
  return (
    <div className={styles.portraitBlocker}>
      <svg
        width="128"
        height="128"
        viewBox="0 0 128 128"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M66 16H19C16.2386 16 14 18.2386 14 21V97C14 99.7614 16.2386 102 19 102H20V103H19C15.6863 103 13 100.314 13 97V21C13 17.6863 15.6863 15 19 15H66C69.3137 15 72 17.6863 72 21V47H71V21C71 18.2386 68.7614 16 66 16Z"
          fill="#808080"
        />
        <path
          d="M42.0057 23C42.5611 23 43.0114 22.5523 43.0114 22C43.0114 21.4477 42.5611 21 42.0057 21C41.4503 21 41 21.4477 41 22C41 22.5523 41.4503 23 42.0057 23Z"
          fill="#808080"
        />
        <path
          d="M107 83.0057C107 83.5611 107.448 84.0114 108 84.0114C108.552 84.0114 109 83.5611 109 83.0057C109 82.4503 108.552 82 108 82C107.448 82 107 82.4503 107 83.0057Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M109 54C112.314 54 115 56.6863 115 60V107C115 110.314 112.314 113 109 113L33 113C29.6863 113 27 110.314 27 107L27 60C27 56.6863 29.6863 54 33 54L109 54ZM114 60V107C114 109.761 111.761 112 109 112L33 112C30.2386 112 28 109.761 28 107L28 60C28 57.2386 30.2386 55 33 55L109 55C111.761 55 114 57.2386 114 60Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M98.758 42.6764C100.251 33.3556 91.8196 25.2804 82.4286 27.4148L82.1109 27.4869C81.8416 27.5481 81.5737 27.3795 81.5125 27.1102C81.4513 26.8409 81.62 26.573 81.8893 26.5118L82.2069 26.4396C92.3337 24.1381 101.421 32.8897 99.7263 42.9513L102.542 41.1786C102.775 41.0315 103.084 41.1016 103.231 41.3353C103.378 41.569 103.308 41.8777 103.075 42.0248L99.2665 44.4225C99.0328 44.5696 98.7241 44.4995 98.577 44.2658L96.1793 40.4577C96.0322 40.2241 96.1024 39.9153 96.336 39.7682C96.5697 39.6211 96.8784 39.6912 97.0256 39.9249L98.758 42.6764Z"
          fill="white"
        />
        <rect
          opacity="0.1"
          x="27"
          y="54"
          width="88"
          height="59"
          rx="6"
          fill="#C4C4C4"
        />
      </svg>

      <div className={styles.contentContainer}>
        <Typography
          className={cx(margin.bottom.small, styles.header)}
          component="h4"
        >
          Portrait view is not supported.
        </Typography>

        <Typography className={styles.text} component="h6">
          This content is best experienced with the device in landscape mode.
          Please rotate your device for optimal display.
        </Typography>
      </div>
    </div>
  );
};

export default PortraitBlocker;

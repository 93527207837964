import { applyCompoundPose } from '@sb/geometry';
import type { CartesianPose } from '@sb/geometry';
import type { Integrations } from '@sb/types';
import { Typography } from '@sb/ui/components';
import { Flex, FlexChild } from '@sb/ui/components/Layout';
import { margin } from '@sb/ui/styles';
import { EditEquipment } from '@sbrc/components/equipment-manager/EditEquipment';

import {
  OR_DUAL_QUICK_CHANGER_PRIMARY_POSE,
  OR_DUAL_QUICK_CHANGER_SECONDARY_POSE,
} from '../constants';
import type { DualGripperCompatibleGrippers } from '../types/Configuration';

// eslint-disable-next-line import/no-cycle
import { QuickChangerItem } from './QuickChangerItem';

export function EquipmentManagerEditForm({
  item,
  otherEquipment,
  setChildContent,
  onClose,
  onUpdate,
  getEquipmentByKind,
  isFormDisabled,
  robot,
}: Integrations.EquipmentManagerEditFormProps) {
  if (item.kind !== 'OnRobotDualQuickChanger') {
    throw new Error(
      'Calling OnRobotDualQuickChanger method on non OnRobotDualQuickChanger item',
    );
  }

  const onClickChildEquipment = (child: 'primary' | 'secondary') => {
    const childItem = {
      id: child,
      config: item.grippers[child],
      isEnabled: true,
    };

    setChildContent(
      <EditEquipment
        key={child}
        equipmentItem={childItem}
        otherEquipment={otherEquipment}
        onBack={() => setChildContent(null)}
        onClose={onClose}
        onSave={({ config }) => {
          onUpdate({
            ...item,
            grippers: {
              ...item.grippers,
              [child]: config,
            },
          });

          setChildContent(null);
        }}
        getEquipmentByKind={getEquipmentByKind}
        isFormDisabled={isFormDisabled}
        robot={robot}
      />,
    );
  };

  const rotateTooltip = (
    child: DualGripperCompatibleGrippers,
    basePose: CartesianPose,
  ) => {
    if ('tooltipConfig' in child && child.tooltipConfig) {
      const {
        tooltipConfig: { tooltipPose },
      } = child;

      return {
        ...child,
        tooltipConfig: {
          tooltipPose: applyCompoundPose(tooltipPose, basePose),
        },
      };
    }

    return child;
  };

  return (
    <>
      <Typography component="h5">Grippers</Typography>
      <Flex direction="vertical" className={margin.top.small}>
        <FlexChild className={margin.top.extraSmall}>
          <div className={margin.bottom.extraSmall}>Primary</div>
          <QuickChangerItem
            item={item.grippers.primary}
            onClick={() => onClickChildEquipment('primary')}
            onChange={(childItem) => {
              onUpdate({
                ...item,
                grippers: {
                  ...item.grippers,
                  primary: rotateTooltip(
                    childItem,
                    OR_DUAL_QUICK_CHANGER_PRIMARY_POSE,
                  ),
                },
              });
            }}
            isDisabled={isFormDisabled}
          />
        </FlexChild>
        <FlexChild className={margin.top.medium}>
          <div className={margin.bottom.extraSmall}>Secondary</div>
          <QuickChangerItem
            item={item.grippers.secondary}
            onClick={() => onClickChildEquipment('secondary')}
            onChange={(childItem) =>
              onUpdate({
                ...item,
                grippers: {
                  ...item.grippers,
                  secondary: rotateTooltip(
                    childItem,
                    OR_DUAL_QUICK_CHANGER_SECONDARY_POSE,
                  ),
                },
              })
            }
            isDisabled={isFormDisabled}
          />
        </FlexChild>
      </Flex>
    </>
  );
}

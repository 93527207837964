import React, { useState } from 'react';

import type { Integrations } from '@sb/types';
import { Dropdown, MenuItem } from '@sb/ui/components';
import { IOBadge } from '@sbrc/components/io-manager';

import { usePortDropdownData } from './usePortDropdownData';

import styles from './PortDropdown.module.css';

interface PortDropdownProps {
  selectedPort?: Integrations.IOPort;
  onSelect: (port: Integrations.IOPort) => void;
  onUnassign?: () => void;
  children: (onOpen: () => void) => React.ReactNode;
}

export function PortDropdown({
  selectedPort,
  onSelect,
  onUnassign,
  children,
}: PortDropdownProps) {
  const [isOpen, setIsOpen] = useState(false);
  const portDropdownData = usePortDropdownData({ isOpen, selectedPort });

  return (
    <Dropdown
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      content={
        <>
          {onUnassign && (
            <MenuItem variant="alertPrimary" hasDivider onClick={onUnassign}>
              Unassign
            </MenuItem>
          )}

          {portDropdownData.map(({ port, isDisabled, label }) => (
            <MenuItem
              key={port.port}
              aria-label={`${port.kind} port ${port.port}`}
              hasDivider
              onClick={() => onSelect(port)}
              disabled={isDisabled}
              className={styles.button}
              contentClassName={styles.buttonContent}
            >
              <IOBadge>
                {port.kind === 'Input' ? 'IN' : 'OUT'} {port.port}
              </IOBadge>
              {label}
            </MenuItem>
          ))}
        </>
      }
    >
      {children(() => setIsOpen(true))}
    </Dropdown>
  );
}

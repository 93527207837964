import { Typography } from '@sb/ui/components';
import { useFeatureFlag } from '@sbrc/hooks';

import styles from './LimitsSectionHeader.module.css';

export function LimitsSectionHeader() {
  const dualModeCollision = useFeatureFlag('dualModeCollision');

  return (
    <>
      <Typography className={styles.cell} isBold variant="medium">
        Joint
      </Typography>
      <Typography className={styles.cell} isBold variant="medium">
        Torque Shock Threshold
      </Typography>
      <Typography className={styles.cell} isBold variant="medium">
        {dualModeCollision && 'Acceleration Threshold'}
        &nbsp;
      </Typography>
    </>
  );
}

import { Button } from '@sb/ui/components';
import { SwitchIcon } from '@sb/ui/icons';
import SectionContainer from '@sbrc/components/visualizer-view-shared/widget-panel/SectionContainer';
import {
  useIsAnotherSessionRunningAdHocCommand,
  useRobotRoutineRunningState,
} from '@sbrc/hooks';

import {
  calculateOR3FG15CommandFromState,
  calculateOR3FG15DefaultDiameterWithFingerAngle,
  getOR3FG15ConfigWithRoutineRunnerState,
} from '../..';
import type {
  OnRobot3FG15Command,
  OnRobot3FG15State,
  OnRobot3FG15Configuration,
} from '../..';

import type { SingleValueModeProps } from './types';
import useGripperSingleValueControl from './useGripperSingleValueControl';

import styles from './GripKind.module.css';

const getGripperCommand = (
  currentGripperState: OnRobot3FG15State | null,
  requestedGripKind: OnRobot3FG15Command['gripKind'],
) => {
  if (
    !currentGripperState ||
    currentGripperState.gripKind === requestedGripKind
  ) {
    return null;
  }

  const fingerConfiguration: OnRobot3FG15Configuration =
    getOR3FG15ConfigWithRoutineRunnerState(currentGripperState);

  const command = calculateOR3FG15CommandFromState({
    ...currentGripperState,
    gripKind: requestedGripKind,
    diameterMeters: calculateOR3FG15DefaultDiameterWithFingerAngle(
      fingerConfiguration,
      currentGripperState.fingerAngle,
      requestedGripKind,
    ),
  });

  return command;
};

export default function GripKind({
  gripperState,
  routineRunnerArgs,
  activeDualChangeGripper,
}: SingleValueModeProps) {
  const isAnotherSessionMovingRobot =
    useIsAnotherSessionRunningAdHocCommand(routineRunnerArgs);

  const routineRunningState = useRobotRoutineRunningState(routineRunnerArgs);

  const isRoutineRunning = routineRunningState !== null;

  const isDisabled = isAnotherSessionMovingRobot || isRoutineRunning;

  const [gripKind, setGripKind] = useGripperSingleValueControl(
    routineRunnerArgs,
    gripperState.gripKind,
    getGripperCommand,
    activeDualChangeGripper,
  );

  const toggleGripKind = () =>
    setGripKind((currentGripKind) =>
      currentGripKind === 'inward' ? 'outward' : 'inward',
    );

  return (
    <SectionContainer title="Grip Kind">
      <div className={styles.gripKindContainer}>
        <Button
          className={styles.gripKindButton}
          variant="gray"
          onClick={toggleGripKind}
          disabled={isDisabled}
        >
          {gripKind}
        </Button>

        <Button
          startIcon={<SwitchIcon />}
          aria-label="Switch grip kind"
          onClick={toggleGripKind}
          variant="blackPrimary"
          disabled={isDisabled}
          data-testid="grip-kind-button-first-widget"
        />
      </div>
    </SectionContainer>
  );
}

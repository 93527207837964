import GripperErrorWidget from '@sb/integrations/gripper-general/shared-components/GripperError';
import type { Integrations } from '@sb/types/integrations';
import { useRobotGripperState } from '@sbrc/hooks';

import { WidgetControlBody } from './WidgetControlBody';

export function OnRobotVGP20Widget(props: Integrations.WidgetProps) {
  const {
    robot,
    isVizbot,
    onClose,
    implementation,
    onCommandChange,
    defaultCommand,
  } = props;

  const routineRunnerArgs = {
    robotID: robot.id,
    isVizbot,
  };

  const routineRunnerGripperState = useRobotGripperState(
    routineRunnerArgs,
    'OnRobotVGP20',
  );

  if (!routineRunnerGripperState || !routineRunnerGripperState.isConnected) {
    return (
      <GripperErrorWidget
        implementation={implementation}
        onClose={onClose}
        message="The vacuum does not appear to be connected. Please ensure an OnRobot VGP20 Vacuum is properly mounted and connected."
      />
    );
  }

  return (
    <WidgetControlBody
      onClose={onClose}
      headerTitle={implementation.getDisplayName()}
      vacuumState={routineRunnerGripperState}
      routineRunnerArgs={routineRunnerArgs}
      onCommandChange={onCommandChange}
      defaultCommand={
        defaultCommand?.kind === 'OnRobotVGP20Command'
          ? defaultCommand
          : undefined
      }
    />
  );
}

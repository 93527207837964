import type { DistanceUnit } from '@sb/types';
import type { SelectProps } from '@sb/ui/components';
import { Select, SelectItem } from '@sb/ui/components';
import { DISTANCE_UNIT_INFOS, getDistanceUnitInfo } from '@sbrc/utils';

interface SelectDistanceUnitProps extends Omit<SelectProps, 'children'> {
  value: DistanceUnit;
  onSelect: (value: DistanceUnit) => void;
}

export function SelectDistanceUnit({
  value,
  onSelect,
  ...rest
}: SelectDistanceUnitProps) {
  return (
    <Select activeLabel={getDistanceUnitInfo(value).name} {...rest}>
      {DISTANCE_UNIT_INFOS.map((info) => (
        <SelectItem
          key={info.unit}
          isSelected={info.unit === value}
          onClick={() => onSelect(info.unit)}
        >
          {info.name}
        </SelectItem>
      ))}
    </Select>
  );
}

import dayjs from 'dayjs';
import type { ReactNode } from 'react';
import { useContext, useState } from 'react';

import type { DBBackupData, USBDriveData } from '@sb/feathers-types';
import { Button, Loader, Select, SelectItem } from '@sb/ui/components';
import { ModalContext } from '@sb/ui/components/Modal/shared';
import Typography from '@sb/ui/components/Typography';
import { margin } from '@sb/ui/styles';
import { restoreFromBackup } from '@sbrc/services/feathers-client';

import { DriveStatusIcon } from './DriveStatusIcon';

import styles from './RestoreBackupTab.module.css';

export const RestoreBackupTab = ({ usbData }: { usbData: USBDriveData[] }) => {
  const { onClose } = useContext(ModalContext);
  const [isRestoring, setIsRestoring] = useState(false);

  const [selectedDriveLabel, setSelectedDriveLabel] = useState<string | null>(
    null,
  );

  const [selectedBackup, setSelectedBackup] = useState<DBBackupData>();
  const [restoreData, setRestoreData] = useState<DBBackupData | null>(null);

  const driveData =
    usbData.find((drive) => drive.label === selectedDriveLabel) ??
    usbData[0] ??
    null;

  let driveHeaderLabel: string;
  let driveHeader: ReactNode;
  let subText: string = '';
  let restorePossible = false;
  let backupList: ReactNode[] = [];

  const restoreClick = async () => {
    if (!selectedBackup) return;
    setIsRestoring(true);
    setRestoreData(null);

    try {
      const restoreResult = await restoreFromBackup(
        selectedBackup.path,
        selectedBackup.robotID,
        selectedBackup.key,
      );

      setIsRestoring(false);
      setRestoreData(restoreResult);
    } catch (err) {
      setIsRestoring(false);

      setRestoreData({
        path: selectedBackup.path,
        robotID: selectedBackup.robotID,
        key: selectedBackup.key,
        date: new Date(),
        status: 'failed',
        error: err.message,
      });
    }
  };

  if (driveData) {
    restorePossible = true;
    driveHeaderLabel = driveData.label;
    if (isRestoring) driveHeaderLabel = `Restoring from ${driveData.label}`;
    else if (restoreData?.status === 'success')
      driveHeaderLabel = `Backup restored from ${driveData.label}`;
    else if (restoreData?.status === 'failed')
      driveHeaderLabel = `Error occured while restoring from ${driveData.label}`;

    backupList = driveData.backups.map((backup) => {
      return (
        <SelectItem key={backup.key} onClick={() => setSelectedBackup(backup)}>
          {dayjs(backup.date).format('YYYY-MM-DD HH:mm')}
        </SelectItem>
      );
    });

    if (backupList.length === 0) {
      backupList.push(
        <SelectItem key="empty-item" disabled>
          no backups detected.
        </SelectItem>,
      );
    }

    if (restoreData) {
      subText = restoreData.status ?? '';

      if (restoreData.status === 'failed' && restoreData.error) {
        subText = restoreData.error;
      }
    }
  } else {
    driveHeaderLabel = 'No disk insterted.';
  }

  if (usbData.length > 1 && !isRestoring && !restoreData) {
    driveHeader = (
      <Select
        activeLabel={
          <Typography variant="medium" isBold>
            {driveData?.label ?? 'select drive...'}
          </Typography>
        }
      >
        {usbData.map((usb) => (
          <SelectItem
            key={usb.label}
            onClick={() => {
              setSelectedDriveLabel(usb.label);
              setSelectedBackup(undefined);
            }}
          >
            {usb.label}
          </SelectItem>
        ))}
      </Select>
    );
  } else {
    driveHeader = (
      <Typography variant="medium" isBold>
        {driveHeaderLabel}
      </Typography>
    );
  }

  return (
    <>
      <Typography variant="medium">
        To restore from a backup, insert a thumbdrive or other USB storage
        device into the USB-A port on the bottom of the control box.
      </Typography>
      <div className={styles.drivePanel}>
        {driveData && (
          <DriveStatusIcon
            className={margin.right.small}
            status={restoreData?.status}
          />
        )}
        <div className={styles.driveTextContainer}>
          {driveHeader}

          {!isRestoring && subText && (
            <Typography variant="medium" color="gray">
              {subText}
            </Typography>
          )}
        </div>
        {isRestoring && <Loader className={margin.right.small} />}
        {!isRestoring && !restoreData && (
          <>
            <Select
              className={styles.backupSelect}
              activeLabel={
                selectedBackup?.date
                  ? dayjs(selectedBackup.date).format('YYYY-MM-DD HH:mm')
                  : 'Choose backup...'
              }
              isDisabled={!restorePossible}
            >
              {backupList}
            </Select>
            <Button
              disabled={isRestoring || !restorePossible || !selectedBackup}
              onClick={restoreClick}
            >
              Restore
            </Button>
          </>
        )}
        {restoreData?.status === 'success' && (
          <Button variant="secondary" onClick={onClose}>
            Done
          </Button>
        )}
        {restoreData?.status === 'failed' && (
          <Button variant="alertSecondary" onClick={restoreClick}>
            Retry
          </Button>
        )}
      </div>
    </>
  );
};

import { RangeSpinner, useDualValueRangeSpinner } from '@sb/ui/components';
import { forceToString } from '@sb/utilities';
import SectionContainer from '@sbrc/components/visualizer-view-shared/widget-panel/SectionContainer';

import { OR_2FG7_FORCE_NEWTONS_SLIDER_STEP } from '../..';

import type { DualValueModeProps } from './types';

export default function GripperForce({
  gripperControlState,
}: DualValueModeProps) {
  const {
    changeTargetForce,
    command,
    forceRange,
    isDisabled,
    routineRunnerForce,
  } = gripperControlState;

  const targetForce = command.targetForce ?? NaN;

  const spinnerProps = useDualValueRangeSpinner({
    primaryValue: routineRunnerForce,
    secondaryValue: targetForce,
    min: forceRange.min,
    max: forceRange.max,
    step: OR_2FG7_FORCE_NEWTONS_SLIDER_STEP,
    onChange: changeTargetForce,
    isDisabled,
  });

  return (
    <SectionContainer hasMeter title="Force">
      <RangeSpinner {...spinnerProps} valueToString={forceToString} />
    </SectionContainer>
  );
}

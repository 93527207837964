import type { Robot } from '@sb/types';
import { Modal, ModalHeader, ModalContent } from '@sb/ui/components';

import { DeveloperAPIModalBody } from './DeveloperAPIModalBody';

import styles from './DeveloperAPI.module.css';

interface DeveloperAPIModalProps {
  isOpen: boolean;
  onClose: () => void;
  robot: Robot.ConvertedResponse;
}

export const DeveloperAPIModal = ({
  isOpen,
  onClose,
  robot,
}: DeveloperAPIModalProps) => {
  if (!isOpen) {
    return null;
  }

  return (
    <Modal className={styles.backupModal} isOpen onClose={onClose}>
      <ModalHeader title="Developer API" />
      <ModalContent>
        <DeveloperAPIModalBody robot={robot} />
      </ModalContent>
    </Modal>
  );
};

import cx from 'classnames';

import styles from './WidgetBody.module.css';

interface WidgetBodyProps {
  isScrolling?: boolean;
  children?: React.ReactNode;
  className?: string;
}

export default function WidgetBody({
  isScrolling,
  children,
  className,
}: WidgetBodyProps) {
  return (
    <div
      className={cx(styles.main, isScrolling && styles.isScrolling, className)}
    >
      {children}
    </div>
  );
}

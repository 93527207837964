import cx from 'classnames';

import { Loader, ModalHeader } from '@sb/ui/components';
import { alignItems, justifyContent } from '@sb/ui/styles';

import styles from './shared.module.css';

export default function LoadingState() {
  return (
    <>
      <ModalHeader title="Safety Settings" />

      <div
        className={cx(
          styles.modalContent,
          justifyContent.center,
          alignItems.center,
        )}
      >
        <Loader />
      </div>
    </>
  );
}

import cx from 'classnames';

import {
  Modal,
  ModalContent,
  ModalHeader,
  Typography,
} from '@sb/ui/components';
import { margin, padding } from '@sb/ui/styles';

import { SoftwareReadyToInstall } from './SoftwareReadyToInstall';
import { SoftwareUpToDate } from './SoftwareUpToDate';
import type { SoftwareBuildData } from './useSoftwareBuildData';

import styles from './SoftwareUpdateModal.module.css';

interface SoftwareUpdateModalProps {
  isOpen: boolean;
  onClose: () => void;
  buildData: SoftwareBuildData;
}

export function SoftwareUpdateModal({
  onClose,
  isOpen,
  buildData,
}: SoftwareUpdateModalProps) {
  const {
    activeBuildID,
    activeBuildName,
    timeChecked,
    isSoftwareUpdateAvailable,
    lastErrorMessage,
  } = buildData;

  const modalContent = (() => {
    if (isSoftwareUpdateAvailable) {
      return <SoftwareReadyToInstall buildData={buildData} />;
    }

    if (activeBuildID) {
      return (
        <SoftwareUpToDate
          lastCheckedTime={timeChecked}
          lastErrorMessage={lastErrorMessage}
        />
      );
    }

    return (
      <Typography className={padding.small}>
        Version information is unavailable.
      </Typography>
    );
  })();

  return (
    <Modal className={styles.modal} isOpen={isOpen} onClose={onClose}>
      <ModalHeader title="Software Update" />
      <ModalContent>
        <div className={styles.modalContent}>{modalContent}</div>

        <Typography
          variant="medium"
          className={cx(margin.left.medium, margin.top.large)}
        >
          <strong>Installed Version: </strong>
          {activeBuildName ?? 'Unavailable'}
        </Typography>

        <Typography
          color="gray"
          variant="small"
          className={cx(margin.left.medium, margin.bottom.medium)}
        >
          {activeBuildID}
        </Typography>
      </ModalContent>
    </Modal>
  );
}

import { JOINT_NUMBERS } from '@sb/motion-planning';

import { Disclaimer } from '../Disclaimer';

import { FactoryPresetChooser } from './FactoryPresetChooser';
import { JointLimits } from './JointLimits';
import { TooltipLimits } from './TooltipLimits';

import styles from './LimitsTab.module.css';

export function LimitsTab() {
  return (
    <>
      <Disclaimer />
      <FactoryPresetChooser />
      <div className={styles.grid}>
        <TooltipLimits />
        {JOINT_NUMBERS.map((jointNumber) => {
          return <JointLimits key={jointNumber} jointNumber={jointNumber} />;
        })}
      </div>
    </>
  );
}

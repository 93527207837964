import { Button, Typography } from '@sb/ui/components';
import { AddIcon, AccessoriesIcon } from '@sb/ui/icons';
import { margin } from '@sb/ui/styles';

import styles from './shared.module.css';

type EmptyStateProps = {
  onAdd: () => void;
};

export function EmptyState({ onAdd }: EmptyStateProps) {
  return (
    <div className={styles.emptyState}>
      <AccessoriesIcon variant="neutralDark" size="extraLarge" />
      <Typography component="h3" className={margin.top.medium}>
        No equipment configured
      </Typography>
      <Typography variant="medium" className={margin.top.extraSmall}>
        Manage the grippers, end effectors, and network devices that you use
        with your robot here.
      </Typography>
      <Button
        onClick={() => onAdd()}
        startIcon={<AddIcon />}
        className={margin.top.medium}
      >
        Add equipment
      </Button>
    </div>
  );
}

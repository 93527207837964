import type { RoutineVariable } from '@sb/types';

import type { DualGripperCompatibleGrippers } from './types/Configuration';

export const getDualGripperVariables = (
  primaryKind: DualGripperCompatibleGrippers['kind'],
  secondaryKind: DualGripperCompatibleGrippers['kind'],
): RoutineVariable.GripperStateVariableInformation[] => {
  const primaryIs3FG15 = primaryKind === 'OnRobot3FG15';
  const secondaryIs3FG15 = secondaryKind === 'OnRobot3FG15';

  let primaryName = 'Primary:';
  let secondaryName = 'Primary:';

  if (primaryKind !== secondaryKind) {
    primaryName = `${primaryKind} (Primary):`;
    secondaryName = `${secondaryKind} (Secondary):`;
  }

  const maybe2FG7Primary: RoutineVariable.GripperStateVariableInformation = {
    kind: 'gripperState',
    gripperName: primaryName,
    selector: 'gripperState.primary.width.outer',
    variableKind: 'number',
    displayName: 'Outer width',
    variableName: 'kinematicState.gripperState.primary.width.outer',
    userIdentifier: 'Gripper_width_outer',
    unitLabel: 'M',
  };

  const maybe2FG7Secondary: RoutineVariable.GripperStateVariableInformation = {
    kind: 'gripperState',
    gripperName: secondaryName,
    selector: 'gripperState.secondary.width.outer',
    variableKind: 'number',
    displayName: 'Outer width',
    variableName: 'kinematicState.gripperState.secondary.width.outer',
    userIdentifier: 'Gripper_width_outer',
    unitLabel: 'M',
  };

  return [
    {
      kind: 'gripperState',
      gripperName: primaryName,
      selector: primaryIs3FG15
        ? 'gripperState.primary.diameterMeters'
        : 'gripperState.primary.width.inner',
      variableKind: 'number',
      displayName: primaryIs3FG15 ? 'Diameter' : 'Inner width',
      variableName: primaryIs3FG15
        ? 'kinematicState.gripperState.primary.diameterMeters'
        : 'kinematicState.gripperState.primary.width.inner',
      userIdentifier: primaryIs3FG15
        ? 'Gripper_diameterMeters'
        : 'Gripper_width_inner',
      unitLabel: 'M',
    },
    ...(primaryIs3FG15 ? [] : [maybe2FG7Primary]),
    {
      kind: 'gripperState',
      gripperName: primaryName,
      selector: 'gripperState.primary.forceAppliedNewtons',
      variableKind: 'number',
      displayName: 'Gripped with force',
      variableName: 'kinematicState.gripperState.primary.forceAppliedNewtons',
      userIdentifier: 'Gripper_forceAppliedNewtons',
      unitLabel: 'N',
    },
    {
      kind: 'gripperState',
      gripperName: primaryName,
      selector: 'gripperState.primary.isGripDetected',
      variableKind: 'boolean',
      displayName: 'Gripped',
      values: [{ displayName: 'Gripped', value: true }],
      variableName: 'kinematicState.gripperState.primary.isGripDetected',
      userIdentifier: 'Gripper_isGripDetected',
    },
    {
      kind: 'gripperState',
      gripperName: primaryName,
      selector: 'gripperState.primary.isForceGripDetected',
      variableKind: 'boolean',
      displayName: 'Gripped with force specified in previous step',
      values: [{ displayName: 'Gripped', value: true }],
      variableName: 'kinematicState.gripperState.primary.isForceGripDetected',
      userIdentifier: 'Gripper_isForceGripDetected',
    },
    {
      kind: 'gripperState',
      gripperName: secondaryName,
      selector: secondaryIs3FG15
        ? 'gripperState.primary.diameterMeters'
        : 'gripperState.primary.width.inner',
      variableKind: 'number',
      displayName: secondaryIs3FG15 ? 'Diameter' : 'Inner width',
      variableName: secondaryIs3FG15
        ? 'kinematicState.gripperState.primary.diameterMeters'
        : 'kinematicState.gripperState.primary.width.inner',
      userIdentifier: secondaryIs3FG15
        ? 'Gripper_diameterMeters'
        : 'Gripper_width_inner',
      unitLabel: 'M',
    },
    ...(secondaryIs3FG15 ? [] : [maybe2FG7Secondary]),
    {
      kind: 'gripperState',
      gripperName: secondaryName,
      selector: 'gripperState.secondary.forceAppliedNewtons',
      variableKind: 'number',
      displayName: 'Gripped with force',
      variableName: 'kinematicState.gripperState.secondary.forceAppliedNewtons',
      userIdentifier: 'Gripper_forceAppliedNewtons',
      unitLabel: 'N',
    },
    {
      kind: 'gripperState',
      gripperName: secondaryName,
      selector: 'gripperState.secondary.isGripDetected',
      variableKind: 'boolean',
      displayName: 'Secondary: Gripped',
      values: [{ displayName: 'Gripped', value: true }],
      variableName: 'kinematicState.gripperState.secondary.isGripDetected',
      userIdentifier: 'Gripper_isGripDetected',
    },
    {
      kind: 'gripperState',
      gripperName: secondaryName,
      selector: 'gripperState.secondary.isForceGripDetected',
      variableKind: 'boolean',
      displayName: 'Gripped with force specified in previous step',
      values: [{ displayName: 'Gripped', value: true }],
      variableName: 'kinematicState.gripperState.secondary.isForceGripDetected',
      userIdentifier: 'Gripper_isForceGripDetected',
    },
  ];
};

import IOBadge from './IOBadge';

import styles from './IOLabel.module.css';

interface IOLabelProps {
  badgeLabel: string;
  deviceName?: string;
  name: string;
}

const IOLabel = ({ badgeLabel, deviceName, name }: IOLabelProps) => {
  return (
    <div className={styles.ioLabel}>
      <IOBadge className={styles.ioBadge}>{badgeLabel}</IOBadge>
      {deviceName && <span className={styles.ioDeviceName}>{deviceName}:</span>}
      {name}
    </div>
  );
};

export default IOLabel;

import type { OnRobot3FG15Command, OnRobot3FG15Configuration } from '../..';
import { calculateOR3FG15FingerAngle } from '../..';

export default function calculateFingerAngleFromControlState({
  command,
  config,
}: {
  command: OnRobot3FG15Command;
  config: OnRobot3FG15Configuration;
}): number {
  return calculateOR3FG15FingerAngle({
    ...config,
    diameter: command.targetDiameter,
    gripKind: command.gripKind,
  });
}

import { useEffect, useState } from 'react';

import type { OnRobotVGP20State } from '@sb/integrations/OnRobotVGP20';

import type { OnRobotVGP20ChannelCommand, OnRobotVGP20Command } from '../types';

type Channel = keyof OnRobotVGP20Command['suctionActions'];

export function useGripperControlState(
  defaultCommand: OnRobotVGP20Command | null,
  vacuumState: OnRobotVGP20State,
  isVizbot: boolean,
): {
  command: OnRobotVGP20Command | null;
  getIsEnabled: (channel: Channel) => boolean;
  getSuctionPercentage: (channel: Channel) => number;
  handleIsEnabledChange: (channel: Channel | '*') => (value: boolean) => void;
  overallSuctionPercentage: number;
  handleOverallSuctionPercentageChange: () => (
    action: (value: number) => number,
  ) => void;
  resetCommand: () => void;
} {
  const [command, setCommand] = useState<OnRobotVGP20Command | null>(
    defaultCommand,
  );

  const calcOverallSuctionPercentage = () => {
    return Math.max(...Object.values(vacuumState.suctionForcePercentage)) || 1;
  };

  const [
    overallSuctionPercentage = calcOverallSuctionPercentage(),
    setOverallSuctionPercentage,
  ] = useState<number | undefined>(() => {
    if (defaultCommand) {
      const suctionPercentages = Object.values(
        defaultCommand.suctionActions,
      ).map((a) => a.suctionPercentage);

      return Math.max(...suctionPercentages) || 1;
    }

    return undefined;
  });

  const getIsEnabled = (channel: Channel) => {
    if (command) {
      return command.suctionActions[channel].commandKind === 'GRIP';
    }

    return (
      vacuumState.suctionForcePercentage[channel] > 0 ||
      vacuumState.currentAction?.[channel]?.kind === 'applyingSuction'
    );
  };

  const getChannelCommand = (
    channel: Channel,
    applyTo: Channel | '*',
    isEnabled = getIsEnabled(channel),
    suctionPercentage = overallSuctionPercentage,
  ): OnRobotVGP20ChannelCommand => {
    if (applyTo === '*' || applyTo === channel) {
      if (isEnabled) {
        return {
          commandKind: 'GRIP',
          suctionPercentage,
        };
      }

      return {
        commandKind: 'RELEASE',
        suctionPercentage: 0,
      };
    }

    if (command) {
      return command.suctionActions[channel];
    }

    return {
      commandKind:
        vacuumState.suctionForcePercentage[channel] > 0 ? 'GRIP' : 'RELEASE',
      suctionPercentage: vacuumState.suctionForcePercentage[channel],
    };
  };

  useEffect(() => {
    setCommand(null);
    setOverallSuctionPercentage(undefined);
  }, [isVizbot]);

  return {
    command,
    overallSuctionPercentage,
    getIsEnabled,

    getSuctionPercentage: (channel) => {
      if (!vacuumState.isBusy) {
        if (command && command.suctionActions[channel].commandKind === 'GRIP') {
          return command.suctionActions[channel].suctionPercentage;
        }

        if (command) {
          return 0;
        }
      }

      return vacuumState.suctionForcePercentage[channel];
    },

    handleIsEnabledChange: (channel) => (isEnabled) => {
      setCommand({
        kind: 'OnRobotVGP20Command',
        suctionActions: {
          one: getChannelCommand('one', channel, isEnabled),
          two: getChannelCommand('two', channel, isEnabled),
          three: getChannelCommand('three', channel, isEnabled),
          four: getChannelCommand('four', channel, isEnabled),
        },
      });
    },

    handleOverallSuctionPercentageChange: () => {
      return (action) => {
        const newVal = action(overallSuctionPercentage);

        setOverallSuctionPercentage(newVal);

        setCommand({
          kind: 'OnRobotVGP20Command',
          suctionActions: {
            one: getChannelCommand('one', '*', undefined, newVal),
            two: getChannelCommand('two', '*', undefined, newVal),
            three: getChannelCommand('three', '*', undefined, newVal),
            four: getChannelCommand('four', '*', undefined, newVal),
          },
        });
      };
    },

    resetCommand: () => {
      setCommand(null);
      setOverallSuctionPercentage(undefined);
    },
  };
}

import { Typography } from '@sb/ui/components';
import { InfoIcon } from '@sb/ui/icons';

import WidgetBody from './WidgetBody';

import styles from './DisabledWidgetBody.module.css';

interface DisabledWidgetViewProps {
  contentHeader?: string;
  content: string;
}

export default function DisabledWidgetBody({
  contentHeader,
  content,
}: DisabledWidgetViewProps) {
  return (
    <WidgetBody isScrolling className={styles.widgetBody}>
      <InfoIcon className={styles.infoIcon} />
      {contentHeader && (
        <Typography component="h4" isBold>
          {contentHeader}
        </Typography>
      )}
      <Typography className={styles.bodyText}>{content}</Typography>
    </WidgetBody>
  );
}

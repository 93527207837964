import {
  zPositionStringConvert,
  SHANK_POSITION_RANGE,
} from '@sb/integrations/OnRobotScrewdriver';
import { RangeSpinner, useDualValueRangeSpinner } from '@sb/ui/components';

import type { DualValueModeProps } from './types';

export default function GripperShankPosition({
  gripperControlState,
}: DualValueModeProps) {
  const { changeShankPosition, actual, target } = gripperControlState;

  const spinnerProps = useDualValueRangeSpinner({
    primaryValue: actual.routineRunnerShankPosition,
    secondaryValue: target.targetShankPosition,
    min: SHANK_POSITION_RANGE.min,
    max: SHANK_POSITION_RANGE.max,
    step: SHANK_POSITION_RANGE.step,
    onChange: changeShankPosition,
  });

  return (
    <RangeSpinner {...spinnerProps} valueToString={zPositionStringConvert} />
  );
}

import { useRouter } from 'next/router';

import type { Robot } from '@sb/types';
import {
  Button,
  Modal,
  ModalContent,
  ModalHeader,
  Typography,
} from '@sb/ui/components';
import { margin, padding } from '@sb/ui/styles';
import { useIsRobotAtJointAngles } from '@sbrc/hooks';
import {
  getLinkToBoxRobotPage,
  ROBOT_JOINT_ANGLES_FOAM_CASE,
} from '@sbrc/utils';

interface BoxRobotModalProps {
  isOpen: boolean;
  onClose: () => void;
  robot: Robot.ConvertedResponse;
}

const BoxRobotModal = ({ isOpen, onClose, robot }: BoxRobotModalProps) => {
  const { push } = useRouter();

  const isRobotInBoxDefaultPosition = useIsRobotAtJointAngles(
    { robotID: robot.id },
    ROBOT_JOINT_ANGLES_FOAM_CASE,
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalHeader className={margin.bottom.small} title="Box Robot" />

      <ModalContent className={padding.top.none}>
        {isRobotInBoxDefaultPosition && (
          <>
            <Typography className={margin.bottom.small} variant="medium">
              The Live Robot “{robot.name}” is in the boxing position.
            </Typography>

            <Button variant="primary" onClick={onClose}>
              Return
            </Button>
          </>
        )}

        {!isRobotInBoxDefaultPosition && (
          <>
            <Typography className={margin.bottom.small} variant="medium">
              Before boxing the robot, please return the arm into its boxing
              position. Click the “Prepare Robot for Shipping” button to
              navigate to the Move Robot page and sync the live arm to the
              boxing position.
            </Typography>

            <Button
              variant="primary"
              onClick={() => push(getLinkToBoxRobotPage(robot.id))}
            >
              Prepare Robot for Shipping
            </Button>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default BoxRobotModal;

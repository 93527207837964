import ApplyChangesButton from './ApplyChangesButton';
import GripKind from './GripKind';
import GripperDiameter from './GripperDiameter';
import GripperForce from './GripperForce';
import GripperPayload from './GripperPayload';
import type { DualValueModeProps } from './types';

export function GripperControlDualValueMode(props: DualValueModeProps) {
  return (
    <>
      <GripperPayload {...props} />
      <GripKind {...props} />
      <GripperForce {...props} />
      <GripperDiameter {...props} />
      <ApplyChangesButton {...props} />
    </>
  );
}

import { sortBy } from 'lodash';
import { useState } from 'react';

import { getEquipmentByKind } from '@sb/integrations/frontend/getEquipmentByKind';
import {
  getEquipmentList,
  canAddIntegration,
} from '@sb/integrations/frontend/util';
import type { Equipment } from '@sb/types';
import { Button, Typography, RadioInput, ModalHeader } from '@sb/ui/components';
import { AddIcon } from '@sb/ui/icons';

import styles from './shared.module.css';

interface NewEquipmentPickerProps {
  onAddEquipment: (equipment: Equipment.EditableFields) => void;
  onClickBack: () => void;
  equipment: Equipment.ConvertedResponse[];
}

export function NewEquipmentPicker({
  onAddEquipment,
  onClickBack,
  equipment,
}: NewEquipmentPickerProps) {
  let availableEquipment = getEquipmentList().filter(
    (e) => !getEquipmentByKind(e).isHidden?.(),
  );

  availableEquipment = sortBy(availableEquipment, (eq) =>
    canAddIntegration(eq, equipment, getEquipmentByKind) ? 0 : 1,
  );

  const [selected, setSelected] = useState<number | null>(null);

  const onClickAddEquipment = () => {
    if (selected === null) {
      return;
    }

    const kindToAdd = availableEquipment[selected];

    const isEnabled = canAddIntegration(
      kindToAdd,
      equipment,
      getEquipmentByKind,
    );

    const int = getEquipmentByKind(kindToAdd);

    const baseName = int.getShortName();

    const getDefaultName = (i = 1): string => {
      const name = i === 1 ? baseName : `${baseName} (${i})`;

      return equipment.some((e) => e.config.name === name)
        ? getDefaultName(i + 1)
        : name;
    };

    onAddEquipment({
      config: {
        name: getDefaultName(),
        ...int.getDefaultItem(),
      },
      isEnabled,
    });
  };

  return (
    <>
      <ModalHeader title="Add equipment" className={styles.modalHeader} />
      <div className={styles.equipmentModalContent} role="radiogroup">
        {availableEquipment.map((equipmentItem, i) => {
          const equipmentFrontendImplementation =
            getEquipmentByKind(equipmentItem);

          return (
            <div key={equipmentItem} className={styles.newEquipmentPickerRow}>
              <RadioInput
                checked={i === selected}
                onChange={() => setSelected(i)}
                aria-checked={i === selected}
                role="radio"
              >
                <div className={styles.newEquipmentPickerIcon}>
                  <equipmentFrontendImplementation.InlineIcon size="medium" />
                </div>
                <div>
                  <Typography variant="medium" isBold={i === selected}>
                    {equipmentFrontendImplementation.getDisplayName()}
                  </Typography>
                </div>
              </RadioInput>
            </div>
          );
        })}
      </div>
      <div className={styles.footer}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button variant="gray" onClick={() => onClickBack()}>
            Cancel
          </Button>
          <Button
            startIcon={<AddIcon />}
            disabled={selected == null}
            onClick={() => onClickAddEquipment()}
          >
            Add equipment
          </Button>
        </div>
      </div>
    </>
  );
}

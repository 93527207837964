import { SafeguardKind } from '@sb/routine-runner';

export function asSafeguardKind(value: any): SafeguardKind {
  const parsedValue = SafeguardKind.safeParse(value);

  return parsedValue.success ? parsedValue.data : 'none';
}

interface SafeguardKindInfo {
  description: JSX.Element;
  supportsAutoReset: boolean;
}

export const SAFEGUARD_KIND_INFOS: Record<SafeguardKind, SafeguardKindInfo> = {
  none: {
    description: <>Unassigned</>,
    supportsAutoReset: false,
  },
  slow: {
    description: (
      <>
        <strong>Slow Speed</strong> when low
      </>
    ),
    supportsAutoReset: true,
  },
  estop: {
    description: (
      <>
        <strong>Emergency Stop</strong> when low
      </>
    ),
    supportsAutoReset: false,
  },
  pauselow: {
    description: (
      <>
        <strong>Pause</strong> when low
      </>
    ),
    supportsAutoReset: true,
  },
  pausehigh: {
    description: (
      <>
        <strong>Pause</strong> when high
      </>
    ),
    supportsAutoReset: true,
  },
  reset: {
    description: (
      <>
        <strong>Reset Safeguards</strong> when high
      </>
    ),
    supportsAutoReset: false,
  },
};

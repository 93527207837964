import styles from './MenuCardItem.module.css';

interface MenuCardItemProps {
  children: React.ReactNode;
}

export function MenuCardItem({ children }: MenuCardItemProps) {
  /* eslint-disable jsx-a11y/no-static-element-interactions */

  return (
    <div className={styles.container} onClick={(e) => e.stopPropagation()}>
      <div className={styles.innerContainer}>{children}</div>
    </div>
  );
}

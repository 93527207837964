import { cloneDeep, isEqual } from 'lodash';

import type { SafeguardRule } from '@sb/routine-runner';
import { SAFEGUARD_PAIR_PORTS, SAFEGUARD_KIND_NAMES } from '@sb/routine-runner';
import { getRobot, updateRobot } from '@sbrc/services';

export async function updateRobotIOManager(
  robotID: string,
  safeguardRules: SafeguardRule[],
) {
  const robot = await getRobot(robotID);

  if (!robot) {
    return;
  }

  const ioInputs = cloneDeep(robot.ioInputs);

  const safeguardPorts = new Set<number>();

  for (const rule of safeguardRules) {
    if (rule.kind === 'none') {
      continue;
    }

    for (const port of SAFEGUARD_PAIR_PORTS[rule.pair]) {
      const ioInput = ioInputs.find((i) => i.port === port);

      if (!ioInput) {
        continue;
      }

      safeguardPorts.add(port);

      if (ioInput.safeguardKind !== rule.kind) {
        ioInput.name = `Safety I/O: ${SAFEGUARD_KIND_NAMES[rule.kind]}`;
        ioInput.safeguardKind = rule.kind;
      }

      switch (rule.kind) {
        case 'estop': {
          ioInput.highSignalName = 'Run';
          ioInput.lowSignalName = 'Stop';
          break;
        }
        case 'slow': {
          ioInput.highSignalName = 'Full Speed';
          ioInput.lowSignalName = 'Slow Speed';
          break;
        }
        case 'pausehigh': {
          ioInput.highSignalName = 'Pause';
          ioInput.lowSignalName = 'Run';
          break;
        }
        case 'pauselow': {
          ioInput.highSignalName = 'Run';
          ioInput.lowSignalName = 'Pause';
          break;
        }
        case 'reset': {
          ioInput.highSignalName = 'Reset';
          ioInput.lowSignalName = 'None';
          break;
        }
        default:
          break;
      }
    }
  }

  // reset manager info on ports which are no longer safety IO ports
  for (const ioInput of ioInputs) {
    if (!safeguardPorts.has(ioInput.port)) {
      if (ioInput.safeguardKind) {
        ioInput.safeguardKind = undefined;

        if (ioInput.name.startsWith('Safety I/O:')) {
          ioInput.name = `Input ${ioInput.port}`;
        }

        ioInput.highSignalName = 'High';
        ioInput.lowSignalName = 'Low';
      }
    }
  }

  if (!isEqual(ioInputs, robot.ioInputs)) {
    await updateRobot(robotID, { ioInputs });
  }
}

import { useEffect, useState } from 'react';

import type { USBDriveData } from '@sb/feathers-types';
import { getUSBDrives } from '@sbrc/services/feathers-client';

/** Generates a sort of hash from USB data to save render cycles. */
const generateUSBCacheString = (data: USBDriveData[]): string => {
  return data
    .map((drive) => `${drive.label}:${drive.backups.length}`)
    .join(',');
};

export const useUSBDrive = () => {
  const [usbDriveData, setUSBDriveData] = useState<USBDriveData[]>([]);

  useEffect(() => {
    let driveDataCache = '';

    const fetchUSBDrives = async () => {
      const data = await getUSBDrives();
      const newCache = generateUSBCacheString(data);

      if (newCache !== driveDataCache) {
        setUSBDriveData(data);
        driveDataCache = newCache;
      }
    };

    const poll = setInterval(fetchUSBDrives, 2000);

    fetchUSBDrives();

    return () => {
      clearInterval(poll);
    };
  }, []);

  return usbDriveData;
};

import { RangeSpinner, useSingleValueRangeSpinner } from '@sb/ui/components';
import { forceToString } from '@sb/utilities';
import SectionContainer from '@sbrc/components/visualizer-view-shared/widget-panel/SectionContainer';
import {
  useIsAnotherSessionRunningAdHocCommand,
  useRobotRoutineRunningState,
} from '@sbrc/hooks';

import {
  OR_3FG15_FORCE_NEWTONS_MAX,
  OR_3FG15_FORCE_NEWTONS_MIN,
  calculateOR3FG15CommandFromState,
  OR_3FG15_FORCE_NEWTONS_SLIDER_STEP,
  OR_3FG15_TARGET_FORCE_DEFAULT,
} from '../..';
import type { OnRobot3FG15Command, OnRobot3FG15State } from '../..';

import type { SingleValueModeProps } from './types';
import useGripperSingleValueControl from './useGripperSingleValueControl';

const getGripperCommand = (
  currentGripperState: OnRobot3FG15State | null,
  requestedTargetForce: number,
) => {
  if (
    !currentGripperState ||
    requestedTargetForce === currentGripperState.targetForceNewtons
  ) {
    return null;
  }

  const command = calculateOR3FG15CommandFromState({
    ...currentGripperState,
    targetForceNewtons: requestedTargetForce,
  }) as OnRobot3FG15Command;

  return command;
};

export default function GripperForce({
  gripperState,
  routineRunnerArgs,
  activeDualChangeGripper,
}: SingleValueModeProps) {
  const [targetForce, setTargetForce] = useGripperSingleValueControl(
    routineRunnerArgs,
    gripperState.targetForceNewtons ?? OR_3FG15_TARGET_FORCE_DEFAULT,
    getGripperCommand,
    activeDualChangeGripper,
  );

  const isAnotherSessionMovingRobot =
    useIsAnotherSessionRunningAdHocCommand(routineRunnerArgs);

  const routineRunningState = useRobotRoutineRunningState(routineRunnerArgs);

  const isRoutineRunning = routineRunningState !== null;

  const spinnerProps = useSingleValueRangeSpinner({
    value: targetForce,
    min: OR_3FG15_FORCE_NEWTONS_MIN,
    max: OR_3FG15_FORCE_NEWTONS_MAX,
    step: OR_3FG15_FORCE_NEWTONS_SLIDER_STEP,
    onChange: setTargetForce,
  });

  return (
    <SectionContainer title="Force">
      <RangeSpinner
        {...spinnerProps}
        valueToString={forceToString}
        isDisabled={isAnotherSessionMovingRobot || isRoutineRunning}
      />
    </SectionContainer>
  );
}

import cx from 'classnames';
import { useState } from 'react';

import { Card, NoItems, Typography } from '@sb/ui/components';

import DetailedAlertsModal from './detailed-alerts/DetailedAlertsModal';
import NotificationItem from './NotificationItem';
import { useNotificationSystemContext } from './shared';

import styles from './NotificationDropdownContent.module.css';

const NotificationDropdownContent = () => {
  const { maxNonArchivedNotifications: notifications, archivedNotifications } =
    useNotificationSystemContext();

  const hasArchivedNotifications = Boolean(archivedNotifications.length);

  const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false);

  const openAlert = () => setIsAlertOpen(true);
  const closeAlert = () => setIsAlertOpen(false);

  return (
    <Card
      borderSize="medium"
      className={cx(styles.notificationDropdownContent, {
        [styles.noNotifications]: !notifications.length,
      })}
    >
      <Typography
        component="h5"
        className={styles.notificationsTitle}
        data-testid="notifications-label"
      >
        Notifications
      </Typography>

      {notifications.length ? (
        <div className={styles.notificationList}>
          {notifications.map((notification, i) => (
            <NotificationItem
              key={notification.id}
              notification={notification}
              listNumber={`${i + 1}. `}
            />
          ))}
        </div>
      ) : (
        <NoItems className={styles.noItems}>No notifications</NoItems>
      )}

      <Typography
        role="button"
        className={cx(
          styles.archivedButton,
          !hasArchivedNotifications && styles.disabled,
        )}
        variant="small"
        color={hasArchivedNotifications ? 'primary' : 'gray'}
        onClick={hasArchivedNotifications ? openAlert : undefined}
      >
        {hasArchivedNotifications ? 'View archived' : 'No archived items'}
      </Typography>

      <DetailedAlertsModal
        key={`alerts:${isAlertOpen}`}
        isOpen={isAlertOpen}
        onClose={closeAlert}
        isArchivedList
      />
    </Card>
  );
};

export default NotificationDropdownContent;

import { useEffect } from 'react';

import type { DeviceCommand } from '@sb/integrations/device';
import type { OnRobotVGP20State } from '@sb/integrations/OnRobotVGP20';
import {
  Button,
  Checkbox,
  RangeSpinner,
  useSingleValueRangeSpinner,
} from '@sb/ui/components';
import { ResetAllIcon, VacuumIcon } from '@sb/ui/icons';
import HelpPopover from '@sbrc/components/HelpPopover';
import {
  WidgetView,
  sharedStyles,
} from '@sbrc/components/visualizer-view-shared/widget-panel';
import SectionContainer from '@sbrc/components/visualizer-view-shared/widget-panel/SectionContainer';

import { suctionAmountStringConvert, ORVGP20_CHANNELS } from '../constants';
import type { OnRobotVGP20Command } from '../types';

import { ApplyChangesButton } from './ApplyChangesButton';
import { SuctionChannelCheckbox } from './SuctionChannelCheckbox';
import { useGripperControlState } from './useGripperControlState';

import styles from './WidgetControlBody.module.css';

interface WidgetControlBodyProps {
  onClose: () => void;
  headerTitle: string;
  onCommandChange?: React.Dispatch<React.SetStateAction<DeviceCommand | null>>;
  defaultCommand?: OnRobotVGP20Command;
  vacuumState: OnRobotVGP20State;
  routineRunnerArgs: {
    robotID: string;
    isVizbot: boolean;
  };
}

export function WidgetControlBody({
  onClose,
  headerTitle,
  defaultCommand,
  vacuumState,
  onCommandChange,
  routineRunnerArgs,
}: WidgetControlBodyProps) {
  const {
    command,
    getIsEnabled,
    getSuctionPercentage,
    handleIsEnabledChange,
    handleOverallSuctionPercentageChange,
    overallSuctionPercentage,
    resetCommand,
  } = useGripperControlState(
    defaultCommand ?? null,
    vacuumState,
    routineRunnerArgs.isVizbot,
  );

  useEffect(() => {
    onCommandChange?.(command);
  }, [onCommandChange, command]);

  const sprops = useSingleValueRangeSpinner({
    value: overallSuctionPercentage,
    min: 0.01,
    max: 1,
    step: 0.01,
    onChange: handleOverallSuctionPercentageChange(),
  });

  const isAllEnabled = ORVGP20_CHANNELS.every(({ channel }) =>
    getIsEnabled(channel),
  );

  return (
    <WidgetView
      onClose={onClose}
      headerTitle={headerTitle}
      testPrefix="onrobot-vacuum-control"
      headerIcon={<VacuumIcon />}
      headerButton={
        <Button
          startIcon={<ResetAllIcon className={sharedStyles.buttonIcon} />}
          label="Reset to current gripper state"
          size="extraSmall"
          variant="gray"
          onClick={resetCommand}
          disabled={!command}
          data-testid="gripper-widget-reset-current-gripper-state-button"
        />
      }
    >
      <div className={sharedStyles.controls}>
        <SectionContainer
          title="Enabled Channels"
          extraTitle={
            <HelpPopover message="Select which channels are enabled" />
          }
        >
          <div className={styles.channelsGrid}>
            {ORVGP20_CHANNELS.map(({ channel, name }) => (
              <SuctionChannelCheckbox
                key={channel}
                name={name}
                isChecked={getIsEnabled(channel)}
                onChange={handleIsEnabledChange(channel)}
                suctionPercentage={getSuctionPercentage(channel)}
              />
            ))}
          </div>

          <Checkbox
            checked={isAllEnabled}
            onChange={(e) => handleIsEnabledChange('*')(e.target.checked)}
          >
            Toggle All
          </Checkbox>
        </SectionContainer>

        <SectionContainer
          title="Pressure for Enabled Channels"
          extraTitle={
            <HelpPopover message="Target pressure to apply on the enabled channels" />
          }
        >
          <RangeSpinner
            {...sprops}
            valueToString={suctionAmountStringConvert}
          />
        </SectionContainer>

        <ApplyChangesButton
          routineRunnerArgs={routineRunnerArgs}
          command={command}
          resetCommand={resetCommand}
        />
      </div>
    </WidgetView>
  );
}

import type { Integrations } from '@sb/types';
import { GripperIcon } from '@sb/ui/icons';

import { getGripperImplementationDefaults } from '../frontend/getGripperImplementationDefaults';

import type { NoGripperConfiguration } from './types/Configuration';

export const noGripperImplementation: Integrations.IntegrationFrontend<NoGripperConfiguration> =
  {
    ...getGripperImplementationDefaults(),
    InlineIcon: GripperIcon,
    getSupportedSteps: () => [],
    getManufacturerName: () => 'None',
    getShortName: () => 'No gripper',
    getDisplayName: () => {
      return 'No gripper';
    },
    getDefaultItem: (): NoGripperConfiguration => {
      return {
        kind: 'NoGripper',
      };
    },
    getIntegrationVariables: () => [],
  };

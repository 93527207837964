import type { OnRobot3FG15Command } from '../..';
import {
  OR_3FG15_TARGET_FORCE_DEFAULT,
  OR_3FG15_FORCE_NEWTONS_MIN,
  OR_3FG15_FORCE_NEWTONS_MAX,
} from '../..';

const OR_3FG15_COMMAND_KIND_DEFAULT = 'OnRobot3FG15Command';
const OR_3FG15_GRIP_KIND_DEFAULT: OnRobot3FG15Command['gripKind'] = 'inward';

export const OR_3FG15_DIAMETER_DEFAULT = 0.1535;

export const OR_3FG15_COMMAND_DEFAULT: OnRobot3FG15Command = {
  kind: OR_3FG15_COMMAND_KIND_DEFAULT,
  gripKind: OR_3FG15_GRIP_KIND_DEFAULT,
  targetForce: OR_3FG15_TARGET_FORCE_DEFAULT,
  targetDiameter: OR_3FG15_DIAMETER_DEFAULT,
};

export const OR_3FG15_FORCE_RANGE = {
  min: OR_3FG15_FORCE_NEWTONS_MIN,
  max: OR_3FG15_FORCE_NEWTONS_MAX,
};

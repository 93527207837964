import { Button, Checkbox } from '@sb/ui/components';
import { margin } from '@sb/ui/styles';
import SectionContainer from '@sbrc/components/visualizer-view-shared/widget-panel/SectionContainer';
import { useGuidedMode, useRoutineRunnerHandle, useToast } from '@sbrc/hooks';

import { GripperStatus } from '../shared/GripperStatus';

import type { DualValueModeProps } from './types';

export default function ApplyChangesButton({
  gripperControlState,
  routineRunnerArgs,
  dualChangerCommand,
}: DualValueModeProps) {
  const { setToast } = useToast();

  const routineRunnerHandle = useRoutineRunnerHandle(routineRunnerArgs);

  const { runAdHocCommand, stopGuidedMode } = useGuidedMode({
    ...routineRunnerArgs,
    shouldStopBeforeRun: false,
  });

  const {
    canApplyGripperChanges,
    isDiameterEqual,
    setConformedState,
    targetPayload,
    changeCommandKind,
    isGripChecked,
    status,
  } = gripperControlState;

  const onHoldApplyChangesButton = () => {
    runAdHocCommand({
      onRunCommand: async () => {
        const actuateGripper = await routineRunnerHandle.actuateDevice({
          command: dualChangerCommand ?? gripperControlState.command,
          payload: { mass: targetPayload },
          retryTimeoutMS: 10,
        });

        setConformedState('outdated');

        return actuateGripper;
      },
      onError: (error) => {
        setToast({
          kind: 'error',
          message: `Error actuating gripper: ${error}`,
        });
      },
    });
  };

  const action = isDiameterEqual ? 'Tap' : 'Hold';

  return (
    <SectionContainer>
      <Checkbox
        checked={isGripChecked}
        onChange={(e) => {
          changeCommandKind(e.target.checked);
        }}
        className={margin.extraSmall}
      >
        Flex grip with force
      </Checkbox>

      <GripperStatus status={status} />

      <Button
        variant="blackPrimary"
        isFullWidth
        onHold={onHoldApplyChangesButton}
        onRelease={stopGuidedMode}
        disabled={!canApplyGripperChanges}
        data-testid="gripper-widget-apply-changes-button"
      >
        {action} to Apply Changes
      </Button>
    </SectionContainer>
  );
}

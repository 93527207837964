/* eslint-disable react/destructuring-assignment */

import GripperErrorWidget from '@sb/integrations/gripper-general/shared-components/GripperError';
import type { Integrations } from '@sb/types';
import { useRobotGripperState, useRobotPayloadState } from '@sbrc/hooks';

import { OnRobot2FG7WidgetInner } from './OnRobot2FG7WidgetInner';

export function OnRobot2FG7Widget(args: Integrations.WidgetProps) {
  const routineRunnerArgs = {
    robotID: args.robot.id,
    isVizbot: args.isVizbot,
  };

  const otherRobotRoutineRunnerArgs = {
    robotID: args.robot.id,
    isVizbot: !args.isVizbot,
  };

  const routineRunnerPayload = useRobotPayloadState(routineRunnerArgs);

  const routineRunnerGripperState = useRobotGripperState(
    routineRunnerArgs,
    'OnRobot2FG7',
  );

  const otherRobotGripperState = useRobotGripperState(
    otherRobotRoutineRunnerArgs,
    'OnRobot2FG7',
  );

  if (
    !routineRunnerGripperState ||
    !routineRunnerGripperState.isConnected ||
    routineRunnerPayload === null
  ) {
    return (
      <GripperErrorWidget
        implementation={args.implementation}
        onClose={args.onClose}
        message="The gripper does not appear to be connected. Please ensure a 2FG7 gripper is properly mounted and connected."
      />
    );
  }

  return (
    <OnRobot2FG7WidgetInner
      routineRunnerPayload={routineRunnerPayload}
      routineRunnerGripperState={routineRunnerGripperState}
      otherRobotGripperState={otherRobotGripperState}
      args={args}
    />
  );
}

import { Button } from '@sb/ui/components';
import { useGuidedMode, useRoutineRunnerHandle, useToast } from '@sbrc/hooks';

import type { OnRobotScrewdriverCommand } from '../..';

import type { DualValueModeProps } from './types';

export default function RepositionShank({
  gripperControlState,
  routineRunnerArgs,
}: DualValueModeProps) {
  const { setToast } = useToast();

  const routineRunnerHandle = useRoutineRunnerHandle(routineRunnerArgs);

  const { runAdHocCommand, stopGuidedMode } = useGuidedMode({
    ...routineRunnerArgs,
    shouldStopBeforeRun: false,
  });

  const { setConformedState, isShankPositionEqual } = gripperControlState;

  const command: OnRobotScrewdriverCommand = {
    ...gripperControlState.command,
    commandKind: 'SET_SHANK_POSITION',
  };

  const onHoldRepositionShank = () => {
    runAdHocCommand({
      onRunCommand: async () => {
        const actuateGripper = await routineRunnerHandle.actuateDevice({
          command,
        });

        setConformedState('outdated');

        return actuateGripper;
      },

      onError: (error) => {
        setToast({
          kind: 'error',
          message: `Error actuating gripper: ${error}`,
        });
      },
    });
  };

  return (
    <Button
      variant="blackPrimary"
      isFullWidth
      onHold={onHoldRepositionShank}
      onRelease={stopGuidedMode}
      disabled={isShankPositionEqual}
      data-testid="gripper-widget-apply-changes-button"
    >
      Hold to Adjust Z-Axis
    </Button>
  );
}

import { Typography } from '@sb/ui/components';

import { MenuCardItem } from './MenuCardItem';

export function DisconnectedCard() {
  return (
    <MenuCardItem>
      <Typography isBold variant="medium">
        Disconnected
      </Typography>
      <Typography color="gray" variant="medium">
        Check your network and whether the robot is plugged in. If the issue
        persists then contact support.
      </Typography>
    </MenuCardItem>
  );
}

import { NumberInput } from '@sb/ui/components';
import { useFeatureFlag } from '@sbrc/hooks';

import { useSettingValue } from '../../store';

import styles from './CollisionThresholdField.module.css';

interface SettingsFieldProps {
  torqueFieldName: string;
  torqueSuffix: string;
  torqueStep: number;
  torquePlaceholderValue: number;
  torqueRange: [number, number];
  accelerationFieldName: string;
  accelerationSuffix: string;
  accelerationStep: number;
  accelerationPlaceholderValue: number;
  accelerationRange: [number, number];
}

export function CollisionThresholdField({
  torqueFieldName,
  torqueSuffix,
  torqueStep,
  torquePlaceholderValue,
  torqueRange,
  accelerationFieldName,
  accelerationSuffix,
  accelerationStep,
  accelerationPlaceholderValue,
  accelerationRange,
}: SettingsFieldProps) {
  const dualModeCollision = useFeatureFlag('dualModeCollision');

  const [value, setValue, onValidationChange, isDisabled] = useSettingValue(
    torqueFieldName,
    (v) => (typeof v === 'number' ? v : null),
  );

  const [
    accelerationValue,
    setAccelerationValue,
    onAccelerationValidationChange,
    isAccelerationDisabled,
  ] = useSettingValue(accelerationFieldName, (v) =>
    typeof v === 'number' ? v : null,
  );

  return (
    <>
      <NumberInput
        className={styles.field}
        disabled={isDisabled}
        suffix={torqueSuffix}
        value={value ?? NaN}
        onChange={setValue}
        onClear={() => setValue(null)}
        step={torqueStep}
        max={torqueRange[1]}
        min={torqueRange[0]}
        onValidationChange={onValidationChange}
        placeholderValue={torquePlaceholderValue}
      />

      {dualModeCollision ? (
        <NumberInput
          className={styles.field}
          disabled={isAccelerationDisabled}
          suffix={accelerationSuffix}
          value={accelerationValue ?? NaN}
          onChange={setAccelerationValue}
          onClear={() => setAccelerationValue(null)}
          step={accelerationStep}
          max={accelerationRange[1]}
          min={accelerationRange[0]}
          onValidationChange={onAccelerationValidationChange}
          placeholderValue={accelerationPlaceholderValue}
        />
      ) : (
        <div className={styles.field} />
      )}
    </>
  );
}

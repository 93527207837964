import cx from 'classnames';

import { Typography } from '@sb/ui/components';
import { CompletedIcon, WarningIcon } from '@sb/ui/icons';
import {
  alignItems,
  display,
  justifyContent,
  margin,
  padding,
} from '@sb/ui/styles';

import styles from './SoftwareUpToDate.module.css';

function convertDate(date: Date) {
  return date.toLocaleString('en-us', {
    month: 'short',
    year: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });
}

interface SoftwareUpToDateProps {
  lastCheckedTime: Date | null;
  lastErrorMessage?: string;
}

export function SoftwareUpToDate({
  lastCheckedTime,
  lastErrorMessage,
}: SoftwareUpToDateProps) {
  return (
    <div
      className={cx(
        display.flex.column,
        justifyContent.center,
        alignItems.center,
        padding.top.small,
        padding.bottom.small,
        styles.modalContent,
      )}
    >
      {lastErrorMessage && (
        <>
          <WarningIcon
            color="var(--standard-bots--warning-main)"
            size="medium"
            className={margin.bottom.small}
          />
          <Typography
            isBold
            variant="medium"
            className={margin.bottom.extraSmall}
          >
            A problem occurred in the software update process.
          </Typography>
          <Typography
            variant="small"
            color="gray"
            className={margin.bottom.extraSmall}
            truncateMultiline={3}
          >
            {lastErrorMessage}
          </Typography>
        </>
      )}

      {!lastErrorMessage && (
        <>
          <CompletedIcon
            color="green"
            size="medium"
            className={margin.bottom.small}
          />
          <Typography
            isBold
            variant="medium"
            className={margin.bottom.extraSmall}
          >
            This robot’s software is up-to-date.
          </Typography>
        </>
      )}

      {lastCheckedTime && (
        <Typography color="gray">
          <strong>Last checked: </strong>
          {convertDate(lastCheckedTime)}
        </Typography>
      )}
    </div>
  );
}

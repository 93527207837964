import cx from 'classnames';

import { Badge } from '@sb/ui/components';

import styles from './IOBadge.module.css';

interface IOBadgeProps {
  children: React.ReactNode;
  isCurrentIOLevel?: boolean;
  className?: string;
}

const IOBadge = ({ children, isCurrentIOLevel, className }: IOBadgeProps) => {
  return (
    <Badge
      className={cx(
        styles.ioBadge,
        {
          [styles.currentLevel]: isCurrentIOLevel,
        },
        className,
      )}
    >
      {children}
    </Badge>
  );
};

export default IOBadge;
